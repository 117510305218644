import React from "react";

export const AddToApplicationCard = (props) => {
  return (
    <div className="grid">
      <div
        className="w-full flex-row flex-col-3 border border-gray-300 rounded-2xl p-4"
        to="/applicationdetails"
      >
        <div className="border-b-2 relative pb-6 w-full flex ">
          <img
            className="rounded-xl"
            src="/images/applcation_card_logo.svg"
            alt="applcation_card_logo"
          />
          <h1 className="pl-3 font-apimp-regular text-base text-[#181F48] ">
            {props.name}
          </h1>

          <label className="inline-block absolute right-1 top-1  items-center cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              value={props.id}
              checked={props.activeApi.includes(props.id)}
              onChange={(e) => props.handleActive(e.target.checked, props.id)}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-gray-700 rounded-full peer dark:bg-[#181F48] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#181F48] peer-checked:bg-[#181F48]"></div>
          </label>
        </div>
        <div className="pt-3 flex justify-between items-center">
          <div className="flex">
            <h1 className="text-[#316AFF] font-apimp-bold">{props.apiUsed}</h1>
            <h1 className="pl-1 text-base text-black font-apimp-light opacity-40">
              APIs used
            </h1>
          </div>
          <div className="flex text-[10px] text-black font-apimp-light opacity-40">
            <h1>Created on</h1>
            <h1 className="pl-1">{props.createdDate}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
