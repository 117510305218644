import React, { Fragment, useEffect, useState } from 'react';
import APIService from "../../Services/API";
import ForumCardDetail from '../Common/ForumCardDetail';
import { Link, useParams } from "react-router-dom";
import Loader from '../Common/Loader';

const ForumDetails = () => {
    const params = useParams();
    const [postDetails, setPostDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isComment, setIsComment] = useState(true);
    const [replyCommentId, setReplyCommentId] = useState("");
    const [commentPlaceholder, setCommentPlaceholder] = useState("Enter Comment");
    const [txtComment, setTxtComment] = useState("");
    const userObj = JSON.parse(sessionStorage.getItem("userDetails"));

    const updateView = () => {
        APIService.updateView(params.fid);        
    };

    const getAllDiscussion = () => {
        setIsLoading(true);
        APIService.getAllDiscussion(userObj.userId).then((response) => {
          if (response) {
            setIsLoading(false);
            let filterById = response.filter((items, index) => {
                return items.discussionId == params.fid
              });
            setPostDetails(filterById[0]); 
          }
        })
          .catch((e) => {
            setIsLoading(false);
            console.log("Error - ", e);
          });
    };
    

    const addCommentReply = (event) => {        
        if (event.code === 'Enter') {
            if(event.target.value === ""){
              return false;
            } else {
                setTxtComment(event.target.value);   
                 let payload = {
                    "discussionId":parseInt(params.fid),
                    "parentCommentId":isComment ? -1 : replyCommentId,
                    "commentPhrase": event.target.value,
                    "userName": userObj.userName,
                    "commentedBy" : userObj.userId
                }                   
              setIsLoading(true);
              APIService.addCommentReply(payload).then((response) => {
                if (response) {
                  setTxtComment("");  
                  setIsLoading(false);
                  setCommentPlaceholder("Enter Comment");
                  getAllDiscussion();
                }
              })
              .catch((e) => {
                setTxtComment("");
                setIsLoading(false);
                console.log("Error - ", e);
              });
            }      
          }
    }    

    const handleReply = (id) => {
        setReplyCommentId(id);
        setIsComment(false);
        setCommentPlaceholder("Enter Reply message");
    }


    useEffect(() => {
        updateView();
        getAllDiscussion();
      }, []);

    return (
        <div className="grid px-5 pt-[26px] pb-[24px]">
            <div className='grid grid-col-3 pb-7'>
                <div className="col-start-1 col-end-3">
                    <nav className="flex" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3 py-[2.72px]">
                            <li className="inline-flex items-center">
                                <img src='/images/home.svg' alt='home'></img>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <Link
                                        to='/forum'
                                        className="ml-1 text-base font-apimp-regular text-black underline md:ml-2"
                                    >
                                        Forums
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <img src='/images/crossline.svg' alt='crossline'></img>
                                    <span className="ml-1 text-lg font-apimp-medium text-black md:ml-2">
                                        {postDetails?.discussionName}
                                    </span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='border border-[#181F48] border-opacity-20 rounded-[10px]'>
                <ForumCardDetail 
                    postDetails={postDetails}
                />
                <p className='p-6 leading-6 text-base font-apimp-regular text-[#121212]'>
                    {postDetails?.discussionContent}
                </p>
                <div className='rounded-[10px] border border-[#181F48] border-opacity-20 py-4 px-6 bg-[#181F48] bg-opacity-20'>
                    <h6 className='text-base font-apimp-medium text-[#121212]'>Comments</h6>

                    {postDetails?.discussionComments && postDetails?.discussionComments.length > 0 && 
                        postDetails?.discussionComments.map((data, index) => (
                            <Fragment key={data.discussionId}>
                                <div                                     
                                    className='flex flex-row pt-4 gap-2 items-center'
                                >
                                    <img className='w-5 h-5' src='/images/avatar.svg'></img>
                                    <h4 className='text-xs font-apimp-semibold'>
                                        {data.userName}
                                    </h4>
                                    <img src='/images/blackdot.svg'></img>
                                    <h6 className='text-[10px] text-[#181F48]'>
                                        {data.lastUpdatedDate}
                                    </h6>
                                </div>
                                <p className='text-sm pl-7 pt-3'>
                                    {data.commentPhrase}
                                </p>
                                <div className='flex flex-row pl-7 pt-1 gap-3'>
                                    <span 
                                        className='text-txtPrimary text-sm underline cursor-pointer'
                                        onClick={() => handleReply(data.commentId)}
                                    >Reply
                                    </span>
                                    <span 
                                        className='text-black text-opacity-40 text-sm underline cursor-pointer'
                                        // onClick={() => handleViewReply(data.commentId)}
                                    >
                                        View Replies
                                    </span>
                                </div>
                            </Fragment>
                        ))    
                    }                     

                    <div className='pt-10 flex items-center'>
                        <img className='w-5 h-5' src='/images/avatar.svg'></img>
                        <div className='relative block w-full pl-2'>
                            <button className="absolute z-10 font-apimp-regular inset-y-0 right-1 flex items-center">
                                <img className='pr-3' src='/images/arrow_circle_right.svg' alt='arrow_circle_right.svg'></img>
                            </button>
                            <input
                                type="text"
                                className='relative w-full py-2.5 text-[#121212] text-sm font-apimp-medium rounded-[10px] pb-[16px] border border-[#316AFF] placeholder:text-black placeholder:text-opacity-50  placeholder:font-apimp-regular'
                                placeholder={commentPlaceholder}
                                defaultValue={txtComment}
                                onKeyUp={(event) => addCommentReply(event)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}

export default ForumDetails