import { ToastContainer } from "react-toastify";
import RoutesPage from "./Routes";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./store/appContext";
import reducer, { initialState } from "./store/reducers";

const App = () => {  

  return (
    <>
      <ContextProvider initialState={initialState} reducer={reducer}>
        <BrowserRouter>
          <RoutesPage />
          <ToastContainer
            position="top-right"
            autoClose={1000}
          />        
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
