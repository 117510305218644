import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import APIService from "../../Services/API";
import CONFIG from "../../Utils";

const AddUsers = ({ handleCloseConfirmation, selectedDetails, roles, partners, masterData, getUsersList, currentUser }) => {

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [hidePartner, setHidePartner] = useState(false)


    let filterRoles = currentUser?.RoleName?.toUpperCase() === "ASURION ADMIN" || currentUser?.RoleName?.toUpperCase() === "ASURION USER"
        ? roles :
        currentUser?.RoleName?.toUpperCase() === "PARTNER ADMIN" ?
            roles?.filter(v => v?.RoleName?.toUpperCase() === "PARTNER USER" || v?.RoleName?.toUpperCase() === "PARTNER ADMIN")
            : ''
    let filterPartners = currentUser?.RoleName?.toUpperCase() === "ASURION ADMIN" || currentUser?.RoleName?.toUpperCase() === "ASURION USER"
        ? partners : partners?.filter(v => v?.PartnerName?.toUpperCase() === currentUser?.Partner?.toUpperCase())

    const addUsers = (data) => {
        const payload = {
            "CreateUserRequest": {
                "emailId": data?.emailId,
                "role": roles?.filter(v=> v?.RoleName === data?.role)?.[0]?.RoleId,
                "roleId": roles?.filter(v=> v?.RoleName === data?.role)?.[0]?.RoleId,
                "partners": data?.partners,
                "username": data?.emailId?.split('@')?.[0]
            }
        }
        APIService.createUser(payload).then((response) => {
            if (response) {
                CONFIG.TOASTMESSAGES("success", response?.CreateUserResponse?.message);
                handleCloseConfirmation();
                getUsersList()
            }
        }).catch((e) => {
            CONFIG.TOASTMESSAGES("error", e);
            console.log("Error - ", e);
        });
        // APIService.updateUserAccessDetails(payload).then((response) => {
        //     if (response) {
        //         CONFIG.TOASTMESSAGES("success", "User Approved successfully");
        //         handleCloseConfirmation();
        //     }
        // }).catch((e) => {
        //         CONFIG.TOASTMESSAGES("error", e);
        //         console.log("Error - ", e);
        // });
    }

    const handleRole = (e) => {
        setHidePartner((e.target.value?.toUpperCase() === "ASURION ADMIN" || e.target.value?.toUpperCase() === "ASURION USER") ? false : true)
    }

    return (
        <>
            <div id="confirmationModal" tabIndex="-1" className="fixed top-0 left-0 right-0 z-5000 p-4 md:inset-0 h-[calc(100%-1rem)] md:h-full bg-opacity-50 backdrop-blur-sm m-auto">
                <div className="relative w-[500px] h-full max-w-2xl md:h-auto m-auto top-[120px]">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* <!-- Modal header --> */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Add Users
                            </p>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal"
                                onClick={() => handleCloseConfirmation()}
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-5">
                            <form onSubmit={handleSubmit(addUsers)}>
                                {(errors.role?.type === "required" || errors.partners?.type === "required" || errors.emailId?.type === "required") && (
                                    <div className="text-[red] text-[14px] my-[10px]">* Fields Required</div>
                                )}
                                <div className="mt-[15px]">
                                    <label
                                        className="w-[100px] inline-block text-base text-[#181F48] font-medium mb-[10px]"
                                        htmlFor="role"
                                    >
                                        Role<span className="text-[red]">*</span>
                                    </label>
                                    <select
                                        className="w-[50%] ml-[30px] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                                        name="role"
                                        {...register("role", { required: true })}
                                        onChange={(e) => handleRole(e)}
                                    >
                                        <option className="" value="">
                                            Select Role
                                        </option>
                                        {filterRoles && filterRoles.length > 0 && filterRoles.map((item, index) => (
                                            <option
                                                name={item.RoleName}
                                                key={item.RoleId}
                                                className="py-[8px]" value={item.RoleName}>
                                                {item.RoleName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {hidePartner &&
                                    <div className="mt-[15px]">
                                        <label
                                            className="w-[100px] inline-block text-base text-[#181F48] font-medium mb-[10px]"
                                            htmlFor="partners"
                                        >
                                            Partners<span className="text-[red]">*</span>
                                        </label>
                                        <select
                                            className="w-[50%] ml-[30px] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                                            name="partners"
                                            {...register("partners", { required: true })}
                                        >
                                            <option className="" value="">
                                                Select Partner
                                            </option>
                                            {filterPartners && filterPartners.length > 0 && filterPartners.map((item, index) => (
                                                <option
                                                    key={item.ApiPartnersId}
                                                    className="py-[8px]" value={item.PartnerName}>
                                                    {item.PartnerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                <div className="mt-[15px] flex flex-row">
                                    <label
                                        className="w-[100px] inline-block text-base text-[#181F48] font-medium mb-[10px]"
                                        htmlFor="role"
                                    >
                                        Email ID<span className="text-[red]">*</span>
                                    </label>
                                    {/* <textarea id="emailId" rows="4" class="w-[50%] ml-[30px] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]" 
                                placeholder="Enter email id"
                                name="emailId"
                                {...register("emailId", { required: true })}></textarea> */}
                                    <div className="w-[50%] ml-[30px] ">
                                        <input type="email" className="w-full rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                                            name="emailId"
                                            {...register("emailId", {
                                                required: true, pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Please enter a valid email',
                                                }
                                            })} />
                                        {errors.emailId?.message && (
                                            <p className="text-red-500 text-xs">{errors.emailId?.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='mt-[1rem] flex justify-end'>
                                    <button disabled={Object.keys(errors).length === 0 ? false : true} onClick={handleSubmit(addUsers)} className={`${Object.keys(errors).length === 0 ? 'bg-txtPrimary text-white cursor-pointer' : 'bg-gray-300 text-black cursor-not-allowed'} px-4 py-[9px] rounded-[10px] font-light text-sm`}>
                                        Create User</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUsers;
