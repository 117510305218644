import React, { useEffect, useState, Fragment } from "react";
import Search from "../Search/Search";
import AddApi from "./AddApi";
import APIService from "../../Services/API";
import APICard from "../Common/APICard";
import Loader from "../Common/Loader";
// import ApiFilterSideMenu from "../Common/ApiFilterSideMenu";
import CONFIG from "../../Utils";
import ConfirmBox from "../Common/ConfirmBox";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";

const ApiDirectory = () => {
  const [state, dispatch] = useCentralStore();
  const [showModal, setShowModal] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [dropDownValue, setdropDownValue] = useState("Most Rated");
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [selectedTag, setSelectedTag] = useState("All Tags");
  const [categoryList, setCategoryList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("Category");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [topCategories, setTopCategories] = useState([]);
  const [selectedTopCatogory, setSelectedTopCatogory] = useState("");
  const [isApiRequestData, setIsApiRequestData] = useState(false);
  const userData = state?.currentUser  //JSON.parse(sessionStorage.getItem('userDetails'));
  
  const handleAPIRequest = (txtChecked) => {
    setIsApiRequestData(txtChecked);
    if(txtChecked){
        getApprovalPendingEntries();
    } else {
      getAPIData(userData?.UserId);
    }
  }

  const getApprovalPendingEntries = () => {
    setIsLoading(true);
    APIService.getApprovalPendingEntries()
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setAPIData(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const filterDataByEmailId = () => {
     let dataAry = APIData;   
     let obj = JSON.parse(sessionStorage.getItem("userDetails"));
     let filteredData = dataAry.filter((elm, index) => elm.createdBy === obj.userId);
     setAPIData(filteredData);
  }

  const getUserData = () => {
    getAPIData(userData?.UserId);    
  };

  useEffect(() => {
    setAPIData(state?.masterDataDetails?.Apis)
  }, [state?.masterDataDetails?.Apis])

  const getMasterData = () => {
    setIsLoading(true)
    APIService.getMasterData().then((response) => {
        if (response) {
            setIsLoading(false)
            dispatch({
                type: ActionTypes.SET_MASTERDATA,
                payload: response?.GetMasterData,
            });
            setAPIData(response?.GetMasterData?.Apis)
            sessionStorage.setItem("masterData", JSON.stringify(response?.GetMasterData))
        }
    }).catch((e) => {
            CONFIG.TOASTMESSAGES("error", e);
    });
}

  const getAPIData = (txtUserId) => {
    // setAPIData(state?.masterDataDetails?.Apis)
    // setIsLoading(true);
    //   APIService.getApiCardData(txtUserId)
    //   .then((response) => {
    //     if (response) {
    //       setIsLoading(false);
    //       setAPIData(response);
    //     }
    //   })
    //   .catch((e) => {
    //     setIsLoading(false);
    //     console.log("Error - ", e);
    //   });
  };

  const getSavedApiData = () => {
    setIsLoading(true);
    APIService.getSavedApiData(userData?.UserId)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setAPIData(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const getAllCategories = () => {
    // APIService.getAllCategories().then((response) => {
    //     if (response) {
    //       setIsLoading(false);
    //       let categoryAry = ["All Categories", ...response];
    //       setCategoryList(categoryAry);
    //     }
    //   })
    //   .catch((e) => {
    //     setIsLoading(false);
    //     console.log("Error - ", e);
    //   });
  };

  const getAllTags = () => {
    // APIService.getAllTags()
    //   .then((response) => {
    //     if (response) {
    //       setIsLoading(false);
    //       let tagsAry = ["All Tags", ...response];
    //       setTagsList(tagsAry);
    //     }
    //   })
    //   .catch((e) => {
    //     setIsLoading(false);
    //     console.log("Error - ", e);
    //   });
  };

  const [_tagsListObj,setTagsListObj] = useState([])
  useEffect(() => {
    if(tagsList?.length > 0){
      const tagsListObj = []
      tagsList?.map(v => {
        return tagsListObj.push({label: v, value: v})
      })
      setTagsListObj(tagsListObj)
    }
  },[tagsList])

  const getTopCategories = () => {
    // APIService.getTopCategories().then((response) => {
    //     if (response) {
    //       setIsLoading(false);
    //       setTopCategories(response);
    //     }
    //   })
    //   .catch((e) => {
    //     setIsLoading(false);
    //     console.log("Error - ", e);
    //   });
  };

  // const handleDrawerClick = () => {
  //   setShowDrawer(!showDrawer);
  // };

  // const filterByCategory = (txtCategory) => {
  //   setSelectedCategory(txtCategory);
  //   if (txtCategory !== "All Categories") {
  //     setIsLoading(true);
  //     APIService.searchByCategory(txtCategory).then((response) => {
  //       if (response) {
  //         setIsLoading(false);
  //         setAPIData(response);
  //       }
  //     })
  //     .catch((e) => {
  //       setIsLoading(false);
  //       console.log("Error - ", e);
  //     });
  //   }
  //   else {
  //     getAPIData(userData?.UserId);
  //     getAllCategories();
  //     getAllTags();
  //   }

  // }

  const filterByTags = (txtTag) => {
    setSelectedTag(txtTag);
    if (txtTag !== "All Tags") {
      setIsLoading(true);
      APIService.searchByTag(txtTag).then((response) => {
        if (response) {
          setIsLoading(false);
          setAPIData(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
    }
    else {
      getAPIData(userData?.UserId);
      getAllCategories();
      getAllTags();      
    }

  }

  const handleSearchTerm = (event) => {
    // if (event.code === 'Enter') {
      if(event === ""){
        // getAPIData(userData?.UserId);
        setAPIData(state?.masterDataDetails?.Apis)
      } else {
        let filterData = state?.masterDataDetails?.Apis?.filter(v => v?.Name?.toLowerCase()?.includes(event?.toLowerCase()) || v?.Category?.toLowerCase()?.includes(event?.toLowerCase()))
        setAPIData(filterData)
        // setIsLoading(true);
        // APIService.searchByNameCategoryTags(event).then((response) => {
        //   if (response) {
        //     setIsLoading(false);
        //     setAPIData(response);
        //   }
        // })
        // .catch((e) => {
        //   setIsLoading(false);
        //   console.log("Error - ", e);
        // });
      }      
    // }
  }  

  // const handleActiveFilter = (txtName) => {
  //     setActiveFilter(txtName);
  //     getAllCategories();
  //     getAllTags();
  //     txtName === "Category" ? filterByCategory(selectedCategory) : filterByTags(selectedTag)
  // }

  const handleTopCategoryFilter = (txtName) => {
    txtName === selectedTopCatogory ? setSelectedTopCatogory("") : setSelectedTopCatogory(txtName);    
    txtName === selectedTopCatogory ? filterByTopCategory("All Categories") : filterByTopCategory(txtName);
  }

  const filterByTopCategory = (txtCategory) => {
    if (txtCategory !== "All Categories") {
      setIsLoading(true);
      APIService.searchByCategory(txtCategory).then((response) => {
        if (response) {
          setIsLoading(false);
          setAPIData(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
    }
    else {
      getAPIData(userData?.UserId);
      getAllCategories();
      getAllTags();
    }

  }

  // const searchCategoryTags = (event) => {
  //   if (event.code === 'Enter') {
  //       if(activeFilter === "Category") {      
  //           if(event.target.value == ""){
  //             getAllCategories();
  //           } else {
  //             let data = categoryList;
  //             const result = data.filter((items, index) => {
  //               return items.toLowerCase() === event.target.value.toLowerCase()
  //             });
  //             setCategoryList(result);
  //           }            
  //       } else if(activeFilter === "Tags") {
  //           if(event.target.value == ""){
  //             getAllTags(); 
  //           } else {
  //             let data = tagsList;
  //             const result = data.filter((items, index) => {
  //               return items === event.target.value
  //             });
  //             setCategoryList(result);
  //           }                        
  //       }
  //   }
  // }  

  const handleSave = (event, apiId, savedFlag) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    APIService.savedApi(userData?.UserId, apiId, savedFlag).then((response) => {
      if (response.error) {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", response.message);
      } else {
        setIsLoading(false);
        getAllCategories();
        getAPIData(userData?.UserId);
        getAllTags();
        CONFIG.TOASTMESSAGES("success", response.message);
      }
    })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  const handleClose = () => {
    setShowModal(false);
    getAPIData(userData?.UserId);
    getAllCategories();
    getAllTags();
  }

  const handleConfirmation = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowConfirmation(true);
  }

  const handleCloseConfirmation = () => {
    setDeleteId("");
    setShowConfirmation(false);
  }

  const handleDeleteAPI = () => {
    setShowConfirmation(false);
    setIsLoading(true);
    APIService.deleteApiById(deleteId, userData?.UserId).then((response) => {
      if (response.error) {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", response.message);
      } else {
        CONFIG.TOASTMESSAGES("success", response.message);
        getAllCategories();
        getAPIData(userData?.UserId);
        getAllTags();
      }
    })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  useEffect(() => {
    getAllCategories();        
    getAllTags();
    getTopCategories();
    getUserData();
    setSortngvalues({ label: 'Most Rated', value: 'Most Rated'})
    setdropDownValue('Most Rated')
    getAPIData(userData?.UserId)
    getMasterData()
  }, []);


  const [selectedTags, setTagOptions] = useState(null)
  const [selectedEnrolmentsValues, setEnrolmentsValues] = useState(null)
  const [selectedSortingvalues, setSortngvalues] = useState([{ label: 'Most Rated', value: 'Most Rated'}])
  const [appliFilter, setApplyFilter] = useState(false)
  // console.log('appliFilter', appliFilter);

  // const handleChange = (txtValue) => {
  //   setdropDownValue(txtValue);
  //   if(txtValue === "Saved APIs"){
  //       getSavedApiData();
  //   } else if(txtValue === "Most Rated" || txtValue === "All APIs"){
  //       getAPIData(userData?.UserId);
  //   } else if(txtValue === "APIs by Me"){
  //       filterDataByEmailId();
  //   }
  // };

  useEffect(() => {
    if(appliFilter){
      setdropDownValue(selectedSortingvalues?.value)
      if(selectedSortingvalues?.value === "Saved APIs"){
          getSavedApiData();
      } else if(selectedSortingvalues?.value === "Most Rated" || selectedSortingvalues?.value === "All APIs"){
          getAPIData(userData?.UserId);
      } else if(selectedSortingvalues?.value === "APIs by Me"){
          filterDataByEmailId();
      }
      if(selectedTags !== null){
        filterByTags(selectedTags?.value)
      } 
    }
  }, [appliFilter])

  const CategoriesOptions = ['Enrolments', 'Service Requests', 'Payments', 'Integrations']

  return (
    <>
      <div className="bg-[#F0F0F5] flex flex-col justify-center py-[20px]">
        <Search
          handleSearchTerm={handleSearchTerm}
          setTagOptions={(e) => setTagOptions(e)}
          setEnrolmentsValues={(e) => setEnrolmentsValues(e)}
          setSortngvalues={(e) => setSortngvalues(e)}
          selectedTags={selectedTags}
          selectedEnrolmentsValues={selectedEnrolmentsValues}
          selectedSortingvalues={selectedSortingvalues}
          setApplyFilter={(val) => setApplyFilter(val)}
          _tagsListObj={_tagsListObj}
        />
        {/*
         <div className="mt-6 w-[52%] mx-auto">
          {CategoriesOptions &&
            CategoriesOptions.length > 0 &&
            CategoriesOptions.map((elem, index) => {
              return (
                <button
                  key={elem}
                  className={`mx-2 px-4 py-[6px] capitalize text-xs font-apimp-regular font-normal ${selectedTopCatogory === elem ? "bg-txtPrimary text-white border-txtPrimary border-[0.5px]" : "bg-white text-[#2743CC] "}`}
                  onClick={() => handleTopCategoryFilter(elem)}
                >
                  {elem}
                </button>
              )
            })} 
        </div>
         */}
        <div className="mt-6 w-[52%] mx-auto">
          {topCategories &&
            topCategories.length > 0 &&
            topCategories.map((elem, index) => {
              return (
                <button
                  key={elem}
                  className={`mx-2 px-4 py-[6px] rounded-[10px] capitalize text-xs font-apimp-regular font-normal ${selectedTopCatogory === elem ? "bg-txtPrimary text-white border-txtPrimary border-[0.5px]" : "bg-white text-[#1C1C1C] border-txtPrimary border-[0.5px]"}`}
                  onClick={() => handleTopCategoryFilter(elem)}
                >
                  {elem}
                </button>
              )
            })} 
        </div>
      </div>
      <div className="grid px-5 pb-7 mt-[12px]"> 
      {/* border-l-[10px] border-l-txtPrimary */}
        {/* <div
          className={
            showDrawer
              ? "absolute z-10 left-[310px] mt-[50px] ease-in-out duration-300 "
              : "absolute z-10 left-[7px] mt-[50px] ease-in-out duration-300"
          }
        >
          <button
            className={
              showDrawer
                ? "p-[9px] bg-txtPrimary  rotate-180  rounded-l-[10px]"
                : "  p-[9px] bg-txtPrimary rounded-r-[10px]"
            }
            onClick={() => handleDrawerClick()}
          >
            <img
              src="./images/arrow_circle_left.svg"
              alt="arrow_circle_left.svg"
              className=" text-white"
            />
          </button>
        </div> */}
        <div className={showDrawer ? "grid grid-cols-4" : "grid grid-cols-3"}>
          {/* {showDrawer && <ApiFilterSideMenu 
            selectedCategory={selectedCategory}
            categoryList={categoryList}
            tagsList={tagsList}
            activeFilter={activeFilter}
            selectedTag={selectedTag}
            searchCategoryTags={searchCategoryTags}
            filterByCategory={filterByCategory}
            filterByTags={filterByTags}
            handleActiveFilter={handleActiveFilter}
          />} */}
          <div
            className={
              showDrawer ? "col-start-2 col-end-5" : "col-start-1 col-end-4"
            }
          >
            <div className="grid grid-cols-3">
              <div className="relative col-start-1 col-end-3  pl-[45px]">
                <h1 className="font-apimp-medium font-medium text-xl">
                  API Directory
                </h1>
              </div>
              <div className="text-right col-start-3 col-end-4">
                <button
                  type="button"
                  className="inline-block px-3 py-2.5 bg-txtPrimary text-white font-inter-regular text-base rounded-xl"
                  onClick={() => setShowModal(true)}
                >
                  <img
                    className="inline-flex cursor-pointer relative mr-2"
                    src={"images/add_circle.svg"}
                    alt="add"
                  />
                  Add API
                </button>
              </div>
            </div>

            {/* <div
              className={
                showDrawer ? "grid grid-cols-3 " : "grid grid-cols-4"
              }
            >
              <div className="col-start-1 col-end-3 mt-4  pl-[45px]">
                <span className="text-base font-normal font-apimp-regular">
                  Displaying{" "}
                  <span className="text-base font-semibold text-txtPrimary font-apimp-regular cursor-pointer pr-[5px]">
                    {dropDownValue}
                  </span>
                  with
                  <span className="pl-[5px] text-base font-semibold text-txtPrimary font-apimp-regular cursor-pointer">
                    {selectedCategory}
                  </span>
                </span>
              </div>
              <div className="text-right col-start-3 col-end-5 mt-4">
                {userData && userData?.RoleName === 'ASURION ADMIN' && <Fragment>
                  <div
                    className="inline-block text-[14px] text-[#1C1C1C] font-normal mr-[10px]"
                  >
                    View API Requests
                  </div>
                  <label className="relative inline-block items-center cursor-pointer align-middle">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={isApiRequestData}
                      onChange={(e) => handleAPIRequest(e.target.checked)}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </Fragment>
                } */}
                {/* <select
                  className="rounded-[10px] pl-[7px] py-[7px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-xs text-[#181F48] font-apimp-regular ml-[24px]"
                  value={dropDownValue}
                  onChange={(event) => handleChange(event.target.value)}
                >
                  <option className="text-xs py-[5px]" value="Most Rated">
                    Most Rated
                  </option>
                  <option className="text-xs py-[5px]" value="All APIs">
                    All APIs
                  </option>
                  <option className="text-xs py-[5px]" value="APIs by Me">
                    APIs by Me
                  </option>
                  <option className="text-xs py-[5px]" value="Recently Added">
                    Recently Added
                  </option>
                  <option className="text-xs py-[5px]" value="Saved APIs">
                    Saved APIs
                  </option>
                </select> */}
              {/* </div> */}
            {/* </div> */}
            <div
              className={
                showDrawer
                  ? "grid grid-cols-3 gap-5 mt-[35px]"
                  : "grid grid-cols-5 gap-5 mt-[35px]"
              }
            >
              {APIData &&
                APIData.length > 0 &&
                APIData.map((data, index) => {
                  return <APICard 
                            key={data.id} 
                            data={data} 
                            isApiRequestData={isApiRequestData}
                            role={userData?.RoleName}
                            handleSave={handleSave}
                            handleConfirmation={handleConfirmation}
                      />;
                })}
              {APIData?.length == 0 && <div>No Data Available...</div>}
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}

      {showModal ? (
        <AddApi 
          handleClose={handleClose} 
          categoryList={categoryList}
        />
      ) : null}

      {showConfirmation ? (
        <ConfirmBox
          descText="Are you sure you want to delete?"
          handleCloseConfirmation={handleCloseConfirmation}
          handleAgree={handleDeleteAPI}
        />
      ) : null}
    </>
  );
};

export default ApiDirectory;