import React from "react";

const TagsCard = (props) => {
  return (
    <div className="">
    
        <div className="flex flex-row mt-4">
          <img className="" src="/images/Icon.svg" alt="" />
          <div className="flex-col ml-2">
            <span className="text-xs text-[#000000] font-apimp-regular">#{props.data.tag}</span>
            <h2 className="text-[10px] text-[#000000] ">{props.data.count} Posts use this tag</h2>
          </div>
        </div>
      </div>
    
  );
};

export default TagsCard;
