import ActionTypes from "./actionTypes";

export const initialState = {
  emailAddr: {},
  isLoggedIn: undefined,
  currentUser: undefined,
  masterDataDetails: undefined,
  currentApplicationData: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EMAIL_ADDRESS:
      return { ...state, emailAddr: action.payload };
    case ActionTypes.SET_USER_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    case ActionTypes.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case ActionTypes.SET_MASTERDATA:
      return { ...state, masterDataDetails: action.payload };
    case ActionTypes.SET_SELECTED_APPLICATION_DATA:
      return { ...state, currentApplicationData: action.payload };

    default:
      throw new Error();
  }
};

export default reducer;