import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import ModalTemplet from "../Common/ModalTemplet";
import { TagsInput } from "react-tag-input-component";
import CONFIG from "../../Utils";
import APIService from "../../Services/API";
import "../../index.scss";


const DiscussionModal = ({ handleClose }) => {
  const [selectedTagInput, setSelectedTagInput] = useState([]);
  const inputRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const userObj = JSON.parse(sessionStorage.getItem("userDetails"));

  const handleFileUpload = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    
  };
  const onSubmit = (data) => {
    let payload = {
      "discussionName": data.discussionName,
      "discussionContent": data.description,
      "discussionTags": selectedTagInput,
      "createdBy": userObj.userId,
      "userName": userObj.userName
    }
    APIService.addDiscussion(payload).then((response) => {
      if (response) {
        CONFIG.TOASTMESSAGES("success", "Discussion details added successfully.");
        handleClose();
      }
    })
      .catch((e) => {
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }; 

  
  return (
    <div className="">
      <ModalTemplet
        handleClose={handleClose}
        modalTitle={"Start a Discussion"}
        modalHeaderClass={
          "py-5 pl-10 pr-9 font-apimp-regular text-[20px] font-medium"
        }
        modalBodyClass={
          "pt-5 px-20 bg-txtSecondary rounded-t-[30px] rounded-b-[5px]"
        }
        modalBody={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  className="relative block font-apimp-regular text-[#181F48] text-base font-medium "
                  htmlFor="api_name"
                >
                  <span
                    className="absolute font-apimp-regular inset-y-0 left-0 flex items-center mt-8 cursor-pointer"
                    onClick={() => handleFileUpload()}
                  >
                    <img
                      className="w-[48px] h-[48px]"
                      src="/images/discussion_icon.svg"
                    ></img>
                  </span>
                  Discussion Icon & Name
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <input
                    className="w-full mt-2 rounded-[10px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-sm font-medium  pl-14 py-[14px] pr-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]  "
                    type="text"
                    placeholder="Enter discussion name and icon"
                    {...register("discussionName", { required: true })}
                  />
                </label>
                {errors.discussionName?.type === "required" && (
                  <p role="alert" className="text-red-700 text-sm ml-2 mt-1">
                    Discussion Icon & Name is required
                  </p>
                )}
              </div>

              <div className="grid mt-6">
                <label
                  className="block text-base mb-[10px] text-[#181F48] font-medium "
                  htmlFor="description "
                >
                  Discussion Detail
                </label>
                <textarea
                  className="pl-[15px] pb-[93px] text-[#121212] rounded-md border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-[12px] font-normal font-apimp-regular pt-[11px]  placeholder: placeholder:font-thin placeholder:text-xs placeholder:text-[#121212]"
                  type="text"
                  placeholder="Enter Description"
                  {...register("description", { required: true })}
                />
                {errors.description?.type === "required" && (
                  <p role="alert" className="text-red-700 ml-1 mt-2 text-sm">
                    Discussion Detail is required
                  </p>
                )}
              </div>

              <div className="w-full pt-5">
                <label
                  className="block text-base mb-[10px] text-[#181F48] font-medium "
                  htmlFor="description "
                >
                  Add Tags
                </label>
                <div className="relative block">
                  <span className="absolute font-apimp-regular inset-y-0 right-2 flex items-center">
                    <img src="/images/add_circle_tag_input.svg"></img>
                  </span>
                  <TagsInput
                    value={selectedTagInput}
                    onChange={setSelectedTagInput}
                    name="tags"
                    placeHolder="Add Tag"
                    className="w-full tagsWrapper"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between pt-20 pb-5">                
                <div>
                  <input
                    className="bg-white px-4 py-[9px] rounded-[10px] font-light  text-sm cursor-pointer"
                    type="reset"
                  />
                  <input
                    disabled={!isDirty || !isValid}
                    className="disabled:opacity-40 bg-txtPrimary ml-[16px] px-4 py-[9px] rounded-[10px] font-light text-white text-sm cursor-pointer"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default DiscussionModal;