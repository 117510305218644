import React, { useEffect, useState } from "react";
import Loader from "../Common/Loader";

const ResponseModal = ({ handleClose, responseType, responseData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [respData, setRespData] = useState([]);
    useEffect(() => {
        if(responseData === null) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
            // let _setRespData = []
            // for (const [key, value] of Object.entries(responseData)) {
            //     _setRespData.push({[key]: value})
            //   }
        }

    }, [responseData])
    console.log('responseData', responseData);
    return (
        <>
            <div className="fixed top-0 left-0 right-0 z-5000 p-4 
       md:inset-0 h-[calc(100%-1rem)] md:h-full bg-opacity-50 backdrop-blur-sm m-auto"
            >
                <div className="relative min-w-[800px] h-full max-w-2xl md:h-auto m-auto top-[30px]">
                    {/* <!-- Modal content --> */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* <!-- Modal header --> */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Response Details
                            </p>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal"
                                onClick={() => handleClose()}
                            >
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600 h-[400px] overflow-y-scroll overflow-x-hidden">
                            {isLoading && <Loader />}
                            {/* {responseData && responseType === "Synchronous" && 
                            <code className="w-[50vw] break-words">
                            <p className="mb-[16px]">Service Name: {responseData?.serviceName}</p>
                            <p className="mb-[16px]">API Path: {responseData?.apiPath}</p>
                            <p>Response Body: {responseData?.responseBody}</p>
                            </code>
                            } */}
                            <code                                 
                                className="w-[50vw] break-words"
                            >
                                {/* {responseData && responseType === "Asynchronous" && responseData?.responseList?.length > 0 &&
                                  <div class='text-black'> */}
                                    {responseData?.Error?.message ? JSON.stringify(responseData?.Error) : JSON.stringify(responseData?.ExecuteApiResponse)}
                                  {/* </div>
                                } */}
                            {/* {responseData && responseType === "Asynchronous" && responseData?.responseList?.length > 0 && responseData?.responseList?.map((elm, ind) => {
                              let id= `id-${ind}`;  
                             return (   
                              <div
                                key={id}
                                className={ind < responseData?.responseList.length -1 ? "pb-[16px] mb-[16px] border-b-[16px]" : ""}
                              >
                                <p className="mb-[16px]">Service Name: {elm.serviceName}</p>
                                <p className="mb-[16px]">API Path: {elm.apiPath}</p>
                                <p>Response Body: {elm.responseBody}</p> 
                             </div>   
                            )
                            })
                            } */}
                            </code>
                            {/* {responseData && responseData?.ExecuteApiResponse?.errorMessage && 
                            <code className="w-[50vw]">
                                <p className="mb-[16px] text-[red]">Error : {JSON.stringify(responseData)}</p>
                            </code>
                            }  */}
                        </div>
                        {/* <!-- Modal footer --> */}
                        <div className="flex items-right p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">

                            <button
                                data-modal-hide="defaultModal"
                                type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                onClick={() => handleClose()}
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ResponseModal;
