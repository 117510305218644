import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import ModalTemplet from "../Common/ModalTemplet";
import CONFIG from "../../Utils";
import APIService from "../../Services/API";
import Loader from "../Common/Loader";
import Multiselect from 'multiselect-react-dropdown';
import { useCentralStore } from "../../store/appContext";

const AddApplication = ({ showModal, handleCloseApp, id, data, editApplication = false }) => {
  const [{ masterDataDetails, currentUser, currentApplicationData }, dispatch] = useCentralStore();
  const inputRef = useRef(null);
  const [iconUrl, setIconUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApis, setSelectedApis] = useState(false);
  const [selectedAPIDetails, setSelectedAPIDetails] = useState([]);
  const [filteredApiArr, setFilteredApiArr] = useState([])
  const [filteredApiPerPartner, setFilteredApiPerPartner] = useState([])
  const [partners, setPartners] = useState(null);

  const userObj = currentUser// JSON.parse(sessionStorage.getItem("userDetails"));

  useEffect(() => {
    if (editApplication) {
      const filteredArr = masterDataDetails?.Apis.filter((el) => {
        return data.every((f) => {
          return f.Name !== el.Name
        });
      });
      console.log('filteredArr', filteredArr, currentApplicationData);
      setFilteredApiArr(filteredArr?.filter(v => v?.Partner?.toUpperCase() === currentApplicationData?.Partner?.toUpperCase()))
    }
  }, [editApplication])

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      token_type: "QAuth",
    },
    mode: "onChange",
  });

  const handleFileUpload = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setIconUrl(event.target.files[0]);
    event.target.value = null;
  };

  useEffect(() => {
    if (selectedApis) {
      let apis = []
      selectedApis?.map(v => {
        apis.push(
          { Key: v?.InsertionId, Name: v?.Name }
        )
      })
      setSelectedAPIDetails(apis)
    }
  }, [selectedApis])

  const onSubmit = (data) => {
    // setIsLoading(true);
    if (id !== "") {
      // let payload = {
      //   "userId": userObj?.UserId,
      //   "appId": id,
      //   "appName": data.application_name,
      //   "description": data.description
      // }
      // APIService.updateApplication(payload).then((response) => {
      //   if (response.error !== "") {
      //     setIsLoading(false);
      //     CONFIG.TOASTMESSAGES("error", response.message);
      //   } else {
      //     setIsLoading(false);
      //     CONFIG.TOASTMESSAGES("success", response.message);
      //     handleCloseApp();
      //   }
      // })
      //   .catch((e) => {
      //     setIsLoading(false);
      //     CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
      //     console.log("Error - ", e);
      //   });
      if (selectedAPIDetails?.length > 0) {
        let arrReq = selectedAPIDetails?.map(v => {
          return {
            ApplicationId: id,
            UserId: userObj?.UserId,
            Name: v?.Name,
            Key: v?.Key,
            Action: "Add"
          }
        })

        let payload = {
          EnrollApiToApp: [
            ...arrReq
          ]
        }
        APIService.enrollApiToApp(payload).then((response) => {
          if (response) {
            CONFIG.TOASTMESSAGES("success", "API added successfully.");
            // navigate(`/applications`)
            handleCloseApp()
          }
        })
          .catch((e) => {
            CONFIG.TOASTMESSAGES("error", "Internal Server Error.");
          });
      }


      // APIService.deleteApiFromApplicationById(payload).then((response) => {
      // APIService.enrollApiToApp(payload).then((response) => {  
      //   console.log('enrollApiToApp resp', response);
      //   if (response) {
      //     CONFIG.TOASTMESSAGES("success", "Record deleted successfully.");
      //     navigate(`/applications`)
      //   }
      // })
      //   .catch((e) => {
      //     CONFIG.TOASTMESSAGES("error", "Internal Server Error.");
      //     console.log("Error - ", e);
      //   });
    } else {
      const payload = {
        AddApplicationRequest: {
          ApplicationName: data.application_name,
          Description: data.description,
          // RoleId: userObj?.RoleId,
          // UserId: userObj?.UserId,
          Partner: data.partners,
          ApiDetails: selectedAPIDetails
        }
      }

      APIService.addApplication(payload)
        .then((response) => {
          // if (response?.AddApplicationResponse?.error !== "") {
          //   setIsLoading(false);
          //   CONFIG.TOASTMESSAGES("error", response?.AddApplicationResponse?.message);          
          // } else {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("success", response?.AddApplicationResponse?.message);
          handleCloseApp();
          // }           
        })
        .catch((e) => {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
          console.log("Error - ", e);
        });
    }
  }

  useEffect(() => {
    if (id !== "") {
      setValue("application_name", data.appName);
      setValue("description", data.description);
    }
    setPartners(masterDataDetails?.Partners)
  }, []);

  const handleApiSelect = (selectedList, selectedItem) => {
    setSelectedApis(selectedList)
  }

  const handleApiRemove = (selectedList, selectedItem) => {
    setSelectedApis(selectedList)
  }

  const handlePartner = (e) => {
    setFilteredApiPerPartner(masterDataDetails?.Apis?.filter(v => v?.Partner?.toUpperCase() === e.target.value?.toUpperCase()))
  }

  return (
    <>
      <ModalTemplet
        handleClose={handleCloseApp}
        // modalTitle={`${id !== "" ? 'Edit' : 'Add'} Application`}
        modalTitle={`${id !== "" ? 'Add API to Applicaiton' : 'Add Application'} `}
        modalHeaderClass={
          "py-5 pl-10 pr-9 font-apimp-regular text-[20px] font-medium"
        }
        modalBodyClass={" px-20 bg-txtSecondary rounded-t-[30px]"}
        modalBody={
          <>
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              {!editApplication &&
                <>
                  <div className="grid grid-cols-12 ">
                    <div className="col-span-12 mt-6">
                      <label
                        className="relative block font-apimp-regular  text-black text-base font-medium "
                        htmlFor="api_name"
                      >
                        <span className="absolute font-apimp-regular inset-y-0 left-0 flex items-center mt-1">
                          <div className="border-[1px] border-[#181F48] hover:border-[#316AFF] cursor-pointer p-[6px] rounded-[10px] mt-7">
                            <img
                              src="/images/input.svg"
                              onClick={() => handleFileUpload()}
                              alt="input"
                            ></img>
                          </div>
                        </span>
                        Application Icon & Name
                        <input
                          style={{ display: "none" }}
                          ref={inputRef}
                          type="file"
                          onChange={handleFileChange}
                        />
                        <input
                          className="w-full mt-2  rounded-[10px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-sm font-medium  pl-14 pt-[15px] pb-[11px] pr-4  placeholder:text-sm placeholder:font-apimp-light focus:border-[1px] placeholder:font-thin placeholder:text-[#181F48] placeholder:opacity-50 placeholder:text-[12px] "
                          type="text"
                          placeholder="Enter Application Name"
                          // value={editApplication ? currentApplicationData?.ApplicationName : ''}
                          {...register("application_name", { required: true })}
                        />
                      </label>
                      {errors.application_name?.type === "required" && (
                        <p role="alert" className="text-red-700 text-sm ml-2 mt-1">
                          Application Name Required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mt-4">
                    <div className="col-span-12">
                      <label
                        className="block text-base mb-[10px] text-black font-medium "
                        htmlFor="description "
                      >
                        Description
                      </label>
                      <input
                        className="w-full pl-[15px] text-[#121212] rounded-md border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-[12px] font-normal font-apimp-regular pt-[11px]  placeholder: placeholder:font-thin placeholder:text-xs placeholder:text-[#121212] placeholder:opacity-50 placeholder:pt-1 "
                        type="text"
                        placeholder="Enter Details"
                        {...register("description", { required: true })}
                      />
                      {errors.description?.type === "required" && (
                        <p role="alert" className="text-red-700 ml-1 mt-1 text-sm">
                          Description is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-12 mt-4">
                <div className="col-span-12">
                  <label
                    className="block text-base mb-[10px] text-black font-medium "
                    htmlFor="partners "
                  >
                    Partners
                  </label>
                  <select
                    className="w-full pl-[15px] text-[#121212] rounded-md border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-[12px] font-normal font-apimp-regular pt-[11px]  placeholder: placeholder:font-thin placeholder:text-xs placeholder:text-[#121212] placeholder:opacity-50 placeholder:pt-1"
                    name="partners"
                    {...register("partners", { required: true })}
                    onChange={(e) => handlePartner(e)}
                  >
                    <option className="" value="">
                      Select Partner
                    </option>
                    {partners && partners.length > 0 && partners.map((item, index) => (
                      <option
                        key={item.ApiPartnersId}
                        className="py-[8px]" value={item.PartnerName}>
                        {item.PartnerName}
                      </option>
                    ))}
                  </select>
                  {errors.description?.type === "required" && (
                    <p role="alert" className="text-red-700 ml-1 mt-1 text-sm">
                      Description is required
                    </p>
                  )}
                </div>
              </div>
                </>
              }
              <div className={`grid grid-cols-12 mt-4 ${editApplication ? 'pt-10' : ''}`}>
                <div className="col-span-12">
                  <label
                    className="block text-base mb-[10px] text-black font-medium "
                    htmlFor="description "
                  >
                    Select API's
                  </label>
                  <Multiselect
                    style={{
                      width: '100%'
                    }}
                    options={editApplication ? filteredApiArr : filteredApiPerPartner}
                    selectedValues={selectedApis}
                    onSelect={handleApiSelect}
                    onRemove={handleApiRemove}
                    displayValue="Name"
                  // {...register("application_name", { required: true })}
                  />
                </div>
              </div>
              <div className="flex pb-9  justify-end pt-5 ">
                {/* {!editApplication &&
                  <div className="">
                    <input
                      className="  bg-white  px-4 py-[9px] rounded-[10px] font-light  text-sm cursor-pointer"
                      type="reset"
                    />
                  </div>
                } */}
                <div className="px-2">
                  <input
                    disabled={editApplication ? selectedAPIDetails?.length > 0 ? false : true : !isDirty || !isValid}
                    className={`disabled:opacity-40 bg-txtPrimary  px-4 py-[9px] rounded-[10px] font-light text-white text-sm cursor-pointer`}
                    type="submit"
                  />
                </div>
              </div>
            </form>
            {isLoading && <Loader />}
          </>
        }
      />
    </>
  );
};

export default AddApplication;