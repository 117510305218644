import React, { useEffect, useState } from "react";
import APIService from "../../Services/API";
import ForumCard from "../Common/ForumCard";
import Loader from "../Common/Loader";
import TagsCard from "../Common/TagsCard";
import DiscussionModal from "./DiscussionModal";
import CONFIG from "../../Utils";

const Forum = () => {
  const [filterValue, setFilterValue] = useState("Latest Posts");
  const [showModal, setShowModal] = useState(false);
  const [discussionData, setDiscussionData] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);  
  const userObj = JSON.parse(sessionStorage.getItem("userDetails"));

  const handleFilter = (event) => {
    setFilterValue(event.target.value);
    if(event.target.value === "Latest Posts"){
      getAllDiscussion();
    } else {
      getDiscussionByUserId();
    }    
  };  

  const getAllDiscussion = () => {
    setIsLoading(true);
    APIService.getAllDiscussion(userObj.userId).then((response) => {
      if (response) {
        setIsLoading(false);
        setDiscussionData(response); 
      }
    })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  };

  const getDiscussionByUserId = () => {
    setIsLoading(true);
    APIService.getDiscussionByUserId(userObj.userId,userObj.userId).then((response) => {
      if (response) {
        setIsLoading(false);
        setDiscussionData(response); 
      }
    })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  };

  const handleLike = (event, discussionId, likeFlag) => {
    event.preventDefault();
    event.stopPropagation();    
    setIsLoading(true);    
    APIService.likePost(userObj.userId, discussionId, likeFlag)
      .then((response) => {
        if (response.error !== "") {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("error", response.message);          
        } else {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("success", response.message);
          getAllDiscussion();
        }         
      })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "internal server error.");
        console.log("Error - ", e);
      });
  }; 

  const getTopTags = () => {
    APIService.getTopTags()
      .then((response) => {
        if (response) {
          setTagsList(response);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  };

  const handleClose = () => {
    setShowModal(false);
    getAllDiscussion();
    getTopTags();
  }


  useEffect(() => {
    getAllDiscussion();
    getTopTags();
  }, []);
  
  return (
    <>
      <div className="grid px-[20px] pt-[26px] pb-[24px]">
        <div className="flex justify-between">
          <div>
            <h1 className="font-apimp-medium font-medium text-xl">Forums</h1>
          </div>
          <div>
            <button
              type="button"
              className="inline-block px-3 py-2.5 bg-txtPrimary text-white font-inter-regular text-base rounded-xl"
              onClick={() => {
                showModal ? setShowModal(false) : setShowModal(true);
              }}
            >
              <img
                className="inline-flex cursor-pointer relative mr-2"
                src={"images/add_circle.svg"}
                alt="add"
              />
              Start a Discussion
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="relative col-start-1 col-end-6 ">
            <span className="absolute  font-Inter py-5 px-4 flex right-1">
              <img
                src="./images/search.svg"
                alt="search_icon"
                className="text-[#181F48]"
              />
            </span>

            <input
              className="w-full py-4 pl-6 font-apimp-medium font-medium text-base text-[#181F48] rounded-[10px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 placeholder:text-[#181F48] placeholder:opacity-50 placeholder:font-[400px] placeholder:font-apimp-regular"
              type="text"
              placeholder="Whats in your mind..."
            />
          </div>
          <div className="col-start-12 col-end-13">
            <div className="flex justify-end mt-3">
              <select
                className="rounded-[10px] pl-[7px] py-[7px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-xs text-[#181F48] font-apimp-regular"
                defaultValue={filterValue}
                onChange={handleFilter}
              >
                <option className="text-xs py-[5px]" value="Latest Posts">
                  Latest Posts
                </option>
                <option className="text-xs py-[5px]" value="Posts by Me">
                  Posts by Me
                </option>                
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-6">
          <div className="col-span-10 space-y-4">
            {discussionData &&
                discussionData.length > 0 &&
                discussionData.map((data, index) => (
                  <ForumCard 
                    key={data.discussionId} 
                    data={data} 
                    handleLike={handleLike} 
                  />
                ))}
              {discussionData?.length == 0 && <div>No Data Available...</div>}
          </div>
          <div className="col-span-2">
            <div className="px-4 py-8 bg-white border-[0.5px] border-txtPrimary rounded-2xl h-full ml-4">
              <span className="text-base text-[#181F48] font-apimp-semibold font-[600px] mt-6">
                Popular Tags
              </span>
              {tagsList &&
                tagsList.length > 0 &&
                tagsList.map((data, index) => (
                  <TagsCard 
                    key={data.id} 
                    data={data} 
                  />
                ))} 
                {tagsList && tagsList?.length == 0 && <div className="text-xs text-[#000000] font-apimp-regular"></div>}             
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {showModal ? (
            <DiscussionModal               
            handleClose={handleClose} 
            />) : null}
    </>
  );
};

export default Forum;
