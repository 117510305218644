import React, { useState, useEffect, useRef } from "react";
import APICardDetails from "../Common/APICardDetails";
import AddToApplication from "./AddToApplication";
import ApiConsole from "./ApiConsole";
import Documentation from "./Documentation";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Common/Loader";
import APIService from "../../Services/API";
import CONFIG from "../../Utils";
import { useCentralStore } from "../../store/appContext";

const ApiDirectoryCardDetail = () => {
  const [{ currentUser }, dispatch] = useCentralStore();
  const [activeTab, setActiveTab] = useState("Overview");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const [rating, setRating] = useState(0);   
  const params = useParams();
  const userObj = JSON.parse(sessionStorage.getItem("userDetails"));
  const [environment, setEnvironment] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [sdkData, setSdkData] = useState(null);
  const apiUrlRef = useRef();
  const {state} = useLocation();  
  const navigate = useNavigate();
  const userData = useState(currentUser); //JSON.parse(sessionStorage.getItem('userDetails'))

  const getApiCardDetails = () => {
    setIsLoading(true);
    APIService.getApiCardDetails(params.did, userObj.userId)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setApiData(response);  
          console.log("apiData===",response);
          getEnvironmentData(response.apiEnvironmentList[0].environmentName, response.id, response);
                        
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  };

  const getApprovalPendingEntriesById = () => {
    setIsLoading(true);
    APIService.getApprovalPendingEntriesById(params.did)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setApiData(response[0]);  
          console.log("approval response===",response);
          getApprovalEnvironmentData(response[0].apiEnvironment[0].environmentName, response[0].id,response[0]);                        
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  }
  
  const handleRatings = (rate) => {
      setRating(rate);
      setIsLoading(true);
      let payload = {
        "userId": userObj.userId,
        "apiId": params.did,
        "rating": rate.toString()
    }
    
    APIService.viewAndAddRating(payload)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("success", "Ratings added successfully.");
        } else {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("error", response.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  const getApprovalEnvironmentData = (txtVal, apiId, allApiData) => {
    setEnvironment(txtVal);
    let allData = allApiData;
    let filteredData = allData.apiEnvironment.filter((element) => element.environmentName === txtVal);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    if (filteredData.length > 0 && filteredData[0].swaggerUrl === "") {
      setApiUrl(`${BASE_URL}baseUrl/swagger-json-data?key=${apiId}&envName=${txtVal}`);
    } else if(filteredData.length > 0 && filteredData[0].swaggerUrl !== "") {
      setApiUrl(filteredData[0].swaggerUrl);
    } else if(filteredData.length === 0){
      setApiUrl("");
    }
  }

  const getEnvironmentData = (txtVal, apiId, allApiData) => {
    setEnvironment(txtVal);
    let allData = allApiData;
    let filteredData = allData.apiEnvironmentList.filter((element) => element.environmentName === txtVal);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    if (filteredData.length > 0 && filteredData[0].swaggerUrl === "") {
      setApiUrl(`${BASE_URL}baseUrl/swagger-json-data?key=${apiId}&envName=${txtVal}`);
    } else if(filteredData.length > 0 && filteredData[0].swaggerUrl !== "") {
      setApiUrl(filteredData[0].swaggerUrl);
    } else if(filteredData.length === 0){
      setApiUrl("");
    }
  }
  
  const copyApiUrl = () => {
    apiUrlRef.current.select();
  };

  const handleApprove = () => {
    let newData = apiData;
    newData.status = "approved";
    let payload = [newData];
    APIService.updateApprovalStatus(payload).then((response) => {
      if (response) {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("success", "Request approved");
        navigate(`/`);        
      } else {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal server error");
      }
    })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  const handleReject = () => {
    let newData = apiData;
    newData.status = "rejected";
    let payload = [newData];
    APIService.updateApprovalStatus(payload).then((response) => {
      if (response) {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Request rejected");
      } else {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("success", "Internal server error");
        navigate(`/`);
      }
    })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  const getSdkData = () => {
    setActiveTab("Sdk")
    setIsLoading(true);
    APIService.getSdkData(params.did, environment)
      .then((response) => {
        setIsLoading(false);  
        if (response.error!=="") {
          setIsLoading(false);          
          setSdkData(null);                  
        } else {
          setIsLoading(false);   
          setSdkData(response.url); 
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });
  }

  const getPlatformType = (txtEnvironment) => {
      if (!apiData || !apiData.apiEnvironmentList) {
        return ""; 
      }    
      const result = apiData.apiEnvironmentList.find((elem) => elem.environmentName === txtEnvironment);
    
      if (result) {
        return result.platformType;
      }    
      return null;
    }

  useEffect(() => {
    if(state.isApiRequest) {
      getApprovalPendingEntriesById()
    } else {
      getApiCardDetails()
    }   
  }, []);

  return (
    <div className="grid px-5 pt-[26px] pb-[24px]">
      <div className="grid grid-col-3">
        <div className="col-start-1 col-end-3">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3 py-[2.72px]">
              <li className="inline-flex items-center">
                <img src="/images/home.svg" alt="home"></img>
              </li>
              <li>
                <div className="flex items-center">
                  <Link
                    to="/"
                    className="ml-1 text-base font-apimp-regular text-black underline md:ml-2"
                  >
                    API Directory
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <img src="/images/crossline.svg" alt="crossline"></img>
                  <span className="ml-1 text-lg font-apimp-medium text-black md:ml-2">
                    {apiData?.name}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="text-right col-start-3 col-end-4">
        {userData && userData?.RoleName?.toUpperCase() === 'ASURION ADMIN' && state.isApiRequest && <>
            <button
              className="bg-[#4AA785] text-white text-[13px] font-medium py-[9px] px-[16px] rounded-[10px] inline-flex items-center mr-[16px]"
              onClick={() => handleApprove()}
            >              
              Approve
            </button>

            <button
              className="bg-[#E22C2C1A] text-[#E22C2C] text-[13px] font-medium py-[9px] px-[16px] rounded-[10px] rounded-[10px] inline-flex items-center mr-[16px]"
              onClick={() => handleReject()}
            >
              Reject
            </button>
          
        </>}        
          <button
            className="bg-txtPrimary text-white font-apimp-medium text-base py-[6px] px-3 rounded-[10px] inline-flex items-center"
            onClick={() => {
              showModal ? setShowModal(false) : setShowModal(true);
            }}
          >
            <img
              className="mr-2"
              src="/images/add_task.svg"
              alt="add_task"
            ></img>
            Add To Application's
          </button>
        </div>
      </div>

      <div className="rounded-2xl mt-8 border border-black border-opacity-20">
        <APICardDetails 
          {...apiData} 
          handleRatings={handleRatings}
          rating={rating}
        />
        <div className="mb-4 mt-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li className="mr-[20px]" role="presentation">
              <button
                className={`${
                  activeTab === "Overview"
                    ? "border-b-[2px] border-b-txtPrimary hover:text-txtPrimary-600 hover:border-txtPrimary dark:hover:text-txtPrimary"
                    : "hover:text-txtPrimar hover:border-txtPrimary"
                } inline-block p-4 border-b-2 border-transparent rounded-t-lg `}
                id="overview-tab"
                data-tabs-target="#overview"
                type="button"
                role="tab"
                aria-controls="overview"
                aria-selected="false"
                onClick={() => setActiveTab("Overview")}
              >
                Overview
              </button>
            </li>
            <li className="mr-[20px]" role="presentation">
              <button
                className={`${
                  activeTab === "Apiconsole"
                    ? "border-b-[2px] border-b-txtPrimary hover:text-txtPrimary-600 hover:border-txtPrimary dark:hover:text-txtPrimary"
                    : "hover:text-txtPrimar hover:border-txtPrimary"
                } inline-block p-4 border-b-2 rounded-t-lg`}
                id="apiconsole-tab"
                data-tabs-target="#apiconsole"
                type="button"
                role="tab"
                aria-controls="apiconsole"
                aria-selected="false"
                onClick={() => setActiveTab("Apiconsole")}
              >
                API Console
              </button>
            </li>
            <li className="mr-[20px]" role="presentation">
              <button
                className={`${
                  activeTab === "Documentation"
                    ? "border-b-[2px] border-b-txtPrimary hover:text-txtPrimary-600 hover:border-txtPrimary dark:hover:text-txtPrimary"
                    : "hover:text-txtPrimar hover:border-txtPrimary"
                } inline-block p-4 border-b-2 rounded-t-lg`}
                id="documentation-tab"
                data-tabs-target="#documentation"
                type="button"
                role="tab"
                aria-controls="documentation"
                aria-selected="false"
                onClick={() => setActiveTab("Documentation")}
              >
                Documentation
              </button>
            </li>
            <li role="presentation">
              <button
                className={`${
                  activeTab === "Sdk"
                    ? "border-b-[2px] border-b-txtPrimary hover:text-txtPrimary-600 hover:border-txtPrimary dark:hover:text-txtPrimary"
                    : "hover:text-txtPrimar hover:border-txtPrimary"
                } inline-block p-4 border-b-2 rounded-t-lg`}
                id="sdk-tab"
                data-tabs-target="#sdk"
                type="button"
                role="tab"
                aria-controls="sdk"
                aria-selected="false"
                onClick={() => getSdkData()}
              >
                SDKs
              </button>
            </li>
          </ul>
        </div>

        <div id="myTabContent">
          <div
            className={`${activeTab === "Overview" ? "show" : "hidden"
              } p-4 rounded-2xl bg-gray-50 dark:bg-gray-800`}
            id="overview"
            role="tabpanel"
            aria-labelledby="overview-tab"
          >
            <div className="flex gap-4 mb-[16px]">
              {apiData && !state.isApiRequest && apiData.apiEnvironmentList.length > 0 && apiData.apiEnvironmentList.map((data, index) =>
              {
                let id = `id-${index}`
                return (
                <label key={id}>
                  <div className="flex items-center text-[16px] text-black font-medium">
                    <input
                      type="radio"
                      value={data.environmentName}
                      className="mr-2"
                      name={`environmentName`}
                      checked={environment === data.environmentName}
                      onChange={(e) => getEnvironmentData(e.target.value, apiData.id, apiData)}
                      disabled={userData && userData?.RoleName?.toUpperCase() !== "ASURION ADMIN" && (data.environmentName === "Production" || data.environmentName === "Development") }
                    />
                    {data.environmentName}
                  </div>
                </label>
              )})}
              {apiData && state.isApiRequest && apiData.apiEnvironment.length > 0 && apiData.apiEnvironment.map((data, index) => {
                let id = `id-${index}`
                return (
                <label key={id}>
                  <div className="flex items-center text-[16px] text-black font-medium">
                    <input
                      type="radio"
                      value={data.environmentName}
                      className="mr-2"
                      name={`environmentName`}
                      checked={environment === data.environmentName}
                      onChange={(e) => getEnvironmentData(e.target.value, apiData.id, apiData)}
                      disabled={userData && userData?.RoleName?.toUpperCase() !== "ASURION ADMIN" && (data.environmentName === "Production" || data.environmentName === "Development") }
                    />
                    {data.environmentName}
                  </div>
                </label>
              )})}
            </div>
            {environment &&
              <div className="relative">
                <input
                  type="text"
                  readOnly
                  id="api_url"
                  name="api_url0"
                  className="w-full py-[10px] text-[#00000080] text-[14px] rounded-[10px] mb-[16px] border border-[#D9D9D9]"
                  value={apiUrl}
                  ref={el => (apiUrlRef.current = el)}
                />
                <img
                  src="/images/content_copy.svg"
                  className="absolute right-[16px] top-[13px] cursor-pointer"
                  alt="content_copy"
                  onClick={() => (copyApiUrl())}
                />
              </div>
            }
          </div>
          <div
            className={`${
              activeTab === "Apiconsole" ? "show" : "hidden"
            } rounded-2xl bg-white dark:bg-gray-800`}
            id="apiconsole"
            role="tabpanel"
            aria-labelledby="apiconsole-tab"
          >
            {activeTab === "Apiconsole" && apiData &&             
                  <ApiConsole 
                      apiUrl = {apiUrl}
                      customHeaders = {apiData?.apiEnvironmentList[0]?.customHeaders}
                  />
            }
          </div>
          <div
            className={`${
              activeTab === "Documentation" ? "show" : "hidden"
            } p-4 rounded-2xl bg-gray-50 dark:bg-gray-800`}
            id="documentation"
            role="tabpanel"
            aria-labelledby="documentation-tab"
          >
            {activeTab === "Documentation" && apiData &&
                <Documentation 
                  apiUrl = {apiUrl}
                  userData={userData}
                />
            }
          </div>
          <div
            className={`${
              activeTab === "Sdk" ? "show" : "hidden"
            } p-4 rounded-2xl bg-gray-50 dark:bg-gray-800`}
            id="sdk"
            role="tabpanel"
            aria-labelledby="sdk-tab"
          >
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {sdkData === null && <span>sdk url not found</span>}
              {sdkData !== null &&
                <a href={sdkData} target="_blank" rel="noreferrer"><span className="download">Download</span>
                </a>
              }
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
        {showModal ? (
          <>
          <AddToApplication 
            showModal={showModal} 
            handleCloseModal={handleCloseModal} 
            baseUrlId={params.did}
            environmentName={environment}
            apiUrl={apiUrl}
            platformType={getPlatformType(environment)}
          />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ApiDirectoryCardDetail;
