const UtilityService = {

  GET_USER_DETAILS: (token) => {
    if (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);

    }
  },

  updateXAmazonDoc: (data) => {
    let allData = data;
    if (Object.keys(allData).includes("x-amazon-apigateway-documentation")) {
      let documentationData = allData["x-amazon-apigateway-documentation"]?.documentationParts;
      const filteredData = documentationData.filter(elem => (elem.location.type === "REQUEST_BODY" || elem.location.type === "REQUEST_QUERY" || elem.location.type === "REQUEST_HEADER" || elem.location.type === "REQUEST_PATH"));
      if (filteredData.length > 0) {
        filteredData.map((data, index) => {
          if (data?.properties?.schema) {
            let schemaObj = data?.properties?.schema;
            data.parameters = [];
            let keys = Object.keys(schemaObj);
            let temp = schemaObj[keys];
            let keysnew = Object.keys(schemaObj[keys]);
            (keysnew && keysnew.length > 0 && keysnew.map((val, index) => {
              let obj = {
                "in": data.location.type,
                "name": val,
                "type": temp[val],
                "value": "",
                "key": keys[0]
              }
              data.parameters.push(obj);
              if (typeof (temp[val]) == "object" && Object.keys(temp[val]).length > 0) {
                Object.keys(temp[val]).map((childKey, childIndex) => {
                  let newObj = {
                    "in": data.location.type,
                    "name": childKey,
                    "type": temp[val],
                    "value": "",
                    "key": keys[0]
                  };
                  data.parameters.keys[0] = newObj;
                })

              }
            }));

          }
        })
      }

    }
    return allData;
  },

  formatSwaggerMethods: (objDoc, definitions) => {
    let objData = objDoc;
    let aryData = Object.values(objData);
    let aryKeys = Object.keys(objData);

    aryData.forEach((val, index) => {
      let temp = Object.keys(val);
      let temp2 = Object.values(val);

      if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.items) {
        let txtSchema = temp2[0].parameters[0].schema.items.$ref.split("/");
        let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
        let arrOfValues = Object.values(allParam);
        let arrOfKeys = Object.keys(allParam);

        arrOfValues.forEach((val, index) => {
          val.name = arrOfKeys[index];
          return val;
        });

        val[temp].definitions = arrOfValues;
      }

      if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.$ref) {
        let txtSchema = temp2[0].parameters[0].schema.$ref.split("/");
        let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
        let arrOfValues = Object.values(allParam);
        let arrOfKeys = Object.keys(allParam);

        arrOfValues.length > 0 && arrOfValues.forEach((val, index) => {
          val.name = arrOfKeys[index];
          return val;
        });
        val[temp[0]]["definitions"] = arrOfValues;
      }

      val[temp[0]].pathString = aryKeys[index];
      val[temp[0]].selected = false;
      return val;
    });

    let temp = {};

    aryData.forEach((item, index) => {
      let keys = Object.keys(item);
      let values = Object.values(item);

      keys.forEach((val, ind) => {
        if (temp[val]) {
          temp[val].push(values[ind]);
        } else {
          temp[val] = [];
          temp[val].push(values[ind]);
        }
      });
    });

    return temp;
  }

};
export default UtilityService;
