import React from "react";
import { useForm } from 'react-hook-form';
import APIService from "../../Services/API";
import CONFIG from "../../Utils";

const UserConfirmBox = ({ handleCloseConfirmation, selectedDetails, roles, partners }) => {  

    const { register, handleSubmit, formState: { errors }, } = useForm();
    
    const onApproveSubmit = (data) => {
        let payload = {
            "useraccessId": selectedDetails.useraccessId,        
            "username": selectedDetails.username,        
            "email": selectedDetails.email,        
            "isApproved": "Approved",        
            "hasRole": data.role,        
            "employeeId": selectedDetails.employeeId,        
            "comment": null,        
            "partners": data.partners        
        }            
        APIService.updateUserAccessDetails(payload).then((response) => {
            if (response) {
                CONFIG.TOASTMESSAGES("success", "User Approved successfully");
                handleCloseConfirmation();
            }
        }).catch((e) => {
                CONFIG.TOASTMESSAGES("error", e);
                console.log("Error - ", e);
        });
    } 

    const onRejectSubmit = (data) => {
        
        let payload = {
            "useraccessId": selectedDetails.useraccessId,        
            "username": selectedDetails.username,        
            "email": selectedDetails.email,        
            "isApproved": "Rejected",        
            "hasRole": selectedDetails.hasRole,        
            "employeeId": selectedDetails.employeeId,        
            "comment": data.reasonForRejection,        
            "partners": data.partners        
        }               
        APIService.updateUserAccessDetails(payload).then((response) => {
            if (response) {
                CONFIG.TOASTMESSAGES("success", "User Rejected successfully");
                handleCloseConfirmation();
            }
        }).catch((e) => {
                CONFIG.TOASTMESSAGES("error", e);
                console.log("Error - ", e);
        });
    } 
  
  return (
    <>
      <div id="confirmationModal" tabIndex="-1" className="fixed top-0 left-0 right-0 z-5000 p-4 
       md:inset-0 h-[calc(100%-1rem)] md:h-full bg-opacity-50 backdrop-blur-sm m-auto"
       
       >
    <div className="relative w-[500px] h-full max-w-2xl md:h-auto m-auto top-[120px]">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">   
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    {selectedDetails.title} - {selectedDetails.username}
                </p>
                <button 
                    type="button" 
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal"
                    onClick={() => handleCloseConfirmation()}
                >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div> 
            {selectedDetails.status === "Approved" && <div className="p-[20px]">
                <form onSubmit={handleSubmit(onApproveSubmit)}>
                    <div className="mb-[15px]">
                        <label
                          className="w-[100px] inline-block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="role"
                        >
                            Role
                        </label>
                        <select
                          className="w-[50%] ml-[30px] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                          name="role"
                          {...register("role", { required: true })}                          
                        >
                          <option className="" value="">
                            Select Role
                          </option>
                          {roles && roles.length > 0 && roles.map((item, index) => (
                            <option
                              key={item.roleId}
                              className="py-[8px]" value={item.roleName}>
                              {item.roleName}
                            </option>
                          ))}
                        </select>
                    </div>
                    {errors.role?.type === "required" && (
                        <div className="text-[red] text-[14px] my-[10px]">* Role Required</div>
                    )}

                    <div className="">
                        <label
                          className="w-[100px] inline-block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="partners"
                        >
                            Partners
                        </label>
                        <select
                          className="w-[50%] ml-[30px] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                          name="partners"
                          {...register("partners", { required: false })}                          
                        >
                          <option className="" value="">
                            Select Partner
                          </option> 
                          {partners && partners.length > 0 && partners.map((item, index) => (
                            <option
                              key={item.partnerId}
                              className="py-[8px]" value={item.partnerName}>
                              {item.partnerName}
                            </option>
                          ))}                         
                        </select>
                    </div>
                    
                    <div className='mt-[1rem] flex justify-end'>
                        <button onClick={handleSubmit(onApproveSubmit)} className='bg-txtPrimary  px-4 py-[9px] rounded-[10px] font-light text-white text-sm cursor-pointer'>
                            Approve</button>
                    </div>
                </form>
              </div>  
            }
            {selectedDetails.status === "Rejected" && <div className="p-[20px]">
                <form onSubmit={handleSubmit(onRejectSubmit)}>
                    <div className="">
                        <label
                          className="block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="role"
                        >
                            Reason for rejection
                        </label>
                        <textarea
                        rows="3"
                        cols="50"
                        className="text-[#121212] rounded-md border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-[12px] font-normal font-apimp-regular pt-[11px]"
                        placeholder="Enter reason for rejection"
                        name="reasonForRejection"
                        {...register("reasonForRejection", { required: false })} 
                      />                        
                    </div>
                    
                    <div className='mt-[1rem] flex justify-end'>
                        <button onClick={handleSubmit(onRejectSubmit)} className='bg-txtPrimary  px-4 py-[9px] rounded-[10px] font-light text-white text-sm cursor-pointer'>
                            Reject</button>
                    </div>
                </form>
              </div>  
            } 
        </div>
    </div>
</div>
    </>
  );
};

export default UserConfirmBox;
