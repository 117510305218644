import React,  {useEffect, useState, Component, createContext, useMemo } from 'react';

const Context = createContext();
const userObj = JSON.parse(sessionStorage.getItem('userDetails')) || null;

const ContextWrapper = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const isNavigation = userObj?.isRegulatoryAssessmentComplete || false;

    useEffect(() => {
        checkUserIsLogin();
    }, []);

    const checkUserIsLogin = () => {
        const isSession = sessionStorage.getItem('userDetails') || false;
        if (isSession) {
            setUserDetails(JSON.parse(isSession));
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    };

    const setUserDetailsWrapper = (data) => {
        sessionStorage.setItem("userDetails", JSON.stringify(data));
        setUserDetails(data);
        setIsLoggedIn(true);
    };

    const logOutWrapper = () => {
        sessionStorage.removeItem("userDetails");
        setUserDetails(null);
        setIsLoggedIn(false);
    };

    const value = useMemo(() => ({
        isLoggedIn,
        userDetails,
        isNavigation,
        setContextUser: setUserDetailsWrapper,
        logOut: logOutWrapper
    }), [isLoggedIn, userDetails, isNavigation]);

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
};

export class ContextUtilsStore extends Component {
    render() {
        return (
            <ContextWrapper>
                {this.props.children}
            </ContextWrapper>
        );
    }
}

export const UtilsConsumer = Context.Consumer;
export default Context;