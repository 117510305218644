import { createContext, useContext, useReducer } from "react";

const AppContext = createContext();

const { Provider } = AppContext;

const ContextProvider = ({ children, initialState, reducer }) => {
  return (
    <Provider value={useReducer(reducer, initialState)}>{children}</Provider>
  );
};

export const useCentralStore = () => useContext(AppContext);

export default ContextProvider;
