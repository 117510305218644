import React, { Fragment } from 'react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const ApiConsole = (props) => {    

    const requestInterceptor = (req) => {
        {props?.customHeaders && Object.entries(props?.customHeaders).map(([key, value]) => (
                req.headers[key] = value         
        ))}
        return req;
    };

    return (
        <Fragment>
            <div className='rounded-2xl border border-[#316AFF] border-opacity-40 mt-[20px]'>
                <div
                    className="swagger_wrapper"
                >
                    <SwaggerUI
                        url={props.apiUrl}
                        requestInterceptor={requestInterceptor}
                    />                    
                </div>
            </div>
        </Fragment>
    )
}

export default ApiConsole