import React from "react";
import { Navigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_SUBCRIBE_URL = process.env.REACT_APP_BASE_SUBSCRIBE_URL;
const USER_URL = process.env.REACT_APP_USER_URL;
const TEST_URL = window.location.href;
const USER_ACCESS_URL = process.env.REACT_APP_USER_ACCESS_URL;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function fetchStatusHandler(response) {
  if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 500) {
    return response;
  } else {
    return <Navigate to="/error" />;
  }
}


const AppServices = {

  getjwtToken: () => {
    let url = `${TEST_URL}`;
    let req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send(null);
    let responseHeaders = req.getAllResponseHeaders();
    let arr = responseHeaders.split('\r\n');
    let headers = arr.reduce(function (acc, current, i) {
      let parts = current.split(': ');
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
    return headers.authorization
  },

  getjwtTokenByCookie: (cookieName) => {
    const cookie = document.cookie.split(';')
      .find(cookie => cookie.trim().startsWith(`${cookieName}=`));

    if (cookie) {
      const [, cookieValue] = cookie.split('=');
      return cookieValue;
    }

    return null;
  },

  getAccessCode: () => {
    let url = `${TEST_URL}`;
    let req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send(null);
    let responseHeaders = req.getAllResponseHeaders();
    let arr = responseHeaders.split('\r\n');
    let headers = arr.reduce(function (acc, current, i) {
      let parts = current.split(': ');
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
    return headers.code
  },


  getApiCardData: (emailId) => {
    let url = `${BASE_URL}baseUrl/all?emailId=${emailId}`;
    return fetch(url, {
      mode: "cors",
      method: "GET",
    })
      .then((response) => response.json());
  },

  addApiDetails: (payload) => {
    let url = `${BASE_URL}baseUrl/addBaseUrl`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json());
  },

  // addApplication: (payload) => {
  //   let url = `${BASE_URL}subscribe/addSubscriptionApp`;
  //   return fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(payload)
  //   })
  //     .then((response) => response.json());
  // },

  getAllCategories: () => {
    let url = `${BASE_URL}dropDown/category`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getAllTags: () => {
    let url = `${BASE_URL}dropDown/allTags`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  searchByNameCategoryTags: (txtValue) => {
    let url = `${BASE_URL}search/apiDetails?key=${txtValue}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  searchByCategory: (txtValue) => {
    let url = `${BASE_URL}search/apiDetails/category?category=${txtValue}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  searchByTag: (txtTag) => {
    let url = `${BASE_URL}search/apiDetails/tag?tag=${txtTag}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getApiDetailsById: (apiId, userId) => {
    let url = `${BASE_URL}baseUrl/${apiId}/${userId}`;
    return fetch(url, {
      method: "GET"
    })
      .then((response) => response.json());
  },

  getApplicationDetailsById: (apiId, userId) => {
    let url = `${BASE_URL}subscribe/appDetails?userId=${userId}&appId=${apiId}`;
    return fetch(url, {
      method: "GET"
    })
      .then((response) => response.json());
  },


  getApplicationCard: (userId) => {
    let url = `${BASE_URL}subscribe/allApp?userId=${userId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getApplicationAPI: (userId, baseUrlId) => {
    let url = `${BASE_URL}subscribe/allApp?userId=${userId}&baseUrlId=${baseUrlId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getApiCardDetails: (apiId, userId) => {
    let url = `${BASE_URL}baseUrl/apiDetails?apiId=${apiId}&userId=${userId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  deleteApplicationById: (appId, txtUser) => {
    let url = `${BASE_SUBCRIBE_URL}deleteApp?userId=${txtUser}&appId=${appId}`;
    return fetch(url, {
      method: "DELETE",
    })
      .then((response) => response.json());
  },

  getApplicationByName: (userId, appName) => {
    let url = `${BASE_URL}search/apiDetails/appName?id=${userId}&appName=${appName}`;
    return fetch(url, {
      method: "GET"
    })
      .then((response) => response.json());
  },

  addApiToApplication: (payload) => {
    let url = `${BASE_URL}subscribe/addSubscriptionBaseUrl`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  deleteApiFromApplicationById: (payload) => {
    let url = `${BASE_SUBCRIBE_URL}deleteApi`;
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  updateApplication: (payload) => {
    let url = `${BASE_URL}subscribe/updateApp`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  addDiscussion: (payload) => {
    let url = `${BASE_URL}discussionForum/addDiscussion`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  getAllDiscussion: (userId) => {
    let url = `${BASE_URL}discussionForum/allDiscussion/${userId}//`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getDiscussionByUserId: (txtCreatedBy, txtEmailId) => {
    let url = `${BASE_URL}discussionForum/allDiscussionCreatedBy/${txtCreatedBy}/${txtEmailId}//`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  addCommentReply: (payload) => {
    let url = `${BASE_URL}discussionForum/addComment`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  updateView: (discussionId) => {
    let url = `${BASE_URL}discussionForum/updateViews/${discussionId}`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.text());
  },

  viewAndAddRating: (payload) => {
    let url = `${BASE_URL}baseUrl/viewAndAddRating`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  updateApiInApp: (payload) => {
    let url = `${BASE_URL}subscribe/updateApiInApp`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.text());
  },

  getTopTags: () => {
    let url = `${BASE_URL}discussionForum/topTags`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  likePost: (emailId, discussionId, flg) => {
    let url = `${BASE_URL}discussionForum/updateLikes/${emailId}/${discussionId}/${flg}`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json());
  },

  getApiDocs: (txtUrl) => {
    let url = `${txtUrl}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  savedApi: (emailId, apiId, savedFlag) => {
    let url = `${BASE_URL}baseUrl/savedApi?userId=${emailId}&apiId=${apiId}&saveAPI=${savedFlag}`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json());
  },

  getSavedApiData: (emailId) => {
    let url = `${BASE_URL}baseUrl/getSavedApi?userId=${emailId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  deleteApiById: (apiId, emailId) => {
    let url = `${BASE_URL}baseUrl/deleteByUserId/${apiId}?createdBy=${emailId}`;
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json());
  },

  selectedUrl: (payload) => {
    let url = `${BASE_SUBCRIBE_URL}selectedUrl`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.text());
  },

  // asyncApiRequest: (payload) => {
  //   let url = `${BASE_URL}asyncApiRequest`;
  //   return fetch(url, {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: JSON.stringify(payload)
  //   })
  //     .then((response) => response.json());
  // },

  synchronousApiRequest: (payload) => {
    let url = `${BASE_URL}synchronousApiRequest`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  codeSnippet: (languageType, headerType, payload) => {
    let url = `${BASE_URL}codeSnippet?languageType=${languageType}&headerType=${headerType}`;
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.text());
  },

  apiDetailsInApp: (appid, apiid, txtUser) => {
    let url = `${BASE_SUBCRIBE_URL}apiDetailsInApp?userId=${txtUser}&appId=${appid}&baseurlId=${apiid}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getTopCategories: () => {
    let url = `${BASE_URL}search/topFiveSearch?searchType=APICATEGORY`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getSdkData: (id, envName) => {
    let url = `${BASE_URL}baseUrl/sdk-url?baseUrlId=${id}&envName=${envName}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getApprovalPendingEntries: () => {
    let url = `${BASE_URL}admin/retieveApprovalPendingEntries`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getApprovalPendingEntriesById: (apiId) => {
    let url = `${BASE_URL}admin/retieveApprovalPendingEntriesById?apiId=${apiId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  updateApprovalStatus: (payload) => {
    let url = `${BASE_URL}admin/updateApprovalStatus`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  getPreSignedUrl: (file) => {
    let url = `https://0vw3f01ngf.execute-api.ap-northeast-1.amazonaws.com/dev/baseUrl/preSignedUrl?fileName=${file}&methodType=PUT`;
    return fetch(url, {
      method: "GET"
    })
      .then((response) => response.text());
  },

  uploadFileToBucket: (txturl, formData) => {
    return fetch(txturl, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.text());
  },

  getUserAccessDetails: (emailId) => {
    let url = `${BASE_URL}userAccessDetails/byUsername/${emailId}`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getUserAccessRoles: () => {
    let url = `${BASE_URL}userAccessDetails/roleList`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getAllUserAccessDetails: () => {
    let url = `${BASE_URL}userAccessDetails/all`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  getAllPartners: () => {
    let url = `${BASE_URL}userAccessDetails/allPartners`;
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.json());
  },

  updateUserAccessDetails: (payload) => {
    let url = `${BASE_URL}userAccessDetails/update`;
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },


  returnTempVa: (objDoc, definitions) => {

    if (objDoc && definitions) {

      let objData = objDoc;
      let aryData = Object.values(objData);
      let aryKeys = Object.keys(objData);

      aryData.forEach((val, index) => {
        let temp = Object.keys(val);
        let temp2 = Object.values(val);

        if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.items) {
          let txtSchema = temp2[0].parameters[0].schema.items.$ref.split("/");
          let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
          let arrOfValues = Object.values(allParam);
          let arrOfKeys = Object.keys(allParam);

          arrOfValues.forEach((val, index) => {
            val.name = arrOfKeys[index];
            return val;
          });

          val[temp].definitions = arrOfValues;
        }

        if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.$ref) {
          let txtSchema = temp2[0].parameters[0].schema.$ref.split("/");
          let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
          let arrOfValues = Object.values(allParam);
          let arrOfKeys = Object.keys(allParam);

          arrOfValues.length > 0 && arrOfValues.forEach((val, index) => {
            val.name = arrOfKeys[index];
            return val;
          });
          val[temp[0]]["definitions"] = arrOfValues;
        }

        val[temp[0]].pathString = aryKeys[index];
        val[temp[0]].selected = false;
        return val;
      });

      let temp = {};

      aryData.forEach((item, index) => {
        let keys = Object.keys(item);
        let values = Object.values(item);

        keys.forEach((val, ind) => {
          if (temp[val]) {
            temp[val].push(values[ind]);
          } else {
            temp[val] = [];
            temp[val].push(values[ind]);
          }
        });
      });

      return temp;

    }

  },

  // =========================================================================================
  
  getMasterData: () => {
    let url = `${API_BASE_URL}apiass/api/masterdata`;
    return fetch(url, {
      method: "GET",
      headers: {
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      }
    })
      .then((response) => response.json());
  },

  getUsersList: () => {
    let url = `${API_BASE_URL}apiass/api/getuser`;
    return fetch(url, {
      method: "GET",
      headers: {
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      }
    })
      .then((response) => response.json());
  },

  getSwaggerDetails: (id) => {
    let url = `${API_BASE_URL}apiass/api/swaggerjsondata?key=${id}`;
    return fetch(url, {
      method: "GET",
      headers: {
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
    })
      .then((response) => response.json());
  },

  asyncApiRequest: (payload) => {
    let url = `${API_BASE_URL}apiass/api/executeapi`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  createUser: (payload) => {
    let url = `${API_BASE_URL}apiass/api/createuser`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  updateUser: (payload) => {
    let url = `${API_BASE_URL}apiass/api/updateuser`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  addApplication: (payload) => {
    let url = `${API_BASE_URL}apiass/api/addapplication`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  getApplicationListByUser: (userId) => {
    let url = `${API_BASE_URL}apiass/api/getapplicationbyuser/${userId}`;
    return fetch(url, {
      method: "GET",
      headers: {
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
    })
      .then((response) => response.json());
  },

  getListOfApis: (applId) => {
    let url = `${API_BASE_URL}apiass/api/getlistofapis/${applId}`;
    return fetch(url, {
      method: "GET",
      headers: {
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
    })
      .then((response) => response.json());
  },

  createAPI: (payload) => {
    let url = `${API_BASE_URL}/apiass/api/createapi`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  enrollApiToApp: (payload) => {
    let url = `${API_BASE_URL}/apiass/api/enrollapitoapp`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

  deleteApplication: (payload) => {
    let url = `${API_BASE_URL}/apiass/api/deleteapplication`;
    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        'x-api-key': process.env.REACT_APP_API_X_API_KEY
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => response.json());
  },

};
export default AppServices;