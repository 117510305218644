import React, { useState, useRef, useEffect } from "react";
import FilterOptions from "./FilterOptions";
import FilterIcon from './FilterIcon'
const Search = (props) => {
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [searchInputText, setSearchInputText] = useState("")
  const ref = useRef();

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
    }
    useOnClickOutside(ref, () => setOpenFilterPopup(false));
  return (
    <div className="relative px-14 w-[60%] mx-auto" ref={ref}>
      <p className="mb-2 text-sm">Search API Name, Category</p>
      <span className="absolute font-Inter p-4 flex left-[6%] 2xl:left-[4.5%]">
        <img
          src="./images/search.svg"
          alt="search_icon"
          className="text-[#181F48]"
        />
      </span>
      {/* <span className={`${openFilterPopup ? 'bg-txtPrimary text-white' : 'bg-lightPurple text-txtPrimary' } px-3 py-1 absolute right-[22%] 2xl:right-[15%] text-[14px] top-[50%] rounded-lg cursor-pointer`}  */}
        {/* onClick={()=> setOpenFilterPopup(!openFilterPopup)}> */}
        {/* <img src='./images/filterIcon.svg' className="inline-block mr-1" /> */}
        {/* <FilterIcon fill={openFilterPopup ? '#fff' : '#6700B8'} className='inline-block mr-1' /> */}
        {/* Filter */}
      {/* </span> */}
      <div className="flex">
      <input
        className="w-full py-3 pl-8 font-apimp-medium font-medium text-base text-[#181F48] rounded-[10px] border border-txtPrimary p-2 outline-none placeholder:text-[#181F48] placeholder:opacity-50 placeholder:font-[400px] placeholder:font-apimp-regular"
        placeholder="Search"
        onChange={(e) => setSearchInputText(e.target.value)}
        // onKeyUp={(event) => props.handleSearchTerm(event)}
      />
      <button className="bg-black rounded-full ml-5 py-3 px-5 text-white text-sm h-fit my-auto shadow-gray-300 shadow-xl" onClick={() => props.handleSearchTerm(searchInputText)}>
        Search
      </button>
      </div>
      {openFilterPopup &&
          <FilterOptions 
            closeFilter={(val) => setOpenFilterPopup(val)} 
            setTagOptions={(e) => props.setTagOptions(e)}
            setEnrolmentsValues={(e) => props.setEnrolmentsValues(e)}
            setSortngvalues={(e) => props.setSortngvalues(e)}
            selectedTags={props.selectedTags}
            selectedEnrolmentsValues={props.selectedEnrolmentsValues}
            selectedSortingvalues={props.selectedSortingvalues}
            setApplyFilter={(val) => props.setApplyFilter(val)}
            _tagsListObj={props._tagsListObj} />
      }
    </div>
  );
};

export default Search;
