import React, { useEffect, useState } from "react";
import { AddToApplicationCard } from "../Common/AddToApplicationCard";
import APIService from "../../Services/API";
import CONFIG from "../../Utils";

const AddToApplication = ({ showModal, handleCloseModal, baseUrlId, environmentName, apiUrl, platformType }) => {
  const [apiData, setApiData] = useState();
  const [activeApi, setActiveApi] = useState([]); 
  const [inActiveApi, setInActiveApi] = useState([]);  
  const [defaultActive, setDefaultActive] = useState([]);
  const [defaultInActive, setDefaultInActive] = useState([]);

  const handleActive = (txtChecked, txtValue) => {
    
    let activeApiAry = JSON.parse(JSON.stringify(activeApi));
    let inActiveApiAry = JSON.parse(JSON.stringify(inActiveApi));
    
    if (txtChecked) {
      const index = inActiveApiAry.indexOf(txtValue);
      inActiveApiAry.splice(index, 1);
      setInActiveApi(inActiveApiAry);
      activeApiAry.push(txtValue);
      setActiveApi(activeApiAry);
    } else {
      const index = activeApiAry.indexOf(txtValue);
      activeApiAry.splice(index, 1);
      setActiveApi(activeApiAry);
      inActiveApiAry.push(txtValue);
      setInActiveApi(inActiveApiAry);
    }   
  }

  const addApiToApplication = () => {   
    arrayEquals(); 
    let obj = JSON.parse(sessionStorage.getItem("userDetails"));

    let filteredActiveArray = activeApi.filter((element) => !defaultActive.includes(element));
    let filteredInActiveArray = inActiveApi.filter((element) => !defaultInActive.includes(element));

    let payload = {
                    "baseUrlId": {
                      "id":parseInt(baseUrlId),
                    } ,
                    "enableBaseUrlIdForApp": filteredActiveArray,
                    "disableBaseUrlIdForApp": filteredInActiveArray,
                    "userId": obj.userId,
                    "envName": environmentName,
                    "swaggerUrl": apiUrl,
                    "platformType": platformType
                  }

    APIService.addApiToApplication(payload).then((response) => {
        if (response.error) {
          CONFIG.TOASTMESSAGES("error", response.message);
        } else {
          CONFIG.TOASTMESSAGES("success", response.message);
          handleCloseModal();
        }
      })
      .catch((e) => {
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
      });
  };

  const handleReset = () => {
    setActiveApi(defaultActive);
    setInActiveApi(defaultInActive);
  }

  const handleApiStatus = (data) => {
      const activeAry = [];
      const inactiveAry = [];
      const defaultActAry = [];
      const defaultInActAry = [];
      data.map((item, index) => {
          if(item.enableBaseUrl){
            activeAry.push(item.id);
            defaultActAry.push(item.id);
          } else {
            inactiveAry.push(item.id);
            defaultInActAry.push(item.id);
          }
      });
      setActiveApi(activeAry);
      setInActiveApi(inactiveAry);
      setDefaultActive(defaultActAry);
      setDefaultInActive(defaultInActAry);
  }

  function arrayEquals() { return Array.isArray(activeApi) && Array.isArray(defaultActive) && activeApi.length === defaultActive.length && activeApi.every((val, index) => val === defaultActive[index]); }

  const getListOfApi = () => {
    let obj = JSON.parse(sessionStorage.getItem("userDetails"));
    APIService.getApplicationAPI(obj.userId, baseUrlId).then((response) => {
        if (response) {
          setApiData(response);
          handleApiStatus(response);
        }
      })
      .catch((e) => {
      });
  };

  useEffect(() => {
    getListOfApi();
  }, []);

  return (
    <div>
      <div
        id="create-new-project"
        tabIndex="-1"
        aria-hidden="false"
        className="fixed top-0 right-0 left-0 z-50 w-full md:inset-0  bg-black bg-opacity-50 backdrop-blur-sm"
        aria-modal="true"
        role="dialog"
      >
        <div
          //Removed ${sizeClass}
          className="flex justify-center m-auto margin-auto"
          data-modal-show="true"
        >
          <div className="bg-uploadform relative top-[100px] rounded-[15px] bg-white w-[1200px]">
            <div className=" pt-6 px-9 overflow-y-scroll overflow-x-hidden">
              <div className="grid grid-cols-12 items-center">
                <div className="col-start-1 col-end-4 text-base font-apimp-medium font-[500px] text-[#181F48]">
                  Add To Application's
                </div>
                <div className="col-start-9 col-end-13">
                  <div className="flex ml-5">
                    <input
                      className="font-apimp-light py-[3px] w-full text-[10px] text-[#1C1C1C]  border-black border-opacity-20 rounded-[10px] border-[0.5px] placeholder:text-[#181F48] placeholder:opacity-60 placeholder:font-[400px] placeholder:font-apimp-regular"
                      type="text"
                      placeholder="Search Applications"
                    />

                    <button
                      type="button"
                      className="text-gray-400 bg-transparent  rounded-full text-sm ml-5   inline-flex items-center"
                      onClick={() => handleCloseModal()}
                    >
                      <img src="/images/cancel.svg"></img>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                </div>
              </div>
              <div 
                className="grid grid-cols-3 gap-x-6 gap-y-4 mt-7 h-[250px]"
              >
                {apiData && apiData.length > 0 && apiData.map((data, index) => (
                    <AddToApplicationCard 
                      key={data.id}
                      {...data} 
                      activeApi={activeApi}
                      handleActive={handleActive} 
                    />
                ))
                }
                {apiData && apiData.length == 0 &&  (
                    <div 
                      className="text-base"
                    >No Data Available...</div>
                )
                }
              </div>
              <div className="flex pb-9  justify-end pt-8">
                <button
                  className="bg-slate-100 mr-2 px-4 py-[9px] rounded-[10px] font-light  text-sm cursor-pointer"
                  type="reset"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>

                <button
                  className={`bg-txtPrimary px-4 py-[9px] rounded-[10px] font-light text-white text-sm ${arrayEquals() ? 'opacity-20' : ''} `}
                  disabled={arrayEquals()}
                  onClick={() => addApiToApplication()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToApplication;
