import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CONFIG = {
    TOASTMESSAGES: (type, message, id) => {
        let options = {
            position: toast.POSITION.TOP_CENTER,
            containerId: id,
            autoClose: 1000,
            hideProgressBar: true,
        }
        if (type === 'success') {
            toast.success(message, options);
        } else {
            toast.error(message, options);
        }
    },   
}
export default CONFIG;