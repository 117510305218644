import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from "react-router-dom";
import APIService from "../../Services/API";
import CONFIG from "../../Utils";
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../Common/Loader';
import OTP from './otp'
import { AuthService } from '../../auth.service'
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { TRUE } from 'sass';

Amplify.configure({
    // aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    // aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    // aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    Auth: {
        //oauth: oauth,
        //identityPoolId: config.cognito.identityPoolId,
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
        storage: sessionStorage
    },
});

const Login = () => {
    const navigate = useNavigate();
    const auth = new AuthService()
    const [state, dispatch] = useCentralStore();

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            dispatch({
                type: ActionTypes.SET_USER_LOGGED_IN,
                payload: false,
            });
            navigate('/login');
        } catch (error) {
            console.log('Error signing out:', error);
        }
    };

    const redirectUser = (user) => {
        APIService.getUserAccessDetails(user.attributes.email).then((response) => {
            if (response.error) {
                CONFIG.TOASTMESSAGES("error", response.error);
                handleSignOut();
            } else {
                if (response.isApproved !== "Approval Pending") {
                    let details = {
                        "userId": user.attributes.email,
                        "userName": user.username,
                        "roles": response.hasRole
                    }
                    sessionStorage.setItem('userDetails', JSON.stringify(details));
                    sessionStorage.setItem('awsToken', user.username);
                    navigate('/applications');
                } else {
                    CONFIG.TOASTMESSAGES("error", response.isApproved);
                    handleSignOut();
                }
            }
        }).catch((e) => {
            console.log("error==", e);
            handleSignOut();
        });
    }

    const [tabVal, setTabVal] = useState('signin')
    const [email, setEmail] = useState('signin')
    const [emailerror, setEmailerror] = useState()
    const [loginerror, setLoginerror] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [authDetails, setAuthDetails] = useState();

    const validateEmail = (values) => {
        const errors = {}
        if (!values) {
            setEmailerror('Email Address Required')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
            setEmailerror('Invalid Email address')
        } else {
            setEmailerror('')
        }
        return errors
    }

    const handleEmail = (e) => {
        dispatch({
            type: ActionTypes.SET_EMAIL_ADDRESS,
            payload: e.target.value,
        });
        setEmail(e.target.value)
        validateEmail(e.target.value)
        setLoginerror('')
    };

    const handleLogin = () => {
        // navigate("/otp");
        setIsLoading(true)
        auth.signIn(email)
            .then(resp => {
                setTabVal('otp')
                setIsLoading(false)
                console.log('resp auth', resp)
                setAuthDetails(resp)
            })
            .catch(err => {
                setLoginerror('Invalid user.')
                setIsLoading(false)
                handleSignOut()
            })
    }

    const getMasterData = () => {
        setIsLoading(true)
        APIService.getMasterData().then((response) => {
            if (response) {
                setIsLoading(false)
                dispatch({
                    type: ActionTypes.SET_MASTERDATA,
                    payload: response?.GetMasterData,
                });
                sessionStorage.setItem("masterData", JSON.stringify(response?.GetMasterData))
            }
        }).catch((e) => {
            CONFIG.TOASTMESSAGES("error", e);
            console.log("Error - ", e);
        });
    }

    const getUsers = () => {
        setIsLoading(true);
        APIService.getUsersList()
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                    console.log('Login get userslist resp===', response);
                    // setUsersList(response?.GetUsersResponse)
                    let currentUser = response?.GetUsersResponse?.filter(v => v?.EmailId === email)?.[0]
                    dispatch({
                        type: ActionTypes.SET_CURRENT_USER,
                        payload: currentUser,
                    });
                    sessionStorage.setItem('userDetails', JSON.stringify(currentUser));
                    console.log('currentUser login', currentUser);
                    navigate(currentUser?.RoleName?.toUpperCase() !== 'ASURION ADMIN' ? '/applications' : "/");
                }
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    const [_otpErrors, setOtpError] = useState();
    const [incorrectAttempts, setIncorrectAttempts] = useState(0);
    const [consoleMessage, setConsoleMessage] = useState("");

    const verifyOtp = (otp) => {
        setIsLoading(true);
        if (otp.length === 6) {
            //   setOtpError('')
            auth.answerCustomChallenge(authDetails, otp.join(""))
                .then(async (data) => {
                    dispatch({
                        type: ActionTypes.SET_USER_LOGGED_IN,
                        payload: true,
                    });
                    setIsLoading(false);
                    getUsers()
                    getMasterData()
                })
                .catch((err) => {
                    setIsLoading(false);
                    //   setOtpvalue('');
                    setIncorrectAttempts((prevAttempts) => prevAttempts + 1);
                    console.log("error verify otp", err)
                    if (incorrectAttempts >= 0) {
                        setIsLoading(false);
                        setConsoleMessage(false);
                        !consoleMessage && setOtpError(err?.message);
                        // setDisabledResend(false);
                    } else {
                        setIsLoading(false);
                        setConsoleMessage(false);
                        setOtpError('Invalid OTP');
                    }
                })
        }
    };

    return (
        <div className="min-h-screen bg-[#F0F0F5]">
            {/* min-h-screen bg-gray-200 */}
            {isLoading && <Loader />}
            <img src="./images/loginHeaderImg.svg" alt='' className='py-[1%] px-[5%]' />
            <div className='loginBg'>
                <div className='min-h-[400px] py-5 px-8 bg-white border-[1px] rounded-xl m-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] shadow-gray-300 shadow-xl'>
                    {/* <div class="border-b border-gray-200 dark:border-gray-700">
                        <ul class="flex w-full justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                            <li class="me-2">
                                <a href="#" class={`inline-flex items-center justify-center p-4 ${tabVal === 'signin' ? 'text-blue-600 border-b-2 border-blue-600' : ''} rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group`}
                                    onClick={(e) => setTabVal('signin')}>
                                    <svg class="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                    </svg>Sign in
                                </a>
                            </li>
                            <li class="me-2">
                                <a href="#" class={`inline-flex items-center justify-center p-4 ${tabVal === 'createaccount' ? 'text-blue-600 border-b-2 border-blue-600' : ''} rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group`} aria-current="page"
                                    onClick={(e) => setTabVal('createaccount')}>
                                    <svg class="w-4 h-4 me-2 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                        <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                    </svg>Create account
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    {tabVal === 'signin' &&
                        <>
                            {/* <img src="./images/loginHeaderImg.svg" alt='' className='p-2 m-auto mt-5' /> */}
                            <p className='mt-7 text-center font-bold text-2xl'>User login</p>
                            <p className='my-4'>Enter your email to get sign in to your account</p>
                            <div className='flex flex-col'>
                                <p className='my-1 text-left text-sm'>Email<span className='text-red-600'>*</span></p>
                                <input type='email' className='rounded-md' onChange={handleEmail} placeholder='Enter email address' />
                                <button
                                    type="button"
                                    className={`${emailerror !== '' ? 'bg-gray-400 text-black cursor-not-allowed' : 'bg-txtPrimary text-white'} py-3 px-10 rounded-2xl text-sm items-center mt-5 mb-1`}
                                    onClick={() => handleLogin()}
                                >
                                    Sign in
                                </button>
                                {loginerror && <p className="text-sm text-red-600 text-center">{loginerror}</p>}
                                {emailerror && !loginerror && <p className="text-sm text-red-600 text-center">{emailerror}</p>}
                                {/* <p className='text-sm'>Don’t have an account? <a className='text-[#2743CC] cursor-pointer' onClick={() => setTabVal('createaccount')}>Create an account</a></p> */}
                            </div>
                        </>
                    }
                    {tabVal === 'otp' &&
                        <OTP
                            auth={auth}
                            verifyOtp={(otp) => verifyOtp(otp)}
                            _otpErrors={_otpErrors}
                        />
                    }
                </div>
                {/* <Authenticator signUpAttributes={['email']}>
                    {({ signOut, user }) => {                        
                        redirectUser(user);                                               
                        return (
                            <main>
                                <Loader />
                            </main>
                        )
                    }}
                </Authenticator> */}
            </div>
            {/* <div className="flex flex-col items-center justify-center bg-blue-200">
                <img src="./images/asurion-logo.svg" alt='' />
            </div> */}
            {/* <div className="flex flex-col items-center justify-center bg-gray-200">
                <Authenticator signUpAttributes={['email']}>
                    {({ signOut, user }) => {                        
                        redirectUser(user);                                               
                        return (
                            <main>
                                <Loader />
                            </main>
                        )
                    }}
                </Authenticator>
            </div> */}
        </div>
    );
};

export default Login;