import React from "react";

const ModalTemplet = (
  {
  handleClose,
  modalTitle,
  modalBody,
  modalFooter="",
  modalClassName="",
  modalBodyClass="",
  size = "regular",
  modalHeaderClass = "",
}
) => {


  return (
    <div
      id="create-new-project"
      tabIndex="-1"
      aria-hidden="false"
      className="overflow-y-auto overflow-x-hidden fixed top-10 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-black bg-opacity-50 backdrop-blur-sm"
      aria-modal="true"
      role="dialog"
    >
      <div
      //Removed ${sizeClass}
        className={`flex m-auto margin-auto ${modalTitle == "Add API" ? 'w-3/4' : 'w-1/2'}`}
        data-modal-show="true"
      >
        <div className="bg-uploadform mt-10 drop-shadow-md  bg-white rounded-[30px] w-full">
          <div className={modalClassName}>
            <div className={`flex items-center   ${modalHeaderClass}`}>
              {modalTitle}
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
                onClick={handleClose}
              >
                <img src="/images/close_icon.svg"></img>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className={modalBodyClass}>
            {modalBody}
            {modalFooter}
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTemplet;
