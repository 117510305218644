import Select from 'react-select'

const FilterOptions = ({ closeFilter, setTagOptions, setSortngvalues, setEnrolmentsValues, selectedTags, selectedEnrolmentsValues, selectedSortingvalues, setApplyFilter, _tagsListObj }) => {
    // const tagOptions = [
    //     {label: 'Enrolments', value: 'Enrolments'},
    //     {label: 'Service Requests', value: 'Service Requests'},
    //     {label: 'Payments', value: 'Payments'},
    //     {label: 'Integrations', value: 'Integrations'}
    // ]

    const enrolmentOptions = []

    const sortingOptions = [
        { label: 'Most Rated', value: 'Most Rated'},
        { label: 'All APIs', value: 'All APIs'},
        { label: 'APIs by Me', value: 'APIs by Me'},
        { label: 'Recently Added', value: 'Recently Added'},
        { label: 'Saved APIs', value: 'Saved APIs'}
    ]

    return(
        <div className="absolute right-[20%] 2xl:right-[14%] bg-white border border-txtPrimary p-3 rounded-md z-40 w-[45%]">
            <p className="font-bold mb-3 text-sm">Filter by</p>
            {/* <div className="flex flex-row justify-between mb-5 w-full"> */}
                {/* <div className='w-[45%]'>
                    <p className="mb-1 text-sm font-medium text-gray-900 dark:text-white">Enrolments</p>
                    <Select
                        className='text-xs'
                        value={selectedEnrolmentsValues}
                        options={enrolmentOptions}
                        onChange={(e) => (setEnrolmentsValues(e), setApplyFilter(false))}
                    />
                </div> */}
                <div className=''>
                    {/* <p className="mb-1 text-sm font-medium text-gray-900 dark:text-white">Sorting</p> */}
                    <Select
                        className='text-xs'
                        options={sortingOptions}
                        value={selectedSortingvalues}
                        onChange={(e) => (setSortngvalues(e), setApplyFilter(false), setTagOptions(null))}
                    />
                </div>
            {/* </div> */}
            <div className="my-5">
                <p className="font-bold mb-1 text-sm text-gray-900 dark:text-white">Filter by tag(s)</p>
                <Select
                    className='text-xs'
                    // isMulti
                    options={_tagsListObj}
                    value={selectedTags}
                    onChange={(e) => (setTagOptions(e), setApplyFilter(false), setSortngvalues(null))}
                />
            </div>
            <div className="flex flex-row justify-between items-center">
                <button className="text-xs underline cursor-pointer font-bold" onClick={() => (setTagOptions(null), setSortngvalues(null), setEnrolmentsValues(null), setApplyFilter(false))}>Reset all filters</button>
                <div className="flex flex-row">
                    <button className="border border-txtPrimary text-txtPrimary rounded-full ml-5 py-2 px-4 h-fit text-xs" onClick={() => closeFilter(false)}>
                        Cancel
                    </button>
                    <button onClick={() => setApplyFilter(true)}
                        className={`${(selectedTags !== null || selectedEnrolmentsValues !== null || selectedSortingvalues !== null) ? 'bg-txtPrimary' : 'bg-lightPurple cursor-not-allowed'} text-white rounded-full ml-5 py-2 px-4 h-fit text-xs`}>
                        Apply Filters
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FilterOptions