import React, { useEffect, useState } from 'react'

const calculateDisplay = (timer) => {
    const dt = new Date();
    if (timer <= dt) {
        return '00:00'
    }
    let seconds = Math.round(timer.getSeconds() - dt.getSeconds())
    let minutes = Math.round(timer.getMinutes() - dt.getMinutes())
    if (seconds < 0) {
        seconds = 60 + seconds;
        minutes = minutes - 1;
    }
    if (minutes < 0){
        minutes = 60 + minutes
    }
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const Timer = ({ state ,parentCallback}) => {
    const [display, setDisplay] = useState(calculateDisplay(state.Timer))
    useEffect(() => {
        if (state.Timer >= new Date()) {
        const timer = setInterval(() => {
            setDisplay(calculateDisplay(state.Timer))
            parentCallback(calculateDisplay(state.Timer));
        }, 1000)
        return () => clearInterval(timer)
        }
    }, [state.Timer, display])
    return <span className={`inline-block text-app-starhubGreen font-bold  ${display === '00:00' ? 'text-error' : ''}`}>{display}</span>
}

export default Timer
