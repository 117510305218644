import React from 'react'

const ForumCardDetail = (props) => {   
   // console.log("props==", props, "  ",rops?.postDetails?.userFlag);
    return (
        <div className='border border-txtSecondary bg-txtSecondary rounded-[10px] cursor-pointer'>
                <div className='p-6 flex relative'>                    
                        <img src='/images/forumicon.svg'></img>
                    <div className='flex flex-col px-5'>
                        <h1 className='text-[#181F48] text-lg font-apimp-semibold'>
                            {props?.postDetails?.discussionName}
                        </h1>

                        <div className='flex flex-row pt-2'>
                            {props?.postDetails?.discussionTags && props?.postDetails?.discussionTags.length > 0 && props?.postDetails?.discussionTags.map((data, index) => (
                                <span 
                                    key={data}
                                    className="bg-[#316AFF] bg-opacity-20 text-[#316AFF] text-[10px] font-apimp-semibold inline-flex items-center px-2.5 py-1 rounded-[10px] mr-2"
                                >
                                    {data}
                                </span>
                            ))}    
                        </div>

                        <div className='flex pt-4'>
                            <img src='/images/avatar.svg'></img>
                            <div className='flex-row px-2'>
                                <h4 className='text-[#181F48] font-apimp-semibold text-sm flex'>
                                    {props?.postDetails?.userName} - {props?.postDetails?.userFlag}
                                    <img className='px-1' src='/images/blackdot.svg'></img>
                                </h4>
                                <span className='text-[10px] font-apimp-regular'>
                                    {props?.postDetails?.lastUpdatedDate}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row gap-4 absolute right-6'>
                    <button className='bg-txtPrimary text-white rounded-[10px] text-xs p-2.5 font-apimp-semibold'>Ongoing Discussion</button>
                        <img 
                            src={`/images/${props?.postDetails?.userFlag ? 'like_filled.svg' : 'like.svg' }`}
                        ></img>
                        
                    </div>
                    <div className='absolute bottom-6 right-6 text-[#181F48] text-sm font-apimp-regular'>
                        <span className='pr-6'>{props?.postDetails?.views} Views</span>
                        <span className='pr-6'>{props?.postDetails?.likes} Likes</span>
                        <span className='text-txtPrimary underline font-apimp-semibold'>
                            {props?.postDetails?.numberOfComments} comments
                        </span>
                    </div>
                </div>
        </div>
    )
}

export default ForumCardDetail