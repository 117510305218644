import React, { useEffect } from "react";
import RoutesPage from "../../Routes";
import { Link } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
    aws_cognito_region: 'ap-northeast-1', // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: 'ap-northeast-1_19xbHitIF', // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: '770h6fe4v1ljgs6duh5sfd43lb', // (optional) - Amazon Cognito App Client ID (App 
    aws_mandatory_sign_in: 'enable' // (optional) - Users are not allowed to get the aws credentials unless they are signed in
  });

  

const NotAuthorized = () => {   

    const handleAwsLogin = async () => {
        console.log("in login");
        return (
            <Authenticator signUpAttributes={['email']}>
                {({ signOut, user }) => (
                    <div className="app-container w-full min-h-[100vh]">                        
                            <RoutesPage /> 
                    </div>
                )}
            </Authenticator>
        )
    }
    useEffect(() => {            
    }, [])

    return (
        <div className="grid grid-cols-2 h-screen">
            <div className="flex flex-col items-center justify-center bg-blue-200">
                <Link
                    to="https://dummy-url-auth/authenticate?post-authentication-binding=http://dummy-url"
                >
                    <button className="bg-blue-500 text-white py-2 px-4 rounded">SSO Login</button>
                </Link>
            </div>
            <div className="flex flex-col items-center justify-center bg-gray-200">
                <Authenticator signUpAttributes={['email']}>
                    <button
                        className="bg-gray-500 text-white py-2 px-4 rounded"
                        onClick={() =>handleAwsLogin()}
                    >
                        External User Login
                    </button>
                </Authenticator>
                {/* <button 
                    className="bg-gray-500 text-white py-2 px-4 rounded"
                    onClick={toSignIn}
                >
                    External User Login
                </button> */}
            </div>
        </div>


    )
}

export default NotAuthorized