import React from 'react'

const APIUsedCards = (props) => {    
  
    return (
        <div className="mt-5 border border-[#181F48] border-opacity-20 rounded-2xl px-4 py-4 flex relative cursor-pointer shadow-gray-300 shadow-xl"
            onClick={(event) => props.handleAppApi(event, props?.Key)}
        >
            {/* <img src="/images/api_img.svg" alt="api_img" /> */}
            <div
                className="grid place-items-center text-center bg-txtSecondary w-[80px] h-[80px] rounded-[20px]"
            >
                <img
                    className={`${props?.data?.iconUrl === null
                        ? "mx-auto my-[0.5px] text-center"
                        : "mx-auto my-[0.5px] text-center w-[65px] h-[65px] rounded-[35px]"
                        }`}
                    src="/images/new_api_img.svg"
                    alt="api_img"
                />
            </div>
            <div className="flex flex-col pl-4">
                <h1 className="text-lg font-apimp-semibold text-txtPrimary line-clamp-1" title={props?.Name}>{props?.Name} </h1>
                <span className="px-3 py-1 mr-32 mt-1 text-center font-apimp-medium text-[10px] bg-txtSecondary text-txtPrimary rounded-md">
                    {props?.Category}
                </span>
                <div className="flex pt-2">
                    <a className="inline-flex items-center text-xs font-apimp-medium text-center text-black">
                        {props?.version}
                    </a>
                    <span 
                        className="inline-block text-[10px] font-apimp-regular items-center px-3 py-0.5 rounded-full bg-white text-black border border-[#EBF1FF] truncate text-ellipsis"
                        title={props?.CreatedBy}
                    >
                        by {props?.CreatedBy?.split('@')?.[0]}
                    </span>
                </div>
            </div>
                 
            {(props?.currentUser?.RoleName?.toUpperCase() === "ASURION ADMIN" || props?.currentUser?.RoleName?.toUpperCase() === "ASURION USER" ) &&
                <div className="absolute right-5 new-test-check">
                    <button className='new-test-check'
                        onClick={(event) => props.handleApiConfirmation(event, props)}
                    > <img src="/images/delete.svg" className='new-test-check' title="delete" alt="delete" /> </button>
                </div>
            }
            
            <div className="absolute right-4 bottom-[1.5rem]">
                <label className="relative inline-flex items-center cursor-pointer new-test-check">
                    <input 
                        type="checkbox" 
                        className="sr-only peer new-test-check" 
                        defaultChecked={props.enabledBaseUrlForGivenApp}
                        onChange={(e) => props.handleInActive(e, e.target.checked, props?.Key)}                        
                    />
                    {/* <div 
                        className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-gray-700 rounded-full peer dark:bg-[#181F48] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#181F48] peer-checked:bg-[#181F48] new-test-check"
                    ></div> */}
                </label>
            </div>
        </div>
    )
}

export default APIUsedCards