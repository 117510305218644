import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";

const ApplicationCard = (props) => {
  const [state, dispatch] = useCentralStore();

  const navigate = useNavigate();
  const [showDelete, setshowDelete] = useState(false);
  const showDeleteButton = () => {
    props.role === "Admin" ? setshowDelete(true) : setshowDelete(false);
  };
  const hideDeleteButton = () => {
    setshowDelete(false);
  };
  const handleDetails = (event, id) => {
    event.preventDefault();
    dispatch({
          type: ActionTypes.SET_SELECTED_APPLICATION_DATA,
          payload: props.apiData,
      });
    navigate(`/application-details/${id}`);
  };

  return (
    <div
      className="grid grid-col-3 border border-gray-300 rounded-2xl p-4 cursor-pointer shadow-gray-300 shadow-xl"
      onClick={(event) => handleDetails(event, props.apiData?.ApplicationId)}
      // onMouseOver={() => showDeleteButton()}
      // onMouseOut={() => hideDeleteButton()}
    >
      <div className="border-b-2 relative pb-6 w-full flex">
        <div
          className="grid place-items-center text-center bg-txtPrimary w-[55px] h-[55px] rounded-[30px]"
        >
        {/* <img
          className={`${props?.apiData?.appIconUrl === null
            ? "mx-auto my-[0.5px] text-center"
            : "mx-auto my-[0.5px] text-center w-[40px] h-[40px] rounded-[30px]"
          }`}
        src={`${props?.apiData?.appIconUrl === null
            ? "images/applcation_card_logo.svg"
            : props?.apiData?.appIconUrl
          }`}
          alt="applcation_card_logo"
        /> */}
        <img
          className={`${props?.apiData?.appIconUrl === null
            ? "mx-auto my-[0.5px] text-center"
            : "mx-auto my-[0.5px] text-center w-[40px] h-[40px] rounded-[30px]"
          }`}
          src={`images/applcation_card_logo.svg`}
          alt="applcation_card_logo"
        />
        </div>
        <h1 className="pl-3 font-apimp-regular text-base text-[#181F48] ">
          {props.apiData?.ApplicationName}
        </h1>
        {/* {showDelete ? ( */}
          {(props?.currentUser?.RoleName?.toUpperCase() === "ASURION ADMIN" || props?.currentUser?.RoleName?.toUpperCase() === "ASURION USER" ) &&
            <img
              className="inline-block absolute right-1 top-1 cursor-pointer"
              src="images/delete.svg"
              alt="delete"
              onClick={(event) => props.handleConfirmation(event, props.apiData?.ApplicationId)}
            />
          }
        {/* ) : (
          ""
        )} */}
      </div>
      <div className="pt-3 flex justify-end items-center">
        {/* <div className="flex">
          <h1
            className="text-txtPrimary font-apimp-bold"
          >
            {props?.apiData?.ApiCount}
          </h1>
          <h1 className="pl-1 text-base text-black font-apimp-light opacity-40">
            APIs used
          </h1>
        </div> */}
        <div className="flex relative right-0 text-[10px] text-black font-apimp-light opacity-40">
          <h1>Created on</h1>
          <h1 className="pl-1">{moment(props?.apiData?.CreatedDate)?.format('YYYY-DD-MM')}</h1>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;