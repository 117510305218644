import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Popover } from 'react-tiny-popover';
import SockJsClient from 'react-stomp';
import { useCentralStore } from "../../store/appContext";

const Header = (props) => {
  const [state, dispatch] = useCentralStore();
  const userData = state?.currentUser //JSON.parse(sessionStorage.getItem('userDetails'));
  const [activeTab, setActiveTab] = useState(userData?.RoleName?.toUpperCase() === 'ASURION ADMIN' ? 'API Directory' : "Applications");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const navigate = useNavigate();
  
  const getTitle = (txtData) => {
    if (txtData) {
      let dataArry = txtData?.userName?.split('@');
      if(dataArry?.length > 1){
          return `${dataArry} ${dataArry[1]} (${txtData?.RoleName})` 
      } else {
          return `${dataArry} (${txtData?.RoleName})`
      }       
    } else {
      return 'NA';
    }
  };
  
  const formatUserData = () => {
    if (userData) {
      let userDetails = userData?.FirstName + '('+(((userData?.RoleName?.toUpperCase() === 'ASURION ADMIN' || userData?.RoleName?.toUpperCase() === 'ASURION USER') ? '' : userData?.Status+'-') + userData?.RoleName)+')'
      return userDetails;
    } else {
      return 'NA';
    }
  };

  const handleClickNotification = (event) => {
    setCount(0);
  };

  const onConnected = (msg) => {
    console.log('notification Connected===');
  };

  const onMessageReceive = (msg, topic) => {
    let countInc = count + 1;
    let arr = notifications ? notifications : [];
    arr = arr.concat(msg);
    setCount(countInc);
    setNotifications(arr);
  };

  const deleteCookies = () => {
    const cookies = document.cookie.split(";");  
    cookies.forEach((cookie) => {
      const [name] = cookie.trim().split("=");
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
  };
  

  const signOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    deleteCookies();
    navigate('/login');
}  

  const URL = `${process.env.REACT_APP_BASE_URL}notifications`;
  const TOPIC_URL = ['/topic/notification'];  
   
  return (
    <nav className="header bg-[#F0F0F5]">
      {/* <SockJsClient
        url={URL}
        topics={TOPIC_URL}
        onMessage={(msg) => {
          onMessageReceive(msg);
        }}
        onConnect={(msg) => {
          onConnected(msg);
        }}
        debug={false}
      /> */}
      <div className="flex justify-between">
        <div className="grid grid-flow-col">
          <div className="flex flex-col  pl-10 pr-[88px] pt-[17px] pb-[18px]">
            {/* <span className="text-white "> */}
            <img src="./images/loginHeaderImg.svg" alt='' className='w-[80%]'/>
              {/* <img
                src="/images/asurion-logo.svg"
                alt="asurion-logo"
                className="pb-1"
              /> */}
            {/* </span> */}
            {/* <span className="text-white font-apimpSpline-medium font-normal text-[14px] leading-[16.59px]">
              API MarketPlace 
            </span> */}
          </div>

          <ul className="flex flex-row my-auto flex-shrink-0 text-white pl-6">
            <li className={`pl-10 ${userData?.RoleName?.toUpperCase() !== "ASURION ADMIN" ? 'hidden' : ''}`}>
              <Link
                className={`${
                  activeTab === "API Directory"
                    ? "border-black font-apimp-bold font-[600px] border-b-4 text-[#181F48] pb-[10px] "
                    : "text-[#181F48] font-apimp-light font-[400px]  text-base  hover:stroke-txtPrimary hover:border-black pb-[10px] hover:border-b-4 hover:text-[#222831]  px-1"
                }
                `}
                to="/"
                onClick={() => setActiveTab("API Directory")}
              >
                API Directory
              </Link>
            </li>

            <li className="pl-10">
              <Link
                className={`${
                  activeTab === "Applications"
                    ? "border-black font-apimp-bold font-[600px] border-b-4 text-[#181F48] pb-[10px] "
                    : "text-[#181F48] font-apimp-light font-[400px]  text-base hover:border-black pb-[10px] hover:border-b-4 hover:text-[#222831]  px-1"
                }`}
                to="/applications"
                onClick={() => setActiveTab("Applications")}
              >
                Applications
              </Link>
            </li>
            {/* <li className="pl-10">
              <Link
                className={`${
                  activeTab === "Forum"
                    ? "border-black font-apimp-bold font-[600px] border-b-4 text-[#181F48] pb-[10px] "
                    : "text-[#181F48] font-apimp-light font-[400px]  text-base  hover:stroke-txtPrimary hover:border-black pb-[10px] hover:border-b-4 hover:text-[#222831] px-1"
                }`}
                to="/forum"
                onClick={() => setActiveTab("Forum")}
              >
                Forum
              </Link>
            </li> */}
            <li className={`pl-10 ${(userData?.RoleName?.toUpperCase() !== "ASURION ADMIN" && userData?.RoleName?.toUpperCase() !== "PARTNER ADMIN") ? 'hidden' : ''}`}>
              <Link
                className={`${
                  activeTab === "Users"
                    ? "border-black font-apimp-bold font-[600px] border-b-4 text-[#181F48] pb-[26px] "
                    : "text-[#181F48] font-apimp-light font-[400px]  text-base  hover:stroke-txtPrimary hover:border-black pb-[26px] hover:border-b-4 hover:text-[#222831] px-1"
                }`}
                to="/users"
                onClick={() => setActiveTab("Users")}
              >
                Users
              </Link>
            </li>
          </ul>
        </div>

        <div className="grid grid-flow-col pr-10">
          {userData?.RoleName?.toUpperCase() === "ASURION ADMIN" &&
          <div className="flex my-auto pr-[23px]">
            <Popover
              isOpen={isPopoverOpen}
              positions={['bottom']} // preferred positions by priority
              onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
              content={<div className="notification-list-inner">
                <div className="heading">Notifications</div>
                <img
                  src="/images/cancel_notification.svg"
                  className="cancelIcon"
                  alt="notifications"
                  onClick={() => setIsPopoverOpen(false)}
                />
                <ul>
                  {notifications &&
                    notifications.map((data, index) => (
                      <li key={data.message}>                        
                        <div className="messageWrapper grid grid-cols-12">
                          <div className="col-span-2">
                            <img
                              src="/images/notification_message.svg"
                              alt="notifications"
                            />
                          </div>

                          <div className="col-span-10">
                            <div className="content">{data.message}</div>                            
                          </div>                          
                        </div>
                      </li>
                    ))}
                  {notifications && notifications.length === 0 && (                   
                    <li>
                        <div className="messageWrapper grid grid-cols-12">
                          <div className="col-span-2">
                            <img
                              src="/images/notification_message.svg"
                              alt="notifications"
                            />
                          </div>

                          <div className="col-span-8">
                            <div className="content">No data found</div>
                          </div>                          
                        </div>
                    </li>
                  )}
                </ul>
              </div>}
            >
              <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                {/* <button type="button" className="relative inline-flex items-center text-sm font-medium text-center text-white rounded-lg">
                  <img
                    src="/images/notification_bell.svg"
                    alt="notifications"
                  />

                  <span className="sr-only">Notifications</span>
                  {notifications && notifications.length > 0 &&
                  <div className="absolute inline-flex items-center justify-center top-[0rem] right-[0rem]"
                    onClick={handleClickNotification}
                  >
                    <img
                      src="/images/notification_color.svg"
                      alt="notifications"
                    />                    
                  </div>
                }
                </button> */}
              </div>
            </Popover>
          </div>
          }
          <div 
            className="flex flex-col text-right text-white my-auto gap-0"
            title={getTitle(userData)}
          >
            <span className="text-base font-medium font-apimp-medium text-[#181F48] pr-[10px] hover:bg-transparent cursor-pointer">
            {userData !== undefined ? formatUserData() : 'NA'}
            </span>
          </div>
          <div className="my-auto px-[3px] py-[3px] rounded-full border-opacity-30">
            <img src="/images/profile_image.svg" alt="profile_image" />
          </div>
          <div className="my-auto px-[3px] py-[3px] arrow-wrapper">
          <Popover
            isOpen={isLogoutOpen}
            positions={['bottom']} // preferred positions by priority
            onClickOutside={() => setIsLogoutOpen(false)} // handle click events outside of the popover/target here!
            content={<div className="notification-list-inner logoutPoperWrapper">
              <ul>
              <li>
                  <div className="mt-[12px] text-[12px] font-oi-regular cursor-pointer text-txtPrimary">
                    <button onClick={signOut}>Sign out</button>
                  </div>
                </li>          
          </ul>
            </div>}
          >  
          <img
                src="/images/down_arrow.svg" 
                alt=""
                className={`${(!isLogoutOpen)?"downArrow":"upArrow"} `}
                onClick={() => setIsLogoutOpen(!isLogoutOpen)}
            />
            </Popover>
            </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
