import React from 'react';
import { Rating } from 'react-simple-star-rating';

const APICardDetails = (props) => {       

    return (
        <div className='w-full bg-txtSecondary flex px-10 pb-5 rounded-2xl relative'>
            <div
                className="my-10 grid place-items-center text-center bg-txtPrimary w-[190px] rounded-[20px]"
            >
                <img
                    className="mx-auto my-[0.5px] text-center"
                    src="/images/new_api_img.svg"
                    alt="api_img"
                />
            </div>
            <div className="flex flex-col pl-8">
                <h1 className="font-apimp-semibold text-lg text-txtPrimary pt-10">{props.name}</h1>

                <div className="flex mt-2 space-x-2 items-center">
                    <span className="inline-block py-1 px-2.5 text-center font-apimp-medium text-[10px] bg-txtSecondary text-txtPrimary rounded-md">
                    {props.category}
                    </span>
                    <a className="inline-flex items-center text-[10px] font-apimp-medium text-center text-black">
                        {props.version}
                    </a>
                    <span className="ml-4 text-[10px] font-apimp-regular inline-flex items-center px-3 py-0.5 rounded-full bg-white text-black border border-[#EBF1FF]">
                        By Admin
                    </span>
                </div>

                <div className="flex mt-4 items-center">
                    {props.ratingsCount !== 0 && 
                    <>
                    <span className="icon-style flex justify-center mx-1 text-xs font-apimp-regular">
                        {props.ratings}
                    </span>
                    <img src='/images/star.svg' alt='star'></img>
                       
                    <img className='px-2' src='/images/line_black.svg' alt='line_black'></img>
                    </>
                    }     
                    <a className="inline-flex font-apimp-light items-center w-full text-xs text-center text-black">
                        {props.ratingsCount} Ratings
                    </a>
                    <span className='flex justify-center ml-[5px]'>
                     {props.ratingFlag &&  
                    <div className='star-app'>
                    <Rating
                        onClick={props.handleRatings}
                    />
                        </div>
                     } 
                    </span>
                    
                </div>                
                <p 
                    className="text-sm font-apimp-regular text-black text-opacity-50 pt-6 line-clamp-1"
                    title={props.description}
                >
                    {props.description}
                </p>
            </div>
            <div className="pt-10 right-10 absolute">
                <button><img src="/images/bookmarkwhitebg.svg" alt="bookmarkwhitebg" /></button>
            </div>
        </div>

    )
}

export default APICardDetails