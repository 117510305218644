import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import APIService from "../../Services/API";
import CONFIG from '../../Utils';
import ResponseModal from './ResponseModal';
import UtilityService from '../../Services/Utility';
import Loader from '../Common/Loader';

const ApplicationApi = (props) => {
    const [allData, setAllData] = useState(null);
    const [activeMethodsCount, setActiveMethodsCount] = useState(0);
    const [responseData, setResponseData] = useState(null);
    const [responseType, setResponseType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const dragItem = useRef();
    const dragOverItem = useRef();
    const params = useParams();
    const userObj = JSON.parse(sessionStorage.getItem("userDetails"));
    const [swaggerMethods, setSwaggerMethods] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleSwaggerMethods = (txtChecked, indexVal, type, txtPath) => {
        handleSelectedPaths(txtChecked, txtPath);
        let obj1 = Object.assign([], swaggerMethods);
        obj1[type][indexVal].selected = txtChecked;
        let cnt = getActiveMethods(obj1);
        setSwaggerMethods(obj1);
        setActiveMethodsCount(cnt);
    }

    const handleSelectedMethods = (txtData) => {
        let aryData = Object.assign([], txtData);
        Object.keys(aryData).length > 0 && Object.keys(aryData).map((data, index) => {
            return aryData[data].map((elem, ind) => {
                // if (props.apiData.selectedUrl && props.apiData.selectedUrl.includes(elem.pathString)) {
                //     elem.selected = true;
                // }
                return elem;
            });
        });
        setSwaggerMethods(aryData);
        // setActiveMethodsCount(props.apiData.selectedUrl !== null ? props.apiData.selectedUrl.length : 0);
    };

    const handleSelectedPaths = (txtChecked, txtValue) => {
        let pathAry = JSON.parse(JSON.stringify(selectedPaths));
        const index = pathAry.indexOf(txtValue);
        if (txtChecked) {
            pathAry.push(txtValue);
        } else {
            if (index > -1) {
                pathAry.splice(index, 1);
            }
        }
        setSelectedPaths(pathAry);
    }

    const getActiveMethods = (txtData) => {
        let cnt = 0;
        txtData && Object.keys(txtData).length > 0 && Object.keys(txtData).map((data, index) => {
            return txtData[data].map((elem, ind) => {
                if (elem.selected) {
                    cnt++
                }
                return elem;
            });
        });
        return cnt;
    };

    const handleAllParameters = (val, index, indexVal, type, isDefinition) => {
        let obj1 = Object.assign([], swaggerMethods);
        if (isDefinition !== "") {
            obj1[type][index].definitions[indexVal].value = val;
        } else {
            obj1[type][index].parameters[indexVal].value = val;
        }
        setSwaggerMethods(obj1);
    }

    const handleXAmazonParameters = (val, index, propertyName, paramName, txtPath, childIndex) => {
        let obj1 = Object.assign([], allData);
        let documentationData = allData["x-amazon-apigateway-documentation"].documentationParts

        let IndexValue = documentationData.findIndex(data => (data?.location?.path === txtPath && (data.location.type === "REQUEST_BODY" || data.location.type === "REQUEST_QUERY" || data.location.type === "REQUEST_HEADER" || data.location.type === "REQUEST_PATH")));
        if(childIndex === ""){
            obj1["x-amazon-apigateway-documentation"].documentationParts[IndexValue].parameters[index].value = val;
        } else {
            let childObj = {
                [paramName]: val
            }
            obj1["x-amazon-apigateway-documentation"].documentationParts[IndexValue].parameters[index].value = childObj;
        }
        setAllData(obj1);
    }

    const dragStart = (e, position, type) => {
        dragItem.current = position;
        let elemetSelected = document.getElementById('drag' + position);
        elemetSelected.classList.add('selected-div');
    };

    const dragEnter = (e, position, type) => {
        dragOverItem.current = position;
    };

    const drop = (e, type) => {
        let txtCnt = getCountOfFilledData();
        if (txtCnt > 0) {
            CONFIG.TOASTMESSAGES("error", "Drag and Drop the rows before entering values");
            return false;
        } else {
            let mainArr = Object.assign([],swaggerMethods);
            let temp = mainArr[type][e.target.id.split('drag')[1]]
            mainArr[type][mainArr?.get?.findIndex(ele => ele?.pathString == temp?.pathString)] = mainArr[type][dragOverItem?.current]
            mainArr[type][dragOverItem?.current] = temp;
            setSwaggerMethods(mainArr);
        }
    };

    const getCountOfFilledData = () => {
        let cnt = 0;
        Object.values(swaggerMethods).forEach(methods => {
            methods.forEach(elem => {
                if (elem.selected) {
                    if (!elem?.definitions && elem?.parameters?.length > 0) {
                        cnt += elem.parameters.filter(param => param.value).length;
                    } else if (elem?.definitions?.length > 0) {
                        cnt += elem.definitions.filter(definition => definition.value).length;
                    }
                }
            });
        });
        return cnt;
    };

    const getSecurityData = (methodAry) => {
        if (methodAry?.security?.length > 0) {
            const allKeys = methodAry?.security.reduce((keysArray, obj) => {
                const keys = Object.keys(obj);
                return [...keysArray, ...keys];
            }, []);
            return allKeys;
        } else {
            return [];
        }
    }

    const getSecurityDefinitions = () => {
        const { securityDefinitions } = props.apiDocs;

        if (securityDefinitions) {
            const aryKeys = Object.keys(securityDefinitions);

            if (aryKeys.length > 0) {
                return aryKeys.reduce((customHeaders, item) => {
                    const objVal = {
                        "type": securityDefinitions[item].type,
                        "name": securityDefinitions[item].name,
                        "in": securityDefinitions[item].in,
                        "x-amazon-apigateway-authtype": securityDefinitions[item]['x-amazon-apigateway-authtype'],
                    };
                    return { ...customHeaders, [item]: objVal };
                }, {});
            }
        }

        return "";
    };

    const getDetailsOfSelected = (data) => {
        const filteredData = {};
        Object.keys(data).forEach(method => {
            filteredData[method] = data[method].filter(item => item.selected === true);
        });
        return filteredData;
    }

    const handleSyncAsync = (type) => {
        if (activeMethodsCount === 0) {
            return false;
        }

        // setShowModal(true);
        const aryData = Object.assign([], getDetailsOfSelected(swaggerMethods));
        const payloadAry = buildPayloadArray(aryData);
        const payloadObj = {
            "ExecuteApiRequest": {
                "key": parseInt(params.apiid), //13
                "credPath": "apiCredentialFiles",
                "apiRequestPayloads": payloadAry,
                "securityDefinitions": getSecurityDefinitions()
            }
        };
        let checkObject = JSON.parse(payloadAry[0]?.body)
        let _checkObject = Object.values(checkObject)
        let _checkForEmptyObj = Object.values(_checkObject[0])?.every(c => c === null || c === '' || c === "")

        // if (_checkForEmptyObj) {
        //     alert('Please provide parameters.')
        // } else {
            const requestFunction = type === "Synchronous" ? APIService.synchronousApiRequest : APIService.asyncApiRequest;
            setIsLoading(true)
            requestFunction(payloadObj)
                .then((response) => {
                    if (!response.error) {
                        setShowModal(true);
                        setResponseType(type);
                        setResponseData(response);
                        setIsLoading(false)
                    } else {
                        setShowModal(false);
                        CONFIG.TOASTMESSAGES("error", response.error);
                        console.log("Error - ", response.error);
                        setIsLoading(false)
                    }
                })
                .catch((e) => {
                    setShowModal(false);
                    CONFIG.TOASTMESSAGES("error", "Internal Server error");
                    console.log("Error - ", e);
                    setIsLoading(false)
                });
        // }
    };

    const buildPayloadArray = (aryData) => {
        const payloadAry = [];

        Object.keys(aryData).forEach((data) => {
            aryData[data].forEach((item) => {
                const payload = buildPayload(item, data);
                payloadAry.push(payload);
            });
        });
        return payloadAry;
    };

    const buildPayload = (item, data) => {
        let payload = {
            "httpMethod": data.toUpperCase(),
            "hostName": `https://${allData.host}${allData?.basePath!== undefined ? allData.basePath : ''}`,
            "serviceName": item.summary ? item.summary : "",
            "path": item.pathString,
            "headers": {
                "Content-Type": "application/json"
            },
            "baseUrlId": parseInt(params.apiid), //13,
            //   "envName": props.apiData.envName,
            "pathParameters": {},
            "queryParameters": {},
            "security": getSecurityData(item)
        };

        if (item.parameters?.length > 0) {
            handleParameters(payload, item.parameters);
        }
        if (Object.values(item)[0]?.definitions && Object.values(item)[0].definitions.length > 0) {
            handleDefinitions(payload, item.definitions);
        }
        if (Object.keys(allData).includes("x-amazon-apigateway-documentation")) {
            handleGatewayDocs(payload, item.pathString);
        }
        return payload;
    };

    const handleGatewayDocs = (payload, txtPath) => {
        let obj1 = Object.assign([], allData);
        let documentationData = allData["x-amazon-apigateway-documentation"].documentationParts;
        let IndexValue = documentationData.findIndex(data => (data?.location?.path === txtPath && (data.location.type === "REQUEST_BODY" || data.location.type === "REQUEST_QUERY" || data.location.type === "REQUEST_HEADER" || data.location.type === "REQUEST_PATH")));

        if (documentationData[IndexValue]?.parameters) {
            payload.body = { };
            let key  = Object.keys(allData['definitions'])[0]
            let placeHolderArr = allData["definitions"][key].properties[key].properties
            for (const param of documentationData[IndexValue]?.parameters) {
                switch (param.in) {
                    case "REQUEST_PATH":
                        payload.pathParameters[param.name.toString()] = param.type === "string" ? JSON.stringify(param.value) : param.value;
                        break;
                    case "REQUEST_QUERY":
                        payload.queryParameters[param.name.toString()] = param.type === "string" ? JSON.stringify(param.value) : param.value;
                        break;
                    case "REQUEST_HEADER":
                        payload.headers[param.name.toString()] = param.type === "string" ? JSON.stringify(param.value) : param.value;
                        break;
                    case "REQUEST_BODY":
                        payload.body[param.name.toString()] = param.type === "number" ? parseInt(param.value || placeHolderArr[param.name]?.example) : param.value || placeHolderArr[param.name]?.example;
                        break;
                    default:
                        break;
                }
            }
            let testObj = documentationData[IndexValue]?.parameters[0].key.toString();

            if (testObj) {
                payload.body = JSON.stringify({ [testObj]: payload.body });
            } else {
                payload.body = JSON.stringify(payload.body);
            }
        }

    }

    const handleParameters = (payload, parameters) => {
        payload.body = {};

        for (const param of parameters) {
            switch (param.in) {
                case "path":
                    payload.pathParameters[param.name.toString()] = param.value;
                    break;
                case "query":
                    payload.queryParameters[param.name.toString()] = param.value;
                    break;
                case "header":
                    payload.headers[param.name.toString()] = param.value;
                    break;
                case "body":
                    payload.body[param.name.toString()] = param.value;
                    break;
                default:
                    break;
            }
        }

        // payload.body = JSON.stringify(payload.body);
    };

    const handleDefinitions = (payload, definitions) => {
        payload.body = {};

        for (const definition of definitions) {
            switch (payload.parameters[0].in) {
                case "path":
                    payload.pathParameters[definition.name.toString()] = definition.value;
                    break;
                case "query":
                    payload.queryParameters[definition.name.toString()] = definition.value;
                    break;
                case "header":
                    payload.headers[definition.name.toString()] = definition.value;
                    break;
                case "body":
                    payload.body[definition.name.toString()] = definition.value;
                    break;
                default:
                    break;
            }
        }

    };


    const handleClose = () => {
        setShowModal(false);
    }

    const handlePreference = () => {
        let payload = {
            "appId": parseInt(params.appid),
            "baseUrlId": parseInt(params.apiid),
            "userId": userObj.userId,
            "listOfSelectedURL": selectedPaths
        }

        APIService.selectedUrl(payload)
            .then((response) => {
                if (response) {
                    CONFIG.TOASTMESSAGES("success", response);
                }
            })
            .catch((e) => {
                CONFIG.TOASTMESSAGES("error", "Internal Server error");
                console.log("Error - ", e);
            });
    }


    const getRequestBody = (txtPath, reqType) => {
        let details = [];
        const sampleObject = {NA: ''};
        let key  = Object.keys(allData['definitions'])[0]
        let placeHolderArr = allData["definitions"][key].properties[key].properties

        if(Object.keys(allData).includes("x-amazon-apigateway-documentation")){
            let documentationData = allData["x-amazon-apigateway-documentation"]?.documentationParts;
            const filteredData = documentationData?.filter(elem => (elem?.location?.path === txtPath && (elem.location.type === "REQUEST_BODY" || elem.location.type === "REQUEST_QUERY" || elem.location.type === "REQUEST_HEADER" || elem.location.type === "REQUEST_PATH")));
            if (filteredData?.length > 0) {
                filteredData.map((data, index) => {
                    let txtDescription = data?.properties?.description;
                    if(data?.properties?.schema){
                        let schemaObj = data?.properties?.schema;
                        details.push(schemaObj);
                    } else {
                        details.push(sampleObject);
                    }
                })
            } else {
                details.push(sampleObject);
            }

        }else {
            details.push(sampleObject);
        }
        return renderResponseObjectProperties(details[0], txtPath, placeHolderArr);
    }

    const renderResponseObjectProperties = (objMethods, txtPath, placeHolderArr) => {
        let keys = Object.keys(objMethods);
        let temp = objMethods[keys];
        let keysnew = Object.keys(objMethods[keys]);
        if (keysnew.length === 0) {
            return null
        } else {

            return (
                <>
                    {isLoading && <Loader />}
                    <div className=''>{keysnew.length > 0 && keysnew.map((val, index) => (
                        <>
                            <li
                                key={val}
                                className='block px[20px my-[16px]'
                            >
                                <div key={val} className='flex item-center mb-[15px]'>
                                    <div
                                        className='w-[170px] mr-[16px]'
                                    >
                                        {val}<span className='text-red-600'>{placeHolderArr[val]?.required ? '*' : ''}</span>
                                    </div>
                                    <div className=''>
                                        {typeof (temp[val]) == "object" && Object.keys(temp[val]).length > 0 ?
                                            Object.keys(temp[val]).map((childKey, childIndex) => (
                                                <p key={childKey} className='mb-[12px] text-[14px]'>
                                                    <span className='inline-block w-[205px]'>{childKey} </span>
                                                    <input
                                                        className="mr-[16px]"
                                                        onChange={(e) => handleXAmazonParameters(e.target.value, index, val, childKey, txtPath, childIndex)}
                                                        type="text"
                                                    // placeholder={placeHolderArr[val][childKey]?.example}
                                                    />
                                                    ({temp[val][childKey]})
                                                </p>
                                            )) : <span> <input
                                                className="mr-[16px]"
                                                onChange={(e) => handleXAmazonParameters(e.target.value, index, val, '', txtPath, '')}
                                                type="text"
                                                placeholder={placeHolderArr[val]?.example}
                                                defaultValue={placeHolderArr[val]?.example}
                                            /> ({temp[val]})</span>
                                        }
                                    </div>

                                </div>
                            </li>
                        </>
                    ))
                    }

                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        if (props.apiDocs) {
            setAllData(UtilityService.updateXAmazonDoc(props.apiDocs));
            handleSelectedMethods(UtilityService.formatSwaggerMethods(props.apiDocs.paths, props.apiDocs.definitions));
        }
    }, [props?.apiDocs]);

    return (
        <Fragment>
            <div className='rounded-2xl border border-[#316AFF] border-opacity-40 mt-[20px]'>
                <div
                    className='grid grid-cols-4 gap-2 py-[16px] pl-[39px] pr-[32px]'
                >
                    <div
                        className='text-left text-base text-black pt-[12px]'
                    >
                        API Specification
                    </div>
                    <div
                        className='col-start-2 col-end-5 text-right'
                    >
                        {/* <span className='text-[14px] text-[#000000] py-[16px] mr-4'>
                            Environment - {props.apiData.envName}
                        </span> */}
                        {/* <button
                            className={`px-[30px] py-[12px] text-white bg-[#EDCA50] text-[10px] font-apimp-regular rounded-[10px] mr-4 ${activeMethodsCount === 0 ? "opacity-50" : "cursor-pointer"}`}
                            onClick={() => handlePreference()}
                            disabled={activeMethodsCount === 0}
                        >
                            Save Preference
                        </button>

                        <button
                            className={`px-[30px] py-[12px] text-white bg-txtPrimary text-[10px] font-apimp-regular rounded-[10px] mr-4 ${activeMethodsCount < 2 ? "opacity-50" : "cursor-pointer"}`}
                            onClick={() => handleSyncAsync("Synchronous")}
                            disabled={activeMethodsCount < 2}
                        >
                            Execute All
                        </button> */}

                        <button
                            className={`px-[30px] py-[12px] text-white bg-[#4BCE9E] text-[10px] font-apimp-regular rounded-[10px] ${activeMethodsCount === 0 ? "opacity-50" : "cursor-pointer"}`}
                            onClick={() => handleSyncAsync("Asynchronous")}
                            disabled={activeMethodsCount === 0}
                        >
                            Execute
                        </button>

                    </div>
                </div>
                <div
                    className="grid grid-cols-4"
                >
                    <div
                        className='p-[16px] border-[#4AB6F2] border-[0.5px] rounded-[15px]'
                    >
                        <div
                            className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                        >
                            Available Methods
                        </div>
                        {swaggerMethods && Object.keys(swaggerMethods).length === 0 && (<div
                            className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                        >
                            No methods found
                        </div>)
                        }

                        {swaggerMethods && Object.keys(swaggerMethods).length > 0 && Object.keys(swaggerMethods).map((data, index) => {
                            let id1 = index;
                            return (<div
                                key={id1}
                                className='block'
                            >
                                <span
                                    className='inline-block bg-txtPrimary border-txtPrimary border-[1px] text-[#FFFFFF] text-[12px] rounded-[15px] px-[16px] py-[10px] mb-[10px]'
                                > {data}  </span>
                                {
                                    swaggerMethods[data].map((elem, ind) => {
                                        let id2 = ind;
                                        return (
                                            <div
                                                key={id2}
                                                className='my-4 border-[#181F48] border-[1px] px-[10px] py-[8px] truncate text-ellipsis'
                                            >
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={elem.selected}
                                                    className="mr-[10px] cursor-pointer"
                                                    onChange={(e) => handleSwaggerMethods(e.target.checked, ind, data, elem.pathString)}
                                                />
                                                <span
                                                    className='text-[#000000] text-[10px] cursor-pointer'
                                                    title={elem.pathString ? elem.pathString : "/NA"}
                                                >
                                                    {elem.pathString ? elem.pathString : "/NA"}
                                                </span>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            )
                        }
                        )

                        }
                    </div>
                    <div
                        className='col-start-2 col-end-5 mx-4 mb-4'
                    >
                        <div
                            className=''
                        >
                            {swaggerMethods && Object.keys(swaggerMethods).length === 0 && activeMethodsCount === 0 && (<div
                                className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                            >
                                No methods found
                            </div>)
                            }
                            {swaggerMethods && Object.keys(swaggerMethods).length > 0 && activeMethodsCount === 0 && <div className='text-center text-[14px] text-[#000000] py-[16px] pl-[24px] pr-[40px]'>
                                Please select the method to perform operations
                            </div>}

                            {activeMethodsCount > 0 && Object.keys(swaggerMethods).length > 0 && Object.keys(swaggerMethods).map((data, ind) => (
                                swaggerMethods[data].map((item, index) => {
                                    if (item.selected) {
                                        let id = index;
                                        return (
                                            <div
                                                key={id}
                                                className='bg-txtSecondary p-[8px] mb-[20px] cursor-pointer'
                                                onDragStart={(e) => dragStart(e, index, data)}
                                                onDragEnter={(e) => dragEnter(e, index, data)}
                                                onDragEnd={(e) => drop(e, data)}
                                                draggable
                                                id={`drag${index}`}
                                            >
                                                <div className='mb-[10px]'>
                                                    <span
                                                        className='inline-block bg-txtPrimary border-txtPrimary border-[1px] text-[#FFFFFF] text-[10px] rounded-[15px] px-[20px] py-[10px] mr-[10px]'
                                                    >
                                                        {data}
                                                    </span>
                                                    {item.pathString}
                                                </div>
                                                <h3>Parameters: </h3>
                                                <ul>
                                                    {item?.parameters && item.parameters?.length > 0  && !Object.values(item)[0].definitions && item.parameters.map((elm, ind) => {
                                                        let id2 = ind;
                                                        if(!elm.type){
                                                            return false;
                                                        }
                                                        return (
                                                            <li
                                                                key={id2}
                                                                className='block px[20px my-[16px]'
                                                            >
                                                                <label
                                                                    htmlFor={`${item.pathString}`}
                                                                    className='inline-block min-w-[170px] mr-[16px]'
                                                                >{elm.name}
                                                                </label>
                                                                <input
                                                                    className="mr-[16px]"
                                                                    onChange={(e) => handleAllParameters(e.target.value, index, ind, data, "")}
                                                                    type="text"
                                                                    id={`${item.summary}-${elm.name}`}
                                                                />
                                                                ( {elm.type} )
                                                            </li>
                                                        )
                                                    })}
                                                    {!item?.parameters && getRequestBody(item.pathString, "REQUEST_BODY") === null && <li
                                                        key={id}
                                                        className='block px[20px my-[16px]'
                                                    >
                                                        <label
                                                            htmlFor={`${item.pathString}`}
                                                            className='inline-block min-w-[170px] mr-[16px]'
                                                        >NA
                                                        </label>
                                                    </li>}

                                                    {Object.values(item)[0].definitions && Object.values(item)[0].definitions.length > 0 && Object.values(item)[0].definitions.map((elm, index_def) => {
                                                        let id3 = index_def;
                                                        return (
                                                            <li
                                                                key={id3}
                                                                className='block px[20px my-[16px]'
                                                            >
                                                                <label
                                                                    htmlFor={`${item.pathString}`}
                                                                    className='inline-block min-w-[170px] mr-[16px]'
                                                                >{elm.name}
                                                                </label>
                                                                <input
                                                                    className="mr-[16px]"
                                                                    onChange={(e) => handleAllParameters(e.target.value, index, index_def, data, "definition")}
                                                                    type="text"
                                                                    id={`${item.pathString}}`}
                                                                />
                                                                ( {elm.type} )
                                                            </li>
                                                        )
                                                    })}

                                                    {getRequestBody(item.pathString, "REQUEST_BODY")}
                                                </ul>
                                            </div>
                                        )
                                    }
                                })
                            ))}
                        </div>

                    </div>
                </div>
            </div>
            {showModal ? (
                <ResponseModal
                    handleClose={handleClose}
                    responseType={responseType}
                    responseData={responseData}
                />
            ) : null}
        </Fragment>
    )
}

export default ApplicationApi