import React, { Fragment, useEffect, useState } from 'react'
import Header from './Components/Common/Header';
import AppServices from './Services/API';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from './Services/Utility';
import CONFIG from './Utils';

const ProtectedRoutes = ({ children }) => {
    const isSession = sessionStorage.getItem('userDetails');
    const [isAuthorized, setIsAuthorized] = useState(isSession);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        !isAuthorized && getUserData();
    }, [])

    const backToLogin = () => {
        navigate('/login');
    }   
    
    const getUserData = async () => {
        // let jwtToken = AppServices.getjwtToken();
        let awsToken = sessionStorage.getItem('jwtToken');  

        const urlParams = new URLSearchParams(location.search);
        const jwtTokenParam = urlParams.get('jwtToken');
        
        if (jwtTokenParam || awsToken) {
            sessionStorage.setItem('jwtToken', jwtTokenParam);
            if(jwtTokenParam){
                let parsedData= UtilityService.GET_USER_DETAILS(jwtTokenParam)

                AppServices.getUserAccessDetails(parsedData.email).then((response) => {
                    if(response){
                        console.log("protected response==", response);
                        if(response.isApproved !== "Approval Pending"){
                            let details = {
                                "userId": parsedData.email,
                                "userName": parsedData.username,
                                "roles": response.hasRole
                            }
                            sessionStorage.setItem("userDetails", JSON.stringify(details));
                            setIsAuthorized(true); 
                        } else {
                            CONFIG.TOASTMESSAGES("error", response.isApproved);
                            backToLogin();
                        }                
                    } else {
                        CONFIG.TOASTMESSAGES("error", response.error);
                        backToLogin();
                    }
                }).catch((e) => {
                        console.log("error==", e);
                        backToLogin();
                });                
            }            
        } else {
            //call aws
            backToLogin();
        }
    };
    return (
        <Fragment>
            {isAuthorized &&
                <>
                    <Header user={isAuthorized} />
                    <div className="page-wrapper">{children}</div>
                </>
            }
        </Fragment>
    );
};

export default ProtectedRoutes;