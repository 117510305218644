import React, { useEffect, useState } from "react";
import APIService from "../../Services/API";
import ApplicationCard from "../Common/ApplicationCard";
import AddApplication from "./AddApplication";
import Loader from "../Common/Loader"
import ConfirmBox from "../Common/ConfirmBox";
import CONFIG from "../../Utils";
import { useCentralStore } from "../../store/appContext";
const Applications = () => {
  const [state, dispatch] = useCentralStore();
  const [showModal, setShowModal] = useState(false);
  const [applicationData, setApplicationData] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userObj = state?.currentUser //JSON.parse(sessionStorage.getItem("userDetails"));
  
  const getApplicationData = () => {
    setIsLoading(true);
    // APIService.getApplicationCard(userObj.userId).then((response) => {
    APIService.getApplicationListByUser((userObj?.RoleName?.toUpperCase() === "ASURION ADMIN" || userObj?.RoleName?.toUpperCase() === "ASURION USER") ? 'All' : userObj?.Partner)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setApplicationData(response?.GetApplicationByUser);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("Error - ", e);
      });

  };

  const handleDelete = () => {
    setShowConfirmation(false);
    setIsLoading(true);
    let payload = {DeleteApplicationRequest: [
      {
          ApplicationId: deleteId
      }
  ]}
    APIService.deleteApplication(payload).then((response) => {
      if (response?.DeleteApplicationResponse?.message !== "") {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("success", response?.DeleteApplicationResponse?.message);          
          getApplicationData();
        } else {
          setIsLoading(false);
          CONFIG.TOASTMESSAGES("error", response?.DeleteApplicationResponse?.message);
      }      
    })
      .catch((e) => {
        setIsLoading(false);
        CONFIG.TOASTMESSAGES("error", "Internal Server Error at fetchStatusHandler.");
        console.log("Error - ", e);
      });
  }

  const handleConfirmation = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowConfirmation(true);
  }

  const handleCloseConfirmation = () => {
    setDeleteId("");
    setShowConfirmation(false);
  }

  const handleSearch = (event) => {
    if (event.code === 'Enter') {
      const filteredValue = event.target.value.toLowerCase();
      if (!filteredValue) {
        getApplicationData();
      } else {
        const filteredData = applicationData?.filter((item, index) => {
          return item?.ApplicationName?.toLowerCase().includes(filteredValue);
        }) ?? [];
        setApplicationData(filteredData);
      }
    }
  };

  const handleCloseApp = () => {
    setShowModal(false);
    getApplicationData();
  }

  useEffect(() => {
    getApplicationData();
  }, []);


  return (
    <>
      <div className="grid px-[20px] pt-[26px] pb-[24px]">
        <div className="grid grid-cols-3">
          <div className="relative col-start-1 col-end-3 text-black text-xl font-apimp-medium">
            Application
          </div>
          {(userObj?.RoleName?.toUpperCase() === "ASURION ADMIN" || userObj?.RoleName?.toUpperCase() === "ASURION USER") &&
            <div className="text-right col-start-3 col-end-4">
              <button
                type="button"
                className="inline-block px-3 py-2.5 bg-txtPrimary text-white font-inter-regular text-[16px] rounded-xl"
                onClick={() => {
                  showModal ? setShowModal(false) : setShowModal(true);
                }}
              >
                <img
                  className="inline-flex cursor-pointer relative mr-2"
                  src={"images/add_circle.svg"}
                  alt="add"
                />
                Add Application
              </button>
            </div>
          }
        </div>
        {/* Search Bar */}
        <div className="grid grid-cols-12">
          <div className="relative col-span-5 ">
            <span className="absolute  font-Inter py-5 px-4 flex right-1">
              <img
                src="./images/search.svg"
                alt="search_icon"
                className="text-[#181F48]"
              />
            </span>

            <input
              className="w-full py-4 pl-6 font-apimp-medium font-medium text-base text-[#181F48] rounded-[10px] border-txtPrimary border-[0.5px] outline-none  placeholder:text-[#181F48] placeholder:opacity-50 placeholder:font-[400px] placeholder:font-apimp-regular"
              placeholder="Search Applications"
              onKeyUp={(event) => handleSearch(event)}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 mt-8  gap-[27px]">
          {applicationData &&
            applicationData.map((apiData, index) => (
              <ApplicationCard
                key={apiData?.ApplicationId}
                role={userObj?.RoleName}
                apiData={apiData}
                handleConfirmation={handleConfirmation}
                currentUser={userObj}
              />
            ))}
          {applicationData?.length == 0 && <div>No Data Available...</div>}
        </div>
      </div>
      {isLoading && <Loader />}
      {showModal ? (
        <AddApplication
          showModal={showModal}
          handleCloseApp={handleCloseApp}
          id=""
          data=""
        />
      ) : null}
      {showConfirmation ? (
        <ConfirmBox
        descText="Are you sure you want to delete?"
          handleCloseConfirmation={handleCloseConfirmation}
          handleAgree={handleDelete}
        />
      ) : null}

    </>
  );
};

export default Applications;
