import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ApplicationApi from "./ApplicationApi";
import Loader from "../Common/Loader";
import APIService from "../../Services/API";
import ExportToLanguage from "./ExportToLanguage";
import Documentation from "../ApiDirectory/Documentation";
import CONFIG from "../../Utils";
import { useCentralStore } from "../../store/appContext";

const ApplicationApiDetails = () => {
    const [{ currentUser }, dispatch] = useCentralStore();
    const [activeTab, setActiveTab] = useState("Apiconsole");
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState("");
    const [apiDocs, setApiDocs] = useState("");
    const params = useParams();
    const userObj = JSON.parse(sessionStorage.getItem("userDetails"));
    const userData = useState(currentUser); //JSON.parse(sessionStorage.getItem('userDetails'))

    const getApiDocs = (txtUrl) => {
        setIsLoading(true);
        APIService.getApiDocs(txtUrl)
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                    setApiDocs(response);
                }
            })
            .catch((e) => {
                setIsLoading(false);
                console.log("Error - ", e);
            });
    };

    const getApiDetailsInApp = () => {
        setIsLoading(true);

        APIService.apiDetailsInApp(params.appid, params.apiid, userObj.userId)
            .then((response) => {
                if (response) {
                    setIsLoading(false);
                    setApiData(response);
                    getApiDocs(response.swaggerUrl);
                }
            })
            .catch((e) => {
                setIsLoading(false);
                console.log("Error - ", e);
            });
    };

    const callSwaggerAPI = () => {
        APIService.getSwaggerDetails(params.apiid)
            .then(resp => {
                if(resp?.SwaggerToJson?.message) {
                    CONFIG.TOASTMESSAGES("error", resp?.SwaggerToJson?.message);
                } else {
                    setApiDocs(resp?.SwaggerToJson)
                }
            })
            .catch(err => {
                CONFIG.TOASTMESSAGES("error", err?.SwaggerToJson?.message);
            })
    }

    useEffect(() => {
        // getApiDetailsInApp();
        callSwaggerAPI()
    }, []);

    return (
        <div className="grid px-5 pt-[26px] pb-[24px]">
            <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3 py-[2.72px]">
                    <li className="inline-flex items-center">
                        <img src="/images/home.svg" alt="home"></img>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <Link
                                to="/applications"
                                className="ml-1 text-base font-apimp-regular text-black underline md:ml-2"
                            >
                                Application
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <Link
                                to={`/application-details/${params.appid}`}
                                className="ml-1 text-base font-apimp-regular text-black underline md:ml-2"
                            >
                                Application Details
                            </Link>
                        </div>
                    </li>
                </ol>
            </nav>


            <div className="rounded-2xl mt-8 border border-black border-opacity-20">
                <div className="mb-4 mt-4 border-b border-gray-200 dark:border-gray-700">
                    <ul
                        className="flex flex-wrap -mb-px text-sm font-medium text-center"
                        id="myTab"
                        data-tabs-toggle="#myTabContent"
                        role="tablist"
                    >
                        <li className="mr-[20px]" role="presentation">
                            <button
                                className={`${activeTab === "Apiconsole"
                                    ? "border-b-[2px] border-b-txtPrimary hover:text-[#316AFF]-600 hover:border-[#316AFF]-300 dark:hover:text-[#316AFF]-300"
                                    : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                    } inline-block p-4 border-b-2 rounded-t-lg`}
                                id="apiconsole-tab"
                                data-tabs-target="#apiconsole"
                                type="button"
                                role="tab"
                                aria-controls="apiconsole"
                                aria-selected="false"
                                onClick={() => setActiveTab("Apiconsole")}
                            >
                                API Console
                            </button>
                        </li>
                        {/* <li className="mr-[20px]" role="presentation">
                            <button
                                className={`${activeTab === "ExportToLanguage"
                                    ? "border-b-[2px] border-b-txtPrimary hover:text-[#316AFF]-600 hover:border-[#316AFF]-300 dark:hover:text-[#316AFF]-300"
                                    : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                    } inline-block p-4 border-b-2 rounded-t-lg`}
                                id="ExportToLanguage-tab"
                                data-tabs-target="#ExportToLanguage"
                                type="button"
                                role="tab"
                                aria-controls="ExportToLanguage"
                                aria-selected="false"
                                onClick={() => setActiveTab("ExportToLanguage")}
                            >
                                Export To Language
                            </button>
                        </li> */}
                        <li className="mr-[20px]" role="presentation">
                            <button
                                className={`${activeTab === "Documentation"
                                    ? "border-b-[2px] border-b-txtPrimary hover:text-[#316AFF]-600 hover:border-[#316AFF]-300 dark:hover:text-[#316AFF]-300"
                                    : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                    } inline-block p-4 border-b-2 rounded-t-lg`}
                                id="Documentation-tab"
                                data-tabs-target="#Documentation"
                                type="button"
                                role="tab"
                                aria-controls="Documentation"
                                aria-selected="false"
                                onClick={() => setActiveTab("Documentation")}
                            >
                                Specification
                            </button>
                        </li>
                    </ul>
                </div>

                <div id="myTabContent">
                    <div
                        className={`${activeTab === "Apiconsole" ? "show" : "hidden"
                            } rounded-2xl bg-white dark:bg-gray-800`}
                        id="apiconsole"
                        role="tabpanel"
                        aria-labelledby="apiconsole-tab"
                    >
                        {activeTab === "Apiconsole" && apiDocs &&
                            <ApplicationApi
                                apiDocs={apiDocs}
                                apiData={apiData}
                                userData={userData}
                            />
                        }
                    </div>
                    {/* <div
                        className={`${activeTab === "ExportToLanguage" ? "show" : "hidden"
                            } rounded-2xl bg-white dark:bg-white`}
                        id="ExportToLanguage"
                        role="tabpanel"
                        aria-labelledby="ExportToLanguage-tab"
                    >
                        {activeTab === "ExportToLanguage" && apiData &&
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                            <ExportToLanguage
                                apiDocs={apiDocs}
                                userData={userData}
                            />
                        </div>
                        }
                    </div> */}
                    <div
                        className={`${activeTab === "Documentation" ? "show" : "hidden"
                            } rounded-2xl bg-white dark:bg-gray-800`}
                        id="documentation"
                        role="tabpanel"
                        aria-labelledby="documentation-tab"
                    >
                        {activeTab === "Documentation" && apiDocs &&
                            <Documentation
                                apiUrl={apiDocs}
                                userData={userData}
                            />
                        }
                    </div>

                </div>
                {isLoading && <Loader />}
            </div>
        </div>
    );
};

export default ApplicationApiDetails;