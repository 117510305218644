import React, { useState } from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const APICard = (props) => {
  const navigate = useNavigate();
  const [showDelete, setshowDelete] = useState(false);

  const showDeleteButton = () => {
    props.role === "ASURION ADMIN" ? setshowDelete(true) : setshowDelete(false);
  };
  const hideDeleteButton = () => {
    setshowDelete(false);
  };

  const handleDetails = (event, id) => {
    event.preventDefault();
    event.stopPropagation(); 
    navigate(`/api-directory/${id}`,{ state: { isApiRequest: props.isApiRequestData }} )
  }

  const ratingStar = Array.from({ length: 5 }, (ele, index) => {
    let number = index + 0.5;
    if (props.data.ratings >= index + 1) {
      return <span key={index}><FaStar className="icon fill-[#E1B824]" /></span>;
    } else if (props.data.ratings >= number) {
      return <span key={index}><FaStarHalfAlt className="icon fill-[#E1B824]" /></span>;
    } else {
      return <span key={index}><AiOutlineStar className="icon fill-[#E1B824]" /></span>;
    }    
  })

  return (
    <div
      className="shadow-gray-300 shadow-xl bg-white border py-4 border-[#181F48] border-opacity-20 rounded-2xl relative"
      // onClick={(event) => handleDetails(event,props.data.id)}
      onClick={undefined}
      // onMouseOver={() => showDeleteButton()}
      // onMouseOut={() => hideDeleteButton()}
    >
      {/* <img 
        className="cursor-pointer p-4 inline-block absolute right-1 top-1"
        src={`images/${props.data.saved ? 'bookmarked.svg' : 'not_bookmarked.svg' }`}
        alt="Save" 
        title={`${props.data.saved ? 'saved' : 'Save' }`}
        onClick={(event) => props.handleSave(event, props.data.id, !props.data.saved)}
      /> */}
      
      {showDelete ? (
        <img
          className="inline-block absolute right-[30px] top-[66px] cursor-pointer"
          src="images/delete.svg"
          alt="delete"
          title="delete"
          onClick={(event) => props.handleConfirmation(event, props.data.id)}
        />
      ) : (
        ""
      )}

      <div className="flex flex-col items-center">
        <div
          className="grid place-items-center text-center bg-txtSecondary w-[80px] h-[80px] rounded-[20px]"
        >
          <img
            className={`${props?.data?.iconUrl === null
                ? "mx-auto my-[0.5px] text-center"
                : "mx-auto my-[0.5px] text-center w-[65px] h-[65px] rounded-[12px]"
              }`}
            // src={`${props?.data?.iconUrl === null
            //     ? "/images/new_api_img.svg"
            //     : props?.data?.iconUrl
            //   }`}
            src={`/images/new_api_img.svg`}
            alt="api_img"
          />
        </div>
        <h5 className="relative mt-3 text-[18px] font-apimp-semibold text-txtPrimary text-center line-clamp-1"
          title={props.data.Name}
        >
          {props.data.Name}
        </h5>
        <span className="inline-block mt-1 py-1 px-2.5 text-center font-apimp-medium text-[10px] bg-txtPrimary bg-opacity-20 text-txtPrimary rounded-md">
          {props.data.Category || 'NA'}
        </span>

        <div className="flex mt-2 space-x-3 text-center line-clamp-1">
          {/* <a className="inline-flex items-center text-xs font-apimp-medium text-center text-black">
            {props.data.version}
          </a> */}
          <span className="ml-4 text-[10px] font-apimp-regular inline-flex items-center px-3 py-0.5 rounded-full bg-white text-black border border-[#EBF1FF]">
            By {props?.data?.CreatedBy?.split('@')?.[0]}
          </span>
        </div>

        {/* <div className="flex mt-2">
          <div className="icon-style flex justify-center mt-1 mx-1">
            {ratingStar}
          </div>
          <span className="border-x border-black border-opacity-30 h-4 mt-1 mx-1"></span>
          <a className="inline-flex font-apimp-light items-center px-2 w-full text-sm text-center text-black py-1">
            {props.data.ratingCounts} Ratings
          </a>
        </div> */}
        <p 
          className="px-3 text-xs font-apimp-light text-black text-opacity-50 text-center mt-3 w-[280px] truncate text-ellipsis"
          title={props.data.Description}
        >
          {props.data.Description}
        </p>
      </div>
    </div>
  );
};

export default APICard;
