import React, { useState, useEffect } from "react";
import APIUsedCards from "../Common/APIUsedCards";
import AddApplication from "../Applications/AddApplication";
import { Link, useParams, useNavigate } from "react-router-dom";
import APIService from "../../Services/API";
import ConfirmBox from "../Common/ConfirmBox";
import CONFIG from "../../Utils";
import { useCentralStore } from "../../store/appContext";
import Loader from "../Common/Loader";

const ApplicationDetails = () => {
  const [state, dispatch] = useCentralStore();
  const [showModal, setShowModal] = useState(false);
  const [applicationData, setApplicationData] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showApiConfirmation, setShowApiConfirmation] = useState(false);
  const [inActiveApi, setInActiveApi] = useState([]);
  const [deleteApiData, setDeleteApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const userObj = state?.currentUser //JSON.parse(sessionStorage.getItem("userDetails"));

  const getApplicationDetailsById = (id) => {
    setIsLoading(true)
    // APIService.getApplicationDetailsById(id, userObj?.userId).then((response) => {
    APIService.getListOfApis(id)
      .then((response) => {
        if (response) {
          setIsLoading(false)
          setApplicationData(response?.GetListOfApis);
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log("Error - ", e);
      });
  };

  const handleInActive = (event, txtChecked, txtValue) => {
    let apiAry = JSON.parse(JSON.stringify(inActiveApi));
    const index = apiAry.findIndex(object => {
      return object.id === txtValue;
    });

    if (index > -1) {
      apiAry[index].enableBaseUrlId = txtChecked
    } else {
      let objVal = {
        "id": txtValue,
        "enableBaseUrlId": txtChecked
      }
      apiAry.push(objVal);
    }

    setInActiveApi(apiAry);
  }

  const handleDelete = () => {
    setShowConfirmation(false);
    APIService.deleteApplicationById(params.aid, userObj.userId).then((response) => {
      if (response) {
        CONFIG.TOASTMESSAGES("success", "Record deleted successfully.");
        navigate(`/applications`)
      }
    })
      .catch((e) => {
        CONFIG.TOASTMESSAGES("error", "Internal Server Error.");
        console.log("Error - ", e);
      });
  }

  const handleDeleteApi = () => {
    setShowApiConfirmation(false);
    // let payload = {
    //   "userId": userObj.userId,
    //   "appId": params.aid,
    //   "baseUrlId": {
    //     "id": deleteId
    //   }
    // }
    console.log('');
    let payload = {
      EnrollApiToApp: [
        {
          ApplicationId: state?.currentApplicationData?.ApplicationId,
          UserId: userObj?.UserId,
          Name: deleteApiData?.Name,
          ApiDetailsId: deleteApiData?.ApiDetailsId,
          Key: deleteApiData?.Key,
          Action: "Delete"
        }
      ]
    }
    console.log('delete api', payload);
    // APIService.deleteApiFromApplicationById(payload).then((response) => {
    APIService.enrollApiToApp(payload).then((response) => {  
      console.log('enrollApiToApp resp', response);
      if (response) {
        CONFIG.TOASTMESSAGES("success", "Record deleted successfully.");
        // navigate(`/applications`)
        handleCloseApp()
      }
    })
      .catch((e) => {
        CONFIG.TOASTMESSAGES("error", "Internal Server Error.");
        console.log("Error - ", e);
      });
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  }

  const handleApiConfirmation = (event, apiData) => {
    setDeleteApiData(apiData);
    setShowApiConfirmation(true);
  }

  const handleCloseApiConfirmation = () => {
    setShowApiConfirmation(false);
  }

  const handleCloseApp = () => {
    setShowModal(false);
    getApplicationDetailsById(params.aid);
  }

  const handleUpdateApiInApp = () => {

    let payload = {
      "userId": userObj.userId,
      "appId": params.aid,
      "baseUrlId": inActiveApi
    }

    APIService.updateApiInApp(payload).then((response) => {
      if (response) {
        CONFIG.TOASTMESSAGES("success", "API Updated in App successfully.");
        getApplicationDetailsById(params.aid);
      } else {
        CONFIG.TOASTMESSAGES("error", response.message);
      }
    })
      .catch((e) => {
        CONFIG.TOASTMESSAGES("error", "Internal Server Error.");
        console.log("Error - ", e);
      });
  }

  const handleAppApi = (event, id) => {
    if (event.target.classList.contains('new-test-check')) {
      return false;
    }
    navigate(`/application-api-details/${params.aid}/${id}`);
  };

  const handleCancel = () => {
    navigate(`/applications`);
  }

  useEffect(() => {
    getApplicationDetailsById(params.aid);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="grid px-5 pt-[26px] pb-[24px]">
        <div className="flex justify-between items-center">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3 py-[2.72px]">
              <li className="inline-flex items-center">
                <img src="/images/home.svg" alt="home"></img>
              </li>
              <li>
                <div className="flex items-center">
                  <Link
                    to='/applications'
                    className="ml-1 text-base font-apimp-regular text-black underline md:ml-2"
                  >
                    Application
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <img src="/images/crossline.svg" alt="crossline"></img>
                  <span className="ml-1 text-lg font-apimp-medium text-black md:ml-2">
                    {applicationData?.appName}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div className="rounded-2xl mt-8 border border-black border-opacity-20">
          <div className="bg-txtSecondary flex px-10 rounded-2xl relative">
            {/* <img
            className="py-11"
            src="/images/applicationdetails.svg"
            alt="applicationdetails"
          /> */}
            <div
              className="grid place-items-center text-center bg-txtPrimary w-[80px] h-[80px] rounded-[20px] my-5"
            >
              <img
                className="mx-auto my-[0.5px] text-center w-[65px] h-[65px] rounded-[35px]"
                src="/images/applcation_card_logo.svg"
                alt="api_img"
              />
            </div>
            <div className="flex flex-col pl-6">
              <h1 className="font-apimp-semibold text-base text-[#181F48] pt-10">
                {applicationData?.appName}
              </h1>
              <p className="font-apimp-regular text-sm text-black text-opacity-50 pt-[10px] pr-16">
                {applicationData?.description}
              </p>
              {/* <span className="text-xs font-apimp-medium text-black text-opacity-50 pt-3 pb-8">
              Token Type:
              <span className="text-base text-black font-apimp-medium pl-1">
                QAuth
              </span>
            </span> */}
            </div>
            {(userObj?.RoleName?.toUpperCase() === "ASURION ADMIN" || userObj?.RoleName?.toUpperCase() === "ASURION USER") &&
              <div className="pt-10 ">
              <button
                onClick={() => {
                  showModal ? setShowModal(false) : setShowModal(true);
                }}
                // className="absolute right-[35px] top-[35px] text-blue-400 underline cursor-pointer"
                className="absolute right-[35px] top-[35px] inline-block px-3 py-2.5 bg-txtPrimary text-white font-inter-regular text-base rounded-xl"
              >
                {/* <img src="/images/edit.svg" alt="edit" /> */}
                <img
                    className="inline-flex cursor-pointer relative mr-2"
                    src={"/images/add_circle.svg"}
                    alt="add"
                  />
                Add API's to applicaiton
              </button>
            </div>
            }
            
          </div>

          <div className="flex justify-between pt-6 px-10">
            <h1 className="font-apimp-medium text-black text-base">
              APIs Used:
            </h1>
            {/* <h1 className="font-apimp-medium text-blue-400 underline cursor-pointer text-base ">
              Add API's to applicaiton
            </h1> */}
          </div>
          <div className="grid grid-cols-3 gap-4 mx-10 mb-5">
            {/* {applicationData && applicationData?.baseUrls !== null && applicationData?.baseUrls.length > 0 && applicationData?.baseUrls.map((data, index) => ( */}
            {applicationData?.map((data, index) => (
              <APIUsedCards
                key={data.id}
                {...data}
                handleInActive={handleInActive}
                handleApiConfirmation={handleApiConfirmation}
                handleAppApi={handleAppApi}
                currentUser={userObj}
              // aid={params.aid}
              />
            ))

            }
            {applicationData && applicationData?.length == 0 && (
              <div
                className="text-base text-[#181F48]"
              >
                No Data found
              </div>
            )

            }

          </div>

          {/* <div className="flex justify-between mt-36 mb-5">
            <div className="flex items-center">
              <span className="font-apimp-regular text-[10px] ml-2">
                Created on {applicationData && applicationData.date !== null ? applicationData.date : ''}
              </span>
            </div>
            <div className="flex items-center">
              <button
                className="font-apimp-medium text-xs  mr-8 px-4 py-2 bg-white"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                className="bg-txtPrimary text-white font-apimp-medium text-xs py-2 px-4 rounded-[10px] mr-10"
                disabled={applicationData && applicationData?.baseUrls == null}
                onClick={() => handleUpdateApiInApp()}
              >
                Update
              </button>
            </div>
          </div> */}
        </div>

        {showModal && (
          <AddApplication
            showModal={showModal}
            handleCloseApp={handleCloseApp}
            id={params.aid}
            data={applicationData}
            editApplication={true}
          />
        )}

        {showConfirmation && (
          <ConfirmBox
            descText="Are you sure you want to delete?"
            handleCloseConfirmation={handleCloseConfirmation}
            handleAgree={handleDelete}
          />
        )}

        {showApiConfirmation && (
          <ConfirmBox
            descText="Are you sure you want to delete?"
            handleCloseConfirmation={handleCloseApiConfirmation}
            handleAgree={handleDeleteApi}
          />
        )}
      </div>
    </>
  );
};

export default ApplicationDetails;