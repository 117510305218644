import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import ModalTemplet from "../Common/ModalTemplet";
import { TagsInput } from "react-tag-input-component";
import '../../index.scss'
import CONFIG from "../../Utils";
import APIService from "../../Services/API";
import AWS from '../Common/AwsConfig';
import Loader from "../Common/Loader";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";

const AddApi = ({ handleClose }) => {
  const [ { currentUser, masterDataDetails }, dispatch ] = useCentralStore()
  const [selectedTagInput, setSelectedTagInput] = useState([]);
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const inputFileRef = useRef(null);
  const sdkfileRef = useRef(null);
  const [iconUrl, setIconUrl] = useState("");
  const apiUrlRef = useRef([]);
  const gitUrlRef = useRef([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState(null);

  const [users, setUsers] = useState(
    [{ environment_name: '',  inputType: 'upload_file', api_url: '', swagger_file: '', platformType: '', auth_type: 'api', headers: [{ custom_key: '', custom_value: '' }], credential_file: '', sdk_file: '' }]
  );
  // service_name: '', branch_name: '', git_url: '',
  const [otherDetails, setOtherDetails] = useState(
    [{ api_name: '', description: '', category: '', client: '' }] //api_version: '',
  );

  const [base64ForSwaggerFile, setBase64ForSwaggerFile] = useState("");
  const [base64ForCredFile, setBase64ForCredFile] = useState("");
  const [categoryList, setCategoryList] = useState("");
  
  useEffect(() => {
    setCategoryList(masterDataDetails?.Categories)
    setClients(masterDataDetails?.Partners)
  }, [])

  

  const handleChangeOtherDetails = (index, field, value) => {
    const updatedOtherDetails = [...otherDetails];
    updatedOtherDetails[index][field] = value;
    setOtherDetails(updatedOtherDetails);
  };

  const handleChange = (index, field, value) => {
    const updatedUsers = [...users];
    updatedUsers[index][field] = value;
    setUsers(updatedUsers);
  };

  const removeUser = (index) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setUsers(updatedUsers);
  };

  const addHeader = (index) => {
    const updatedUsers = [...users];
    updatedUsers[index].headers.push([]);
    setUsers(updatedUsers);
  };

  const removeHeader = (userIndex, headerIndex) => {
    const updatedUsers = [...users];
    updatedUsers[userIndex].headers.splice(headerIndex, 1);
    setUsers(updatedUsers);
  };

  const {
    handleSubmit,
  } = useForm({});

  const copyApiUrl = (index) => {
    apiUrlRef.current[index].select();
  };

  const copyGitUrl = (index) => {
    gitUrlRef.current[index].select();
  };

  const handleFileUpload = () => {
    inputRef.current.click();
  };

  const credentialFileUpload = () => {
    fileRef.current.click();
  };

  const inputFileUpload = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const bucketName = 'api-market-place';
    const s3 = new AWS.S3();

    const params = {
      Bucket: bucketName,
      Key: event.target.files[0].name,
      Body: event.target.files[0],
      ACL: 'public-read', // Set the access control list for the uploaded file (optional)
    };
    s3.upload(params, (err, data) => {
      if (err) {
        console.log('Error uploading file:', err);
        alert('Failed to upload file.');
      } else {
        console.log('File uploaded successfully:', data);
      }
    });

    setIconUrl(event.target.files[0]);
    event.target.value = null;
  };

  // const handleCredentialFile = async (index, field, event) => {
  //   setIsLoading(true);
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  //   if (event.target.files[0].type !== "text/plain" && event.target.files[0].type !== "application/json") {
  //     CONFIG.TOASTMESSAGES("error", "upload file of type text / json only.");
  //     return false;
  //   }

  //   const fileExt = fileObj.name.split(".").pop();
  //   let envName = users[index].environment_name;
  //   const newFileName = `${envName}.${fileExt}`;

  //   let filename = `apiCredentialFiles/${newFileName}`;
  //   let preSignedUrl = await APIService.getPreSignedUrl(filename);
  //   // console.log("preSignedUrl==", preSignedUrl, "  filename==",filename);
  //   // new code
  //   if (preSignedUrl) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result.split(',')[1];
  //       try {
  //         const binaryString = atob(base64String); // Decode the Base64 string to binary data
  //         const blob = new Blob([new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))], { type: fileObj.type });
  //         // new code
  //         const formData = new FormData();
  //         formData.append('file', blob, newFileName);

  //         APIService.uploadFileToBucket(preSignedUrl.toString(), blob).then((response) => {
  //           setIsLoading(false);
  //           setUsers(prevState => {
  //             const updatedUsers = [...prevState];
  //             updatedUsers[index][field] = fileObj;
  //             return updatedUsers;
  //           });
  //         }).catch((e) => {
  //           setIsLoading(false);
  //           console.log("Error - ", e);
  //         });

  //       } catch (error) {
  //         setIsLoading(false);
  //         console.error('Error decoding Base64:', error);
  //         console.log('Base64 String:', base64String);
  //       }
  //     };
  //     reader.readAsDataURL(fileObj, preSignedUrl, newFileName);
  //   }
  // };

  const handleCredentialFile = async (index, field, event) => {

    setIsLoading(true);
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (event.target.files[0].type !== "application/json" && event.target.files[0].type !== "") {
      CONFIG.TOASTMESSAGES("error", "upload file of type json / yaml only.");
      return false;
    }

    const newFileName = fileObj.name //`${fileObj.name}.${fileExt}`;
    const updatedUsers = [...users];
    updatedUsers[index][field] = newFileName;
    setUsers(updatedUsers);

    const reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onload = () => {
      const base64String = reader?.result?.split(',')?.[1];
      setBase64ForCredFile(base64String)
      setIsLoading(false);
      // console.log('Base64 String:', base64String);
    };
    setIsLoading(false);
  }

  function base64ToBlob(base64String, contentType) {
    const binary = atob(base64String);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
  }

  const sdkFileUpload = () => {
    sdkfileRef.current.click();
  };

  const handleSdkFile = async (index, field, event) => {
    setIsLoading(true);
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (event.target.files[0].type !== "application/x-zip-compressed") {
      CONFIG.TOASTMESSAGES("error", "upload zip file type only.");
      return false;
    }

    const fileExt = fileObj.name.split(".").pop();
    let envName = users[index].environment_name;
    const newFileName = `${envName}.${fileExt}`;

    let filename = `SDKs/${newFileName}`
    let preSignedUrl = await APIService.getPreSignedUrl(filename);
    // console.log("preSignedUrl==", preSignedUrl);
    // new code

    if (preSignedUrl) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        try {
          const binaryString = atob(base64String); // Decode the Base64 string to binary data
          const blob = new Blob([new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))], { type: fileObj.type });
          const formData = new FormData();
          formData.append('file', blob, newFileName);

          APIService.uploadFileToBucket(preSignedUrl.toString(), formData).then((response) => {
            setIsLoading(false);
            setUsers(prevState => {
              const updatedUsers = [...prevState];
              updatedUsers[index][field] = fileObj;
              return updatedUsers;
            });
            console.log("swagger response==", response);
          })
            .catch((e) => {
              setIsLoading(false);
              console.log("Error - ", e);
            });

        } catch (error) {
          setIsLoading(false);
          console.error('Error decoding Base64:', error);
          console.log('Base64 String:', base64String);
        }
      };
      reader.readAsDataURL(fileObj, preSignedUrl, newFileName);
    }
  };

  const handleInputFile = async (index, field, event) => {

    setIsLoading(true);
    const fileObj = event.target.files && event.target.files[0];
    console.log('handle swagger event', event);
    if (!fileObj) {
      return;
    }
    if (event.target.files[0].type !== "application/json" && event.target.files[0].type !== "") {
      CONFIG.TOASTMESSAGES("error", "upload file of type json / yaml only.");
      return false;
    }

    const newFileName = fileObj.name //`${fileObj.name}.${fileExt}`;
    const updatedUsers = [...users];
    updatedUsers[index][field] = newFileName;
    setUsers(updatedUsers);

    const reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onload = () => {
      const base64String = reader?.result?.split(',')?.[1];
      setBase64ForSwaggerFile(base64String)
      setIsLoading(false);
      // console.log('Base64 String:', base64String);
    };
    setIsLoading(false);
  }


  // const handleInputFile = async (index, field, event) => {
  //   setIsLoading(true);
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  //   if (event.target.files[0].type !== "application/json" && event.target.files[0].type !== "") {
  //     CONFIG.TOASTMESSAGES("error", "upload file of type json / yaml only.");
  //     return false;
  //   }

  //   const fileExt = fileObj.name.split(".").pop();
  //   let envName = users[index].environment_name;
  //   const newFileName = `${envName}.${fileExt}`;

  //   let filename = `swaggerFiles/${newFileName}`;
  //   let preSignedUrl = await APIService.getPreSignedUrl(filename);
  //   // console.log("preSignedUrl==", preSignedUrl);
  //   // new code
  //   if(preSignedUrl) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result.split(',')[1];
  //       try {
  //         const binaryString = atob(base64String); // Decode the Base64 string to binary data
  //         const blob = new Blob([new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))], { type: fileObj.type });
  //         // new code
  //         const formData = new FormData();
  //         formData.append('file', blob, newFileName);

  //         APIService.uploadFileToBucket(preSignedUrl.toString(), blob).then((response) => {
  //           setIsLoading(false);
  //           setUsers(prevState => {
  //             const updatedUsers = [...prevState];
  //             updatedUsers[index][field] = fileObj;
  //             return updatedUsers;
  //           });
  //           console.log("swagger response==", response);
  //         }).catch((e) => {
  //             setIsLoading(false);
  //             console.log("Error - ", e);
  //           });

  //       } catch (error) {
  //         setIsLoading(false);
  //         console.error('Error decoding Base64:', error);
  //         console.log('Base64 String:', base64String);
  //       }
  //     };
  //     reader.readAsDataURL(fileObj, preSignedUrl, newFileName);
  //   }    
  // };     

  const validateApi = () => {
    const newErrors = {};

    // Custom validation logic for otherDetails
    switch (true) {
      case !otherDetails[0].api_name:
        newErrors.api_name = 'API name is required';
        break;
      // case !otherDetails[0].api_version:
      //   newErrors.api_version = 'API Version is required';
      //   break;
      case !otherDetails[0].description:
        newErrors.description = 'Description is required';
        break;
      default:
        break;
    }

    // Custom validation logic for dynamic user fields
    users && users.length > 0 && users.forEach((user, index) => {
      switch (true) {
        // case !user.environment_name:
        //   newErrors.environment_name = 'Environment Name is required';
        //   break;
        // case !user.service_name:
        //   newErrors.service_name = 'Service Name is required';
        //   break;
        // case !user.git_url:
        //   newErrors.git_url = 'Git Url is required';
        //   break;
        // case !user.branch_name:
        //   newErrors.branch_name = 'Branch Name is required';
        //   break;
        case !user.api_url && !user.swagger_file:
          // newErrors.api_url = 'Api Url or Swagger File is required';
          newErrors.api_url = 'Swagger File is required';
          break;
        case users[index].inputType === "url" && !user.api_url:
          newErrors.api_url = 'Api Url is required';
          break;
        case users[index].inputType === "upload_file" && !user.swagger_file:
          newErrors.swagger_file = 'Swagger File is required';
          break;
        case !user.platformType:
          newErrors.platformType = 'Platform Type is required';
          break;
        case (users[index].auth_type === "oauth2" || users[index].auth_type === "jwt" || users[index].auth_type === "api") && !user.credential_file:
          newErrors.credential_file = 'Credential File is required for selected Auth type';
          break;
        default:
          break;
      }
    });

    // Update errors state
    setErrors(newErrors);
    return newErrors;
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    const errorObj = validateApi();
    // Submit form if no errors
    if (Object.keys(errorObj).length === 0) {
      // Submit form or perform further actions
      const obj = currentUser//JSON.parse(sessionStorage.getItem("userDetails"));
      const envArry = users.map((el) => {
        let swaggerPath = "";
        let credentialPath = "";
        let sdkPath = "";

        if (el.swagger_file !== "") {
          const fileExt = el.swagger_file.split(".").pop();
          swaggerPath = 'swaggerFiles' //`swaggerFiles/${el.environment_name}.${fileExt}`;
        }
        if (el.credential_file !== "") {
          const fileExt = el.credential_file.split(".").pop();
          credentialPath = 'apiCredentialFiles'//`apiCredentialFiles/${el.environment_name}.${fileExt}`;
        }
        if (el.sdk_file !== "") {
          const fileExt = el.sdk_file.name.split(".").pop();
          sdkPath = `SDKs/${el.environment_name}.${fileExt}`;
        }


        const customHeaders = el.headers.reduce((headers, item) => {
          return { ...headers, [item.custom_key]: item.custom_value };
        }, {});

        return {
          // environmentName: el.environment_name,
          // branchName: el.branch_name,
          // serviceName: el.service_name,
          // gitUrl: el.git_url,
          swaggerUrl: el.api_url,
          authType: el.auth_type,
          platformType: el.platformType,
          // customHeaders,
          headersFlag: true,
          swaggerFilePath: swaggerPath,
          credentialFilePath: credentialPath,
          // sdkFilePath: sdkPath
        };
      });

      // const apiDetails = {
      //   name: otherDetails[0].api_name,
      //   version: otherDetails[0].api_version,
      //   description: otherDetails[0].description,
      //   createdBy: obj.userId,
      //   tag: selectedTagInput.toString(),
      //   category: otherDetails[0].category,
      //   apiEnvironment: envArry,
      // };

      const addApiRequest = {
        CreateApiRequest: {
          name: otherDetails[0].api_name,
          // version: otherDetails[0].api_version,
          description: otherDetails[0].description,
          createdBy: obj.FirstName,
          partner: otherDetails[0].client, //selectedTagInput.toString(),
          category: otherDetails[0].category,
          apiEnvironment: envArry,
          File: [
            {
              data: base64ForSwaggerFile,
              FilePath: "swaggerFiles"
            },
            {
              data: base64ForCredFile,
              FilePath: "apiCredentialFiles"
            }
          ]
        }
      }

      // const bytes = new TextEncoder().encode(JSON.stringify(apiDetails));
      // const blob = new Blob([bytes], { type: "application/json" });
      // const fd = new FormData();
      // fd.append("file", iconUrl);
      // fd.append("baseUrlReqDto", blob);      

      APIService.createAPI(addApiRequest).
        then((response) => {
          if (response?.Error?.message) {
            CONFIG.TOASTMESSAGES("error", response?.Error?.message);
          } else {
            setIconUrl("");
            CONFIG.TOASTMESSAGES("success", response?.CreateApiResponse?.message);
            handleClose();
          }
          getMasterData()
        }
        );
    }
  };

  const getMasterData = () => {
    setIsLoading(true)
    APIService.getMasterData().then((response) => {
        if (response) {
            setIsLoading(false)
            dispatch({
                type: ActionTypes.SET_MASTERDATA,
                payload: response?.GetMasterData,
            });
            sessionStorage.setItem("masterData", JSON.stringify(response?.GetMasterData))
        }
    }).catch((e) => {
            CONFIG.TOASTMESSAGES("error", e);
            console.log("Error - ", e);
    });
}

  return (
    <div className="">
      <ModalTemplet
        handleClose={handleClose}
        modalTitle={"Add API"}
        modalHeaderClass={
          "py-5 pl-10 pr-9 font-apimp-regular text-[20px] font-medium"
        }
        modalBodyClass={"pt-5 px-20 bg-txtSecondary rounded-t-[30px] rounded-b-[5px]"}
        modalBody={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {otherDetails.map((item, itemIndex) => {
                  let id = `id-${itemIndex}`
                  return (<div key={id}>
                    <div className="grid grid-cols-12 ">
                      <div className="col-span-10">
                        <label
                          className="relative block font-apimp-regular  text-[#181F48] text-base font-medium "
                          htmlFor="api_name"
                        >
                          <span className="absolute font-apimp-regular inset-y-0 left-0 flex items-center mt-1 ">
                            <div className="bg-txtPrimary px-3 rounded-[10px] py-3 mt-7 cursor-pointer"
                              onClick={() => handleFileUpload()}
                            >
                              <h1 className="text-center text-lg text-white font-apimp-regular font-bold">
                                EN
                              </h1>
                            </div>
                          </span>
                          API Icon & Name
                          <input
                            style={{ display: "none" }}
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                          />
                          <input
                            className="w-full mt-2 rounded-[10px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-sm font-medium  pl-14 py-[15px] pr-4 placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48] "
                            type="text"
                            name="api_name"
                            placeholder="Enter Name..."
                            value={item.api_name}
                            onChange={(e) => handleChangeOtherDetails(itemIndex, 'api_name', e.target.value)}
                          />
                        </label>
                        {errors.api_name && <p className="text-red-700 text-sm ml-2 mt-1">{errors.api_name}</p>}
                      </div>
                      {/* <div className="col-span-2 ml-2">
                        <label
                          className="block font-apimp-regular text-[#181F48] text-base font-medium ml-1"
                          htmlFor="api_version"
                        >
                          API Version
                        </label>
                        <input
                          className=" w-full mt-2 rounded-[10px] border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-sm font-normal py-[15px] placeholder:text-sm placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[rgba(24, 31, 72, 0.6)]"
                          type="text"
                          name="api_version"
                          placeholder="v1.0.1"
                          value={item.api_version}
                          onChange={(e) => handleChangeOtherDetails(itemIndex, 'api_version', e.target.value)}
                        />
                        {errors.api_version && <p className="text-red-700 text-sm ml-2 mt-1">{errors.api_version}</p>}
                      </div> */}
                    </div>

                    <div className="grid mt-6">
                      <label
                        className="block text-base mb-[10px] text-[#181F48] font-medium "
                        htmlFor="description "
                      >
                        Description
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="text-[#121212] rounded-md border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 text-[12px] font-normal font-apimp-regular pt-[11px]"
                        placeholder="Enter Description"
                        name="description"
                        value={item.description}
                        onChange={(e) => handleChangeOtherDetails(itemIndex, 'description', e.target.value)}
                      />
                      {errors.description && <p className="text-red-700 text-sm ml-2 mt-1">{errors.description}</p>}
                    </div>

                    <div className="grid grid-cols-12 mt-6">
                      <div className="col-span-6">
                        <label
                          className="block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="category"
                        >
                          API Category
                        </label>
                        <select
                          className="w-full rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                          name="category"
                          value={item.category}
                          onChange={(e) => handleChangeOtherDetails(itemIndex, 'category', e.target.value)}
                        >
                          <option className="" value="">
                            Select Category
                          </option>
                          {categoryList && categoryList.length > 0 && categoryList.map((item, index) => (
                            <option
                              key={item?.CategoryTagsId}
                              className="py-[8px]" value={item?.CategoryName}>
                              {item?.CategoryName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-6 ml-8">
                        <label
                          className="block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="category"
                        >
                          Partner
                        </label>
                        <select
                          className="w-full rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-4  placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                          name="category"
                          value={item.client}
                          onChange={(e) => handleChangeOtherDetails(itemIndex, 'client', e.target.value)}
                        >
                          <option className="" value="">
                            Select Partner
                          </option>
                          {clients && clients.length > 0 && clients.map((item, index) => (
                            <option
                              key={item?.ApiPartnersId}
                              className="py-[8px]" value={item?.PartnerName}>
                              {item?.PartnerName}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* <div className="col-span-8 ml-[16px]">
                        <label
                          className="block text-base text-[#181F48] font-medium mb-[10px]"
                          htmlFor="tags"
                        >
                          Add Tags
                        </label>
                        <div className="relative block">
                          <span className="absolute font-apimp-regular inset-y-0 right-2 flex items-center">
                            <img src="/images/add_circle_tag_input.svg"></img>
                          </span>
                          <TagsInput
                            value={selectedTagInput}
                            onChange={setSelectedTagInput}
                            name="tags"
                            placeHolder="Add Tag"
                            className="w-full tagsWrapper"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>)
                })}

                {users.map((user, userIndex) => {
                  let temp = `id-${userIndex}`
                  return (<div key={temp}>
                    <div className="grid grid-cols-12 mt-6">
                      {/* <div className="col-span-12">
                        <label
                          className="block text-base mb-[10px] text-[#181F48] font-medium"
                          htmlFor="Add Environment "
                        >
                          Add Environment
                        </label>
                      </div> */}
                      {/* <div className="col-span-1">
                        {
                          userIndex == 0 &&
                          <button
                            type="button"
                            className="inline-block p-[13px] bg-[#FFFFFF] text-[#181F48] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48]"
                            title="Add Environment"
                            onClick={() => setUsers([...users, { environment_name: '', service_name: '', git_url: '', branch_name: '', inputType: 'url', api_url: '', swagger_file: '', platformType: '', auth_type: '', headers: [{ custom_key: '', custom_value: '' }], credential_file: '', sdk_file: '' }])}
                          >
                            <img
                              src={"images/add_circle_tag_input.svg"}
                              alt="add"
                            />
                          </button>
                        }
                        {userIndex > 0 && (
                          <>
                            <button
                              type="button"
                              className="inline-block p-[13px] bg-[#FFFFFF] text-[#181F48] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] mr-[25px]"
                              onClick={() => removeUser(userIndex)}>
                              <img
                                src={"images/delete.svg"}
                                alt="delete"
                              />
                            </button>
                          </>
                        )}
                      </div> */}

                      <div className="col-span-11">
                        {/* <div
                          className="flex w-full mb-[12px] relative text-base text-[#181F48] font-medium"
                          htmlFor="environment_name"
                        >
                          <span
                            className="inline-block pt-[15px] mr-[10px] "
                          >
                            {userIndex + 1}.
                          </span>

                          <select
                            className="w-[40%] rounded-[10px] leading-4 border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-[20px] placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                            value={user.environment_name}
                            onChange={(e) => handleChange(userIndex, 'environment_name', e.target.value)}
                            name={`environment_name${userIndex}`}
                          >
                            <option className="" value="">
                              Select Environment
                            </option>
                            <option className="" value="Production">
                              Production
                            </option>
                            <option className="" value="Development">
                              Development
                            </option>
                            <option className="" value="Sandbox">
                              Sandbox
                            </option>
                          </select>

                        </div> */}
                        {!users[userIndex].environment_name && <p className="text-red-700 text-sm ml-8 mt-1">
                          {errors.environment_name}
                        </p>}

                        {/* <div className="grid grid-cols-12 mt-6">
                          <div className="col-span-6">
                            <div
                              className="flex w-full mb-[12px] relative text-base text-[#181F48] font-medium"
                              htmlFor="service_name"
                            >
                              <input
                                className="w-full rounded-[10px] font-normal border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] py-[15px] pl-4 placeholder:text-[12px] placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[#181F48] focus:bg-red"
                                type="text"
                                id="service_name"
                                name={`service_name${userIndex}`}
                                placeholder="Enter Service Name"
                                value={user.service_name}
                                onChange={(e) => handleChange(userIndex, 'service_name', e.target.value)}
                              />
                            </div>
                            {!users[userIndex].service_name && <p className="block text-red-700 text-sm ml-8 mt-1">
                              {errors.service_name}
                            </p>}
                          </div>

                          <div className="col-span-6">
                            <div
                              className="flex w-full pl-[20px] mb-[12px] relative text-base text-[#181F48] font-medium"
                              htmlFor="branch_name"
                            >
                              <input
                                className="w-full rounded-[10px] font-normal border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] py-[15px] pl-4 placeholder:text-[12px] placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[#181F48]"
                                type="text"
                                id="branch_name"
                                name={`branch_name${userIndex}`}
                                placeholder="Enter Branch Name"
                                value={user.branch_name}
                                onChange={(e) => handleChange(userIndex, 'branch_name', e.target.value)}
                              />
                            </div>
                            {!users[userIndex].branch_name && <p className="block text-red-700 text-sm ml-8 mt-1">
                              {errors.branch_name}
                            </p>}
                          </div>
                        </div> */}
                        {/* <div
                          className="flex w-full mb-[12px] relative text-base text-[#181F48] font-medium"
                          htmlFor="git_url"
                        >
                          <input
                            className="w-full rounded-[10px] font-normal border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] py-[15px] pl-4 placeholder:text-[12px] placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[#181F48]"
                            type="url"
                            id="git_url"
                            name={`git_url${userIndex}`}
                            placeholder="Enter Git Url"
                            value={user.git_url}
                            onChange={(e) => handleChange(userIndex, 'git_url', e.target.value)}
                            ref={el => (gitUrlRef.current[userIndex] = el)}
                          />

                          <span className="absolute inset-y-0 right-[12px] top-[7px] flex  items-center text-[20px]">
                            <img
                              src="/images/content_copy.svg"
                              className="text-[#1C1B1F] cursor-pointer"
                              alt="content_copy"
                              onClick={() => (copyGitUrl(userIndex))}
                            />
                          </span>
                        </div> */}
                        {!users[userIndex].git_url && <p className="block text-red-700 text-sm ml-8 mt-1">
                          {errors.git_url}
                        </p>}

                        <div className="flex gap-4 mt-4">
                          {/* <label>
                            <div className="flex items-center text-base text-[#181F48] font-medium">
                              <input
                                type="radio"
                                checked={users[userIndex].inputType === "url"}
                                value="url"
                                className="mr-1"
                                name={`inputType-${userIndex}`}
                                onChange={(e) => handleChange(userIndex, 'inputType', e.target.value)}
                              />
                              API Url
                            </div>
                          </label> */}

                          <label>
                            <div className="flex items-center text-base text-[#181F48] font-medium">
                              <input
                                type="radio"
                                checked={users[userIndex].inputType === "upload_file"}
                                value="upload_file"
                                className="mr-1"
                                name={`inputType-${userIndex}`}
                                onChange={(e) => handleChange(userIndex, 'inputType', e.target.value)}
                              />
                              Upload Swagger File
                            </div>
                          </label>
                        </div>
                        <div>
                          {users[userIndex].inputType === "url" &&
                            <div
                              className="pl-[20px] mb-[12px] relative text-base text-[#181F48] font-medium"
                              htmlFor="api_url"
                            >
                              <input
                                className="w-full mt-[10px]  rounded-[10px] font-normal border-[0.5px] border-txtPrimary focus:border-txtPrimary focus:ring-0 font-apimp-regular text-[#181F48] text-[12px] py-[15px] pl-4 placeholder:text-[12px] placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[#181F48]"
                                type="url"
                                id="api_url"
                                name={`api_url${userIndex}`}
                                value={user.api_url}
                                onChange={(e) => handleChange(userIndex, 'api_url', e.target.value)}
                                ref={el => (apiUrlRef.current[userIndex] = el)}
                              />

                              <span className="absolute inset-y-0 right-[12px] top-[7px] flex  items-center text-[20px]">
                                <img
                                  src="/images/content_copy.svg"
                                  className="text-[#1C1B1F] cursor-pointer"
                                  alt="content_copy"
                                  onClick={() => (copyApiUrl(userIndex))}
                                />
                              </span>
                            </div>
                          }
                          {users[userIndex].inputType === "upload_file" && <div
                            className="flex w-full pl-[20px] mt-[10px] mb-[12px]"
                          >
                            <button
                              type="button"
                              className="cursor-pointer inline-block p-[9px] bg-[#FFFFFF] text-[#000000] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] mr-[24px]"
                              onClick={() => inputFileUpload()}
                            >
                              <img
                                className="inline-flex cursor-pointer relative mr-2"
                                src={"images/upload.svg"}
                                alt="upload"
                              />
                              Upload File
                            </button>

                            <input
                              style={{ display: "none" }}
                              ref={inputFileRef}
                              type="file"
                              id="swagger_file"
                              name={`swagger_file${userIndex}`}
                              onChange={(e) => handleInputFile(userIndex, 'swagger_file', e)}
                            />
                            {users[userIndex].swagger_file != "" &&
                              <span className="text-[13px] text-[#000000] pt-[10px]">
                                {users[userIndex].swagger_file}
                              </span>
                            }
                          </div>
                          }

                          {!users[userIndex].api_url && !users[userIndex].swagger_file && <p className="block text-red-700 text-sm ml-5 mt-1">
                            {errors.api_url}
                          </p>}
                        </div>

                        <div className="grid grid-cols-12 mb-[12px] mt-4">
                          <div className="col-span-6">
                            <label
                              className="block text-base text-[#181F48] font-medium mb-[10px]"
                              htmlFor="category"
                            >
                              Platform Type
                            </label>
                            <select
                              className="w-full rounded-[10px] border leading-4 border-[#181F48] font-apimp-regular text-[#181F48] text-[12px] font-normal py-[15px] pl-[20px] placeholder:text-sm placeholder:font-apimp-regular placeholder:font-medium placeholder:text-[#181F48]"
                              name={`platformType${userIndex}`}
                              value={user.platformType}
                              onChange={(e) => handleChange(userIndex, 'platformType', e.target.value)}
                            >
                              <option className="" value="">
                                Select Platform
                              </option>
                              {/* <option className="" value="Azure">
                                Azure
                              </option> */}
                              <option className="" value="GCP">
                                GCP
                              </option>
                              <option className="" value="AWS">
                                AWS
                              </option>
                              {/* <option className="" value="ONPREMISE">
                                ONPREMISE
                              </option> */}

                            </select>
                            {!users[userIndex].platformType && <p className="block text-red-700 text-sm ml-5 mt-1">
                              {errors.platformType}
                            </p>}
                          </div>

                          <div className="col-span-6 ml-[16px]">
                            <label
                              className="block text-base text-[#181F48] font-medium mb-[10px]"
                              htmlFor="Auth Type"
                            >
                              Auth Type
                            </label>
                            <div className="flex gap-4 pt-[12px]">
                              {/* <label>
                                <div className="flex items-center text-black text-[15px] font-apimp-regular font-thin">
                                  <input
                                    type="radio"
                                    value="oauth2"
                                    className="mr-1"
                                    name={`auth_type-${userIndex}`}
                                    checked={user.auth_type === "oauth2"}
                                    onChange={(e) => handleChange(userIndex, 'auth_type', e.target.value)}
                                  />
                                  Auth2
                                </div>
                              </label> */}

                              {/* <label>
                                <div className="flex items-center text-black text-[15px] font-apimp-regular font-thin">
                                  <input
                                    type="radio"
                                    value="jwt"
                                    className="mr-1"
                                    name={`auth_type-${userIndex}`}
                                    checked={user.auth_type === "jwt"}
                                    onChange={(e) => handleChange(userIndex, 'auth_type', e.target.value)}
                                  />
                                  JWT
                                </div>
                              </label> */}

                              <label>
                                <div className="flex items-center text-black text-[15px] font-apimp-regular font-thin">
                                  <input
                                    type="radio"
                                    value="api"
                                    className="mr-1"
                                    name={`auth_type-${userIndex}`}
                                    checked={user.auth_type === "api"}
                                    onChange={(e) => handleChange(userIndex, 'auth_type', e.target.value)}
                                  />
                                  API Key
                                </div>
                              </label>

                              {/* <label>
                                <div className="flex items-center text-black text-[15px] font-apimp-regular font-thin">
                                  <input
                                    type="radio"
                                    value="open"
                                    className="mr-1"
                                    name={`auth_type-${userIndex}`}
                                    checked={user.auth_type === "open"}
                                    onChange={(e) => handleChange(userIndex, 'auth_type', e.target.value)}
                                  />
                                  Open
                                </div>
                              </label> */}
                            </div>
                          </div>
                        </div>

                        {/* {user.headers.map((header, headerIndex) => {
                          let headid = `id-${headerIndex}`
                          return (
                            <div className="w-full" key={headid}>
                              {
                                headerIndex === 0 &&
                                <>
                                  <label
                                    className="relative text-base text-[#181F48] font-medium pl-[20px]"
                                    htmlFor="custom_header"
                                  >
                                    Add Custom Header
                                  </label>
                                </>
                              }

                              <div
                                className="flex w-full mt-[10px]"
                              >
                                {
                                  headerIndex == 0 &&
                                  <button
                                    type="button"
                                    className="inline-block p-[13px] bg-[#FFFFFF] text-[#181F48] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] mr-[25px]"
                                    onClick={() => addHeader(userIndex)}
                                  >
                                    <img
                                      src={"images/add_circle_tag_input.svg"}
                                      alt="add"
                                    />
                                  </button>
                                }

                                {headerIndex > 0 && (
                                  <button
                                    type="button"
                                    className="inline-block p-[13px] bg-[#FFFFFF] text-[#181F48] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] ml-[5px] mr-[25px]"
                                    onClick={() => removeHeader(userIndex, headerIndex)}
                                  >
                                    <img
                                      src={"images/delete.svg"}
                                      alt="delete"
                                    />
                                  </button>
                                )}

                                <span
                                  className="inline-block pt-[13px] mr-[5px]"
                                >
                                  {headerIndex + 1}
                                </span>
                                <input
                                  className="w-[215px] mr-[16px] rounded-[10px] border border-[#181F48] font-apimp-regular text-[#181F48] text-sm font-normal py-[15px] focus:outline-[#316AFF] focus:outline-[0.5px] placeholder:text-sm placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[rgba(24, 31, 72, 0.6)]"
                                  type="text"
                                  placeholder="Enter Key"

                                  value={header.custom_key}
                                  onChange={(e) => {
                                    const updatedUsers = [...users];
                                    updatedUsers[userIndex].headers[headerIndex].custom_key = e.target.value;
                                    setUsers(updatedUsers);
                                  }}
                                />

                                <input
                                  className="w-[215px] rounded-[10px] border border-[#181F48] font-apimp-regular text-[#181F48] text-sm font-normal py-[15px] focus:outline-[#316AFF] focus:outline-[0.5px] placeholder:text-sm placeholder:font-apimp-regular placeholder:font-normal placeholder:text-[rgba(24, 31, 72, 0.6)]"
                                  type="text"
                                  placeholder="Enter Value"
                                  value={header.custom_value}
                                  onChange={(e) => {
                                    const updatedUsers = [...users];
                                    updatedUsers[userIndex].headers[headerIndex].custom_value = e.target.value;
                                    setUsers(updatedUsers);
                                  }}
                                />

                              </div>


                              {errors.custom_key?.type === "required" && (
                                <p role="alert" className="text-red-700 ml-1 mt-2 text-sm">
                                  Custom Key is required
                                </p>
                              )}
                              {errors.custom_value?.type === "required" && (
                                <p role="alert" className="text-red-700 ml-1 mt-2 text-sm">
                                  Custom Value is required
                                </p>
                              )}


                            </div>
                          )
                        })} */}

                        <div className="grid grid-cols-12">
                          <div className="col-span-6">
                            <div className="w-full mt-4">
                              <label
                                className="relative  text-base  text-[#181F48]  font-medium "
                                htmlFor="upload_file"
                              >
                                Upload Credential File
                                <div
                                  className="flex w-full mt-[10px]"
                                >
                                  <button
                                    type="button"
                                    className="cursor-pointer inline-block p-[9px] bg-[#FFFFFF] text-[#000000] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] mr-[24px]"
                                    onClick={() => credentialFileUpload()}
                                  >
                                    <img
                                      className="inline-flex cursor-pointer relative mr-2"
                                      src={"images/upload.svg"}
                                      alt="upload"
                                    />
                                    Upload File
                                  </button>

                                  <input
                                    style={{ display: "none" }}
                                    ref={fileRef}
                                    type="file"
                                    id="credential_file"
                                    name={`credential_file${userIndex}`}
                                    onChange={(e) => handleCredentialFile(userIndex, 'credential_file', e)}
                                  />
                                  {users[userIndex].credential_file != "" &&
                                    <span className="text-[13px] text-[#000000] pt-[10px]">
                                      {users[userIndex].credential_file}
                                    </span>
                                  }
                                </div>
                              </label>
                              {errors.credential_file && <p className="text-red-700 text-sm ml-2 mt-1">{errors.credential_file}</p>}
                            </div>
                          </div>

                          {/* <div className="col-span-6">
                            <div className="w-full mt-4 pl-[20px]">
                              <label
                                className="relative  text-base  text-[#181F48]  font-medium "
                                htmlFor="upload_file"
                              >
                                Upload SDK File
                                <div
                                  className="flex w-full mt-[10px]"
                                >
                                  <button
                                    type="button"
                                    className="cursor-pointer inline-block p-[9px] bg-[#FFFFFF] text-[#000000] rounded-[10px] hover:bg-[#FFFFFF] focus:bg-[#FFFFFF] active:bg-[#FFFFFF] border border-[#181F48] mr-[24px]"
                                    onClick={() => sdkFileUpload()}
                                  >
                                    <img
                                      className="inline-flex cursor-pointer relative mr-2"
                                      src={"images/upload.svg"}
                                      alt="upload"
                                    />
                                    Upload File
                                  </button>

                                  <input
                                    style={{ display: "none" }}
                                    ref={sdkfileRef}
                                    type="file"
                                    id="sdk_file"
                                    name={`sdk_file${userIndex}`}
                                    onChange={(e) => handleSdkFile(userIndex, 'sdk_file', e)}
                                  />
                                  {users[userIndex].sdk_file != "" &&
                                    <span className="text-[13px] text-[#000000] pt-[10px]">
                                      {users[userIndex].sdk_file.name}
                                    </span>
                                  }
                                </div>
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                  </div>)
                })}
                <div className="flex pb-9 justify-end pt-5 ">
                  <div className="">
                    <input
                      className="bg-white px-4 py-[9px] rounded-[10px] font-light  text-sm cursor-pointer"
                      type="reset"
                    />
                  </div>
                  <div className="px-2">
                    <input
                      className="disabled:opacity-40 bg-txtPrimary  px-4 py-[9px] rounded-[10px] font-light text-white text-sm cursor-pointer"
                      type="submit"
                    />
                  </div>
                </div>
              </>
            </form>
          </>
        }
      />
      {isLoading && <Loader />}
    </div >

  );
};

export default AddApi;