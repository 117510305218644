
import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loader from './Components/Common/Loader';
import { ContextUtilsStore } from './Context/UtilsContext';
import ApiDirectory from './Components/ApiDirectory/ApiDirectory';
import Applications from "./Components/Applications/Applications";
import ApplicationDetails from "./Components/ApplicationDetails/ApplicationDetails";
import ApiDirectoryCardDetail from './Components/ApiDirectory/ApiDirectoryCardDetail';
import Forum from './Components/Forum/Forum';
import ForumDetails from "./Components/Forum/ForumDetails";
import ApplicationApiDetails from './Components/ApplicationApiDetails/ApplicationApiDetails';
import NotAuthorized from './Components/NotAuthorized/NotAuthorized';
import Login from './Components/Login/Login';
import ProtectedRoutes from './ProtectedRoute';
import Users from './Components/Users/Users';

const RoutesPage = (props) => {     

    return (
        <ContextUtilsStore>
            <Suspense fallback={<Loader />}>
                  <Routes>                    
                    <Route path="/" element={<ProtectedRoutes><ApiDirectory /></ProtectedRoutes>} />
                        <Route path="/api-directory/:did" element={<ProtectedRoutes><ApiDirectoryCardDetail /></ProtectedRoutes>} />
                        <Route path="/applications" element={<ProtectedRoutes><Applications /></ProtectedRoutes>} />
                        <Route path="/application-details/:aid" element={<ProtectedRoutes><ApplicationDetails /></ProtectedRoutes>} />
                        <Route path="/forum" element={<ProtectedRoutes><Forum /></ProtectedRoutes>} />
                        <Route path="/users" element={<ProtectedRoutes><Users /></ProtectedRoutes>} />
                        <Route path="/forum-details/:fid" element={<ProtectedRoutes><ForumDetails /></ProtectedRoutes>} />
                        <Route path="/application-api-details/:appid/:apiid" element={<ProtectedRoutes><ApplicationApiDetails /></ProtectedRoutes>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<p>There's nothing here: 404!</p>} />
                    <Route path="/not-authorized" element={<NotAuthorized />} />
                </Routes>
            </Suspense>
        </ContextUtilsStore>
    )
};

export default RoutesPage;