import React, { useEffect, useState } from "react";
import APIService from "../../Services/API";
import Loader from "../Common/Loader";
import CONFIG from "../../Utils";
import UserConfirmBox from "./UserConfirmBox";
import AddUsers from "./AddUsers";
import ConfirmBox from "../Common/ConfirmBox";
import { useCentralStore } from "../../store/appContext";
import { Button, Table } from 'antd'

const Users = () => {
    const [state, dispatch] = useCentralStore();
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState(null);
    const [roles, setRoles] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [partners, setPartners] = useState(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    const userData = state?.currentUser //JSON.parse(sessionStorage.getItem('userDetails'));

    const [usersList, setUsersList] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [updateUserData, setUpdateUserData] = useState({ userId: '', emailId: '', action: '' });
    const masterData = JSON.parse(sessionStorage.getItem("masterData"))

    const getAllUserAccessDetails = () => {
        // setIsLoading(true);
        // APIService.getAllUserAccessDetails().then((response) => {
        //     if (response) {
        //         setIsLoading(false);
        //         // setUsers(response);
        //     }
        // })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         console.log("Error - ", e);
            // });
    };

    const getUserAccessRoles = () => {
        // setIsLoading(true);
        // APIService.getUserAccessRoles().then((response) => {
        //     if (response) {
        //         setIsLoading(false);
        //         setRoles(response);
        //     }
        // })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         console.log("Error - ", e);
        //     });
    };

    const getAllPartners = () => {
        setPartners(state?.masterDataDetails?.Partners)
        // setIsLoading(true);
        // APIService.getAllPartners().then((response) => {
        //     if (response) {
        //         setIsLoading(false);
        //         setPartners(response);
        //     }
        // })
        //     .catch((e) => {
        //         setIsLoading(false);
        //         console.log("Error - ", e);
        //     });
    };

    const handleApprove = (userId, role) => {
        let filteredData = usersList.filter((item) => item.useraccessId === userId);
        filteredData[0].status = "Approved";
        filteredData[0].title = "Approve User";
        setSelectedDetails(filteredData[0]);
        setShowModal(true);
    }

    const handleReject = (userId, role) => {
        let filteredData = usersList.filter((item) => item.useraccessId === userId);
        filteredData[0].status = "Rejected";
        filteredData[0].title = "Reject User";
        setSelectedDetails(filteredData[0]);
        setShowModal(true);
    }

    const handleCloseApiConfirmation = () => {
        setShowModal(false);
        getAllUserAccessDetails();
        getUserAccessRoles();
    }

    const getUsersList = () => {
        setIsLoading(true);
        APIService.getUsersList().then((response) => {
            if (response) {
                setIsLoading(false);
                let filteredResp = (userData?.RoleName?.toUpperCase() === "ASURION ADMIN" || userData?.RoleName?.toUpperCase() === "ASURION USER") ? response?.GetUsersResponse : response?.GetUsersResponse?.filter(v => v?.Partner?.toUpperCase() === userData?.Partner?.toUpperCase())
                setUsersList(filteredResp)
            }
        })
            .catch((e) => {
                setIsLoading(false);
                console.log("Error - ", e);
            });
    }

    useEffect(() => {
        if (userData?.RoleName?.toUpperCase() !== "ASURION ADMIN" && userData?.RoleName?.toUpperCase() !== "PARTNER ADMIN") {
            return window.location.href = "/*";
        } else {
            getAllUserAccessDetails();
            getAllPartners();
            getUserAccessRoles();
            getUsersList()
        }
    }, []);

    const handleAction = (userId, email, action) => {
        setUpdateUserData({ userId: userId, emailId: email, action: action })
        setShowConfirmation(true)
    }

    useEffect(() => {
        getUsersList()
    }, [!showAddUserModal])

    const callUpdateUser = () => {
        const payload = {
            UpdateUserRequest: {
                UserId: updateUserData?.userId,
                emailId: updateUserData?.emailId,
                // Action: updateUserData?.action
            }
        }
        setIsLoading(true);
        APIService.updateUser(payload).then((response) => {
            if (response) {
                setIsLoading(false);
                setShowConfirmation(false)
                getUsersList()
                CONFIG.TOASTMESSAGES("success", response?.UpdateUserResponse?.message);
                // setUsersList(response?.GetUsersResponse)
            }
        })
            .catch((e) => {
                setIsLoading(false);
                setShowConfirmation(false)
                console.log("Error - ", e);
            });
    }


    let tableColumns = [];
    const totalKeys = {};

    usersList?.reduce((acc, current) => {
        const keys = Object.keys(current);
        for (var i in keys) {
            totalKeys[keys[i]] = 1;
        }
    }, totalKeys);

    var keys = Object.keys(totalKeys);
    for (var i in keys) {
        tableColumns.push({
            title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
            dataIndex: keys[i],
            key: keys[i],
            width: 280
        });
    }

    const getPartsColumns = () => {
        let headerColumns = [];
        tableColumns.forEach((c, i) => {
            if(c?.title !== 'FIRST NAME' && c?.title !== 'ROLE ID' && c?.title !== 'USER ID'){
            headerColumns.push({
                ...c,
            });}
        });
        return headerColumns;
    };

    return (
        <>
            <div className="p-[20px] w-[80%] m-auto">
                {!(usersList) && isLoading && <Loader />}
                <div className="col-span-12 flex flex-row justify-between">
                    <h1 className="text-[32px] mb-[20px]">Users</h1>
                    <div onClick={() => setShowAddUserModal(true)}>
                        <button
                            type="button"
                            className={`inline-block px-3 py-2.5 bg-txtPrimary text-white font-inter-regular text-base rounded-xl mr-[16px] h-auto`}
                        >
                            <img
                                className="inline-flex cursor-pointer relative mr-2"
                                src={"images/add_circle.svg"}
                                alt="add"
                            />
                            Add Users
                        </button>
                    </div>
                </div>
                <div className="w-full flex justify-center">
                {/* <Table
                    columns={getPartsColumns()}
                    dataSource={usersList}
                    pagination={{ show: true, total: usersList?.length }}
                    onChange={undefined}
                    style={{ fontSize: "10px !important" }}
                    rowKey="data"
                    key="data"
                    className="table-css border-current border-white"
                    locale={{ emptyText: "No data" }}
                /> */}
                    <table className='w-full'>
                        <thead className='text-[#0E6B8A]'>
                            <tr>
                                <td className='text-start pb-[1rem]'>User Name</td>
                                <td className='text-start pb-[1rem] pr-[1rem] whitespace-nowrap'>Role</td>
                                <td className='text-start pb-[1rem] pr-[1rem]'>Partner</td>
                                <td className='text-start pb-[1rem]'>Actions</td>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {usersList && usersList.map((item, ind) => {
                                return (
                                    <tr className='border-y' key={ind}>
                                        <td className='text-start text-sm py-[0.75rem] text-[#242424] cursor-pointer truncate w-1/4'>{item.FirstName}</td>
                                        <td className='text-start text-sm py-[0.75rem] text-[#242424] cursor-pointer w-1/4'>{item.RoleName}</td>
                                        <td className='text-start text-sm py-[0.75rem] text-[#242424] cursor-pointer w-1/4'>{item.Partner}</td>
                                        <td className={`text-start text-sm py-[0.75rem] w-1/4`}>
                                            {/* {(item?.Status?.toLowerCase() === 'reject' || item?.Status === undefined) &&
                                                <Button
                                                    className={`border border-green-500 text-green-700 rounded-xl mr-[16px] ${item.isApproved === "Approved" ? "hidden" : ""}`}
                                                    onClick={() => handleAction(item?.UserId, item?.EmailId, 'Approve')}
                                                    >
                                                        Approve
                                                </Button>
                                            }
                                            {(item?.Status?.toLowerCase() === 'approve' || item?.Status === undefined) &&
                                                <Button danger
                                                className={`rounded-xl mr-[16px] ${item.isApproved === "Rejected" ? "hidden" : ""}`}
                                                    onClick={() => handleAction(item?.UserId, item?.EmailId, 'Reject')}
                                                    >
                                                        Reject
                                                </Button>
                                            } */}
                                            {item?.EmailId !== userData?.EmailId &&
                                                <Button danger className={`rounded-xl mr-[16px]`}
                                                    onClick={() => handleAction(item?.UserId, item?.EmailId, 'Delete')}>
                                                        Delete
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            {usersList && usersList.length === 0 && <tr><td colSpan={4} className='py-[1rem] text-[#242424] text-[0.875rem]'>No data found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <UserConfirmBox
                    handleCloseConfirmation={handleCloseApiConfirmation}
                    selectedDetails={selectedDetails}
                    roles={roles}
                    partners={partners}
                />
            )}
            {showAddUserModal && (
                <AddUsers
                    handleCloseConfirmation={() => setShowAddUserModal(false)}
                    roles={masterData?.Roles}
                    partners={partners}
                    masterData={masterData}
                    getUserList={getUsersList}
                    currentUser={userData}
                />
            )}
            {showConfirmation ? (
                <ConfirmBox
                    descText={`Are you sure you want to ${updateUserData?.action}?`}
                    handleCloseConfirmation={() => setShowConfirmation(false)}
                    handleAgree={callUpdateUser}
                    AgreeLBL='Yes'
                    DisagreeLBL="No"
                />
            ) : null}
        </>
    );
};

export default Users;
