import React from 'react';
import { useNavigate } from 'react-router-dom'


const ForumCard = (props) => {
    const navigate = useNavigate();

    const handleDetails = (event, id) => {
        event.preventDefault();
        event.stopPropagation(); 
        navigate(`/forum-details/${id}`);
      }
      
    return (
        <div 
            className='border border-txtPrimary border-opacity-5 bg-txtPrimary bg-opacity-5 rounded-[10px] cursor-pointer'
            onClick={(event) => handleDetails(event,props.data.discussionId)}
        >
                <div className='p-6 flex relative'>
                    <span className="relative">
                        <img src='/images/forumicon.svg'></img>
                        <img className="absolute -top-1 -right-2" src='/images/greendot.svg'></img>
                    </span>
                    <div className='flex flex-col px-5'>
                        <h1 className='text-[#181F48] text-lg font-apimp-semibold'>{props.data.discussionName}</h1>

                        <div className='flex flex-row pt-2'>
                            {props.data.discussionTags.length > 0 && props.data.discussionTags.map((data, index) => (
                                <span 
                                    key={data}
                                    className="bg-txtPrimary bg-opacity-20 text-txtPrimary text-[10px] font-apimp-semibold inline-flex items-center px-2.5 py-1 rounded-[10px] mr-2"
                                >
                                    {data}
                                </span>
                            ))}                            
                        </div>

                        <div className='flex pt-4'>
                            <img src='/images/avatar.svg'></img>
                            <div className='flex-row px-2'>
                                <h4 className='text-[#181F48] font-apimp-semibold text-sm flex'>{props.data.userName}
                                    <img 
                                        className='px-1' 
                                        src='/images/blackdot.svg'
                                    />
                                </h4>
                                <span className='text-[10px] font-apimp-regular'>
                                    {props.data.lastUpdatedDate}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='absolute right-6'>
                        <img 
                            className="cursor-pointer"
                            src={`images/${props.data.userFlag ? 'like_filled.svg' : 'like.svg' }`}                      
                            onClick={(event) => props.handleLike(event, props.data.discussionId, !props.data.userFlag)}
                        />
                    </div>
                    <div className='absolute bottom-6 right-6 text-[#181F48] text-sm font-apimp-regular'>
                        <span className='pr-6'>{props.data.views} Views</span>
                        <span className='pr-6'>{props.data.likes} Likes</span>
                        <span>{props.data.numberOfComments} comments</span>
                    </div>
                </div>
                <div/>
        </div>
    )
}

export default ForumCard