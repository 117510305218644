import React, { Fragment, useEffect, useState } from 'react';
import APIService from "../../Services/API";

const Documentation = (props) => {
    const [allMethods, setAllMethods] = useState([]);
    const [activeMethodsCount, setActiveMethodsCount] = useState(0);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [swaggerMethods, setSwaggerMethods] = useState({});
    const [allData, setAllData] = useState(null);

    const formatDoc = (objDoc, definitions) => {
        let objData = objDoc;

        let aryData = Object.values(objData);
        let aryKeys = Object.keys(objData);
        
        aryData.forEach((val, index) => {
            let temp = Object.keys(val);
            let temp2 = Object.values(val);

            if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.items) {
                let txtSchema = temp2[0].parameters[0].schema.items.$ref.split("/");
                let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
                let arrOfValues = Object.values(allParam);
                let arrOfKeys = Object.keys(allParam);

                arrOfValues.forEach((val, index) => {
                    val.name = arrOfKeys[index];
                    return val;
                });

                val[temp].definitions = arrOfValues;
            }

            if (temp2[0]?.parameters && temp2[0]?.parameters[0]?.schema && temp2[0]?.parameters[0].schema.$ref) {
                let txtSchema = temp2[0].parameters[0].schema.$ref.split("/");
                let allParam = definitions[txtSchema[txtSchema.length - 1]].properties;
                let arrOfValues = Object.values(allParam);
                let arrOfKeys = Object.keys(allParam);

                arrOfValues.length > 0 && arrOfValues.forEach((val, index) => {
                    val.name = arrOfKeys[index];
                    return val;
                });
                val[temp[0]]["definitions"] = arrOfValues;
            }

            val[temp[0]].pathString = aryKeys[index];
            val[temp[0]].selected = false;
            return val;
        });        

        let temp = {};
       
        aryData.forEach((item, index) => {
            let keys = Object.keys(item);
            let values = Object.values(item);

            keys.forEach((val, ind) => {
                if (temp[val]) {
                    temp[val].push(values[ind]);
                } else {
                    temp[val] = [];
                    temp[val].push(values[ind]);
                }
            });
        });

        handleSelectedMethods(temp);
    }

    const handleSwaggerMethods = (txtChecked, indexVal, type, txtPath) => {
        handleSelectedPaths(txtChecked, txtPath);
        let obj1 = Object.assign([], swaggerMethods);
        obj1[type][indexVal].selected = txtChecked;
        let cnt = getActiveMethods(obj1);
        setSwaggerMethods(obj1);
        setActiveMethodsCount(cnt);
    }

    const handleSelectedMethods = (txtData) => {
        let aryData = Object.assign([], txtData);
        Object.keys(aryData).length > 0 && Object.keys(aryData).map((data, index) => {
            return aryData[data].map((elem, ind) => {
                elem.selected = false;
                return elem;
            });
        });
        setSwaggerMethods(aryData);
        setActiveMethodsCount(0);
    };

    const handleSelectedPaths = (txtChecked, txtValue) => {
        let pathAry = JSON.parse(JSON.stringify(selectedPaths));
        const index = pathAry.indexOf(txtValue);
        if (txtChecked) {
            pathAry.push(txtValue);
        } else {
            if (index > -1) {
                pathAry.splice(index, 1);
            }
        }
        setSelectedPaths(pathAry);
    }

    const getActiveMethods = (txtData) => {
        let cnt = 0;
        txtData && Object.keys(txtData).length > 0 && Object.keys(txtData).map((data, index) => {
            return txtData[data].map((elem, ind) => {
                if (elem.selected) {
                    cnt++
                }
                return elem;
            });
        });
        return cnt;
    };

    const getDetailsApiGateway = (data, txtPath) => {      
        let filteredData = data.filter((elem, index) => elem.location.path === txtPath);  
        let details = [];      
        if(filteredData.length > 0){
            filteredData.map((data, index) => {   
                let txtDescription = data.properties?.description;    
                let txtMethod = data.location.method;         
                if(data.properties?.schema?.GetBatchDetailsRequest){
                    let existingObj = data.properties.schema.GetBatchDetailsRequest; 
                    existingObj.method = txtMethod;
                    existingObj.description = txtDescription; 
                    let res = JSON.stringify(existingObj);
                    details.push(res);
                } 
            })            
        } else {     
            const sampleObject = {
                NA: ''
              };
            details.push(JSON.stringify(sampleObject));
        } 
        return details[0];            
    }

    const getRequestBody = (txtPath, reqType) => {          
        let details = []; 
        const sampleObject = {NA: ''};
        if(Object.keys(allData).includes("x-amazon-apigateway-documentation")){
            let documentationData = allData["x-amazon-apigateway-documentation"]?.documentationParts;
            const filteredData = documentationData.filter(elem => (elem?.location?.path === txtPath && (elem.location.type === reqType) )); 
            if(filteredData.length > 0){
                filteredData.map((data, index) => {   
                    let txtDescription = data?.properties?.description;    
                    if(data?.properties?.schema){
                        let schemaObj = data?.properties?.schema;
                        details.push(schemaObj);
                    } else {
                        details.push(sampleObject);
                    } 
                })            
            } else {                
                details.push(sampleObject);
            } 

        }else {              
            details.push(sampleObject);
        } 
        return renderResponseObjectProperties(details[0]);
    }
    
    const getResponseBody = (txtPath, reqType) => {          
        let details = []; 
        const sampleObject = {NA: ''};
        if(Object.keys(allData).includes("x-amazon-apigateway-documentation")){
            let documentationData = allData["x-amazon-apigateway-documentation"]?.documentationParts;
            const filteredData = documentationData.filter(elem => (elem?.location?.path === txtPath && (elem.location.type === reqType) )); 
            if(filteredData.length > 0){
                filteredData.map((data, index) => {   
                    let txtDescription = data?.properties?.description;    
                    if(data?.properties?.schema){
                        let schemaObj = data?.properties?.schema;
                        details.push(schemaObj);
                    } 
                })            
            } else {                
                details.push(sampleObject);
            } 

        }else {              
            details.push(sampleObject);
        } 
        return renderResponseObjectProperties(details[0]);
    }
    
    const getApiDocs = (txtUrl) => {
        APIService.getApiDocs(txtUrl).then((response) => {
            if (response) {
                updateXAmazonDoc(response);
                formatDoc(response.paths, response.definitions);
            }
        })
            .catch((e) => {
                console.log("Error - ", e);
            });
    };

    const updateXAmazonDoc = (data) => {
        let allData = data;
        if(Object.keys(allData).includes("x-amazon-apigateway-documentation")){
            let documentationData = allData["x-amazon-apigateway-documentation"]?.documentationParts;
            const filteredData = documentationData.filter(elem => (elem.location.type === "REQUEST_BODY" || elem.location.type === "REQUEST_QUERY" || elem.location.type === "REQUEST_HEADER" || elem.location.type === "REQUEST_PATH"));                       
            if(filteredData.length > 0){
                filteredData.map((data, index) => {                         
                    if(data?.properties?.schema){
                        let schemaObj = data?.properties?.schema;
                        data.parameters = [];
                        let keys = Object.keys(schemaObj);
                        let temp = schemaObj[keys];
                        let keysnew = Object.keys(schemaObj[keys]);                                             
                        (keysnew && keysnew.length > 0 && keysnew.map((val, index) => {
                            let obj = {
                                "in": data.location.type,
                                "name": val,                               
                                "type": temp[val],
                                "value": "",
                                "key": keys[0]
                            }
                            data.parameters.push(obj);   
                            if(typeof(temp[val]) == "object" && Object.keys(temp[val]).length > 0){
                                Object.keys(temp[val]).map((childKey, childIndex) => {
                                    let newObj = {
                                        "in": data.location.type,
                                        "name": childKey,                               
                                        "type": temp[val],
                                        "value": "",
                                        "key": keys[0]
                                    };
                                    data.parameters.keys[0]= newObj;
                                })
                                 
                            }                             
                        })); 
                        
                    } 
                })            
            }             
        } 
        setAllData(allData);
    }

    const renderResponseObjectProperties = (objMethods) => {
        let keys = Object.keys(objMethods);
        let temp = objMethods[keys]
        let keysnew = Object.keys(objMethods[keys]);
        if (keysnew.length === 0) {
            return (<div>NA</div>)
        } else {

            return (
                <div className=''>{keysnew.length > 0 && keysnew.map((val, index) => (
                    <div key={val} className='flex item-center border-b-[2px] mb-[15px]'>
                        <div className='w-[170px]'>{val}</div>
                        <div className=''>
                            {typeof (temp[val]) == "object" && Object.keys(temp[val]).length > 0 ?
                                Object.keys(temp[val]).map((childKey, childIndex) => (
                                    <p key={childKey} className='mb-[12px] text-[14px]'>
                                        <span className='inline-block w-[120px]'>{childKey} </span>

                                        ({temp[val][childKey]})
                                    </p>
                                )) : <span> ({temp[val]})</span>
                            }
                        </div>

                    </div>
                ))
                }

                </div>
            );
        }
    };

    useEffect(() => {
        if (props.apiUrl) {
            // getApiDocs(props.apiUrl);
            updateXAmazonDoc(props.apiUrl);
            formatDoc(props.apiUrl.paths, props.apiUrl.definitions);
        }
    }, [props?.apiUrl])

    return (
        <Fragment>
            <div className='rounded-2xl border border-[#316AFF] border-opacity-40 mt-[20px]'>
                <div className="grid grid-cols-4">
                    <div
                        className='p-[16px] border-[#4AB6F2] border-[0.5px] rounded-[15px]'
                    >
                        <div
                            className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                        >
                            Available Methods
                        </div>
                        {swaggerMethods && Object.keys(swaggerMethods).length === 0 && (<div
                            className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                        >
                            No methods found
                        </div>)
                        }

                        {swaggerMethods && Object.keys(swaggerMethods).length > 0 && Object.keys(swaggerMethods).map((data, index) => {
                            let id1 = index;
                            return (<div
                                key={id1}
                                className='block'
                            >
                                <span
                                    className='inline-block bg-[#4AB6F2] border-[#4AB6F2] border-[1px] text-[#FFFFFF] text-[12px] rounded-[15px] px-[16px] py-[10px] mb-[10px]'
                                > {data}  </span>
                                {
                                    swaggerMethods[data].map((elem, ind) => {
                                        let id2 = ind;
                                        return (
                                            <div
                                                key={id2}
                                                className='my-4 border-[#181F48] border-[1px] px-[10px] py-[8px] truncate text-ellipsis'
                                            >
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={elem.selected}
                                                    className="mr-[10px] cursor-pointer"
                                                    onChange={(e) => handleSwaggerMethods(e.target.checked, ind, data, elem.pathString)}
                                                />
                                                <span
                                                    className='text-[#000000] text-[10px] cursor-pointer'
                                                    title={elem.pathString ? elem.pathString : "/NA"}
                                                >
                                                    {elem.pathString ? elem.pathString : "/NA"}
                                                </span>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            )
                        }
                        )
                        }
                    </div>

                    <div
                        className='col-start-2 col-end-5 mx-4 mb-4'
                    >
                        <div
                            className=''
                        >
                            {swaggerMethods && Object.keys(swaggerMethods).length === 0 && activeMethodsCount === 0 && (<div
                                className='font-normal text-[#181F48] text-[10px] pb-[16px]'
                            >
                                No methods found
                            </div>)
                            }

                            {swaggerMethods && Object.keys(swaggerMethods).length > 0 && activeMethodsCount === 0 && <div className='text-center text-[14px] text-[#000000] py-[16px] pl-[24px] pr-[40px]'>
                                Please select the method to get the details
                            </div>}

                            {activeMethodsCount > 0 && Object.keys(swaggerMethods).length > 0 && Object.keys(swaggerMethods).map((data, ind) => (
                                swaggerMethods[data].map((item, index) => {
                                    if (item.selected) {
                                        let id = index;
                                        return (
                                            <div
                                                key={id}
                                                className='bg-[#c0c0c01a] p-[8px] mb-[20px] cursor-pointer'
                                                id={`drag${index}`}
                                            >
                                                {/* <div className='mb-[10px]'>
                                                    <span
                                                        className='inline-block bg-[#4AB6F2] border-[#4AB6F2] border-[1px] text-[#FFFFFF] text-[10px] rounded-[15px] px-[20px] py-[10px] mr-[10px]'
                                                    >
                                                        {data}
                                                    </span>
                                                </div> */}
                                                <div className='grid grid-cols-12 mb-[10px]'>
                                                    <div className="col-span-2">
                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            Summary -
                                                        </span>
                                                    </div>
                                                    <div className="col-span-10">
                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            {!item.summary ? "NA" : item.summary}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 mb-[10px]'>
                                                    <div className="col-span-2">
                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            Description -
                                                        </span>
                                                    </div>
                                                    <div className="col-span-10">

                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            {!item.description ? "NA" : item.description}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 mb-[10px]'>
                                                    <div className="col-span-2">
                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            Request Body
                                                        </span>
                                                    </div>
                                                    <div className="col-span-10">
                                                        <span
                                                            className='text-[#000000] text-[16px] py-[10px]'
                                                        >
                                                            {item?.parameters && item.parameters?.length > 0 && !Object.values(item)[0].definitions && item.parameters.map((elm, ind) => {
                                                                let id2 = ind;
                                                                if (!elm.type) {
                                                                    return false;
                                                                }
                                                                return (
                                                                    <div
                                                                        key={id2}
                                                                        className='block px[20px my-[16px]'
                                                                    >
                                                                        <label
                                                                            htmlFor={`${item.pathString}`}
                                                                            className='inline-block min-w-[170px] mr-[16px]'
                                                                        >{elm.name}
                                                                        </label>
                                                                        ( {elm.type} )
                                                                    </div>
                                                                )
                                                            })}

                                                            {!item?.parameters && Object.values(item)[0].definitions && Object.values(item)[0].definitions.length > 0 && Object.values(item)[0].definitions.map((elm, index_def) => {
                                                                let id3 = index_def;
                                                                if (!elm.type) {
                                                                    return false;
                                                                }
                                                                return (
                                                                    <div
                                                                        key={id3}
                                                                        className='block px[20px my-[16px]'
                                                                    >
                                                                        <label
                                                                            htmlFor={`${item.pathString}`}
                                                                            className='inline-block min-w-[170px] mr-[16px]'
                                                                        >{elm.name}
                                                                        </label>
                                                                        ( {elm.type} )
                                                                    </div>
                                                                )
                                                            })}

                                                            {getRequestBody(item.pathString, "REQUEST_BODY")}
                                                        </span>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        )
                                    }
                                })
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Documentation